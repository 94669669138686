/* eslint-disable import/no-cycle */
import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Grid } from "@material-ui/core";
import Intro from "./Intro";
import HighlightMovies from "../../common/HighlightMovies";

const urls = {
  mostRated: "/users/movies/mostRated",
  mostRecent: "/users/movies/mostRecent",
};

export default function Home() {
  const { t } = useTranslation("translations");

  return (
    <>
      <Intro />
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <HighlightMovies
              title={t("dashboard mostRated")}
              url={urls.mostRated}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <HighlightMovies
              title={t("dashboard mostRecent")}
              url={urls.mostRecent}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
