import {
  Grid,
  makeStyles,
  Divider,
  List,
  ListItem,
  Avatar,
  ListItemText,
  ListItemAvatar,
  TextField,
  Button,
} from "@material-ui/core";
import { BuilderCommentFields, Comments } from "@project/types";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../hooks";
import { useTypedSelector } from "../../../../../redux";
import Loading from "../../../../common/Loading";

type Prop = {
  comments: Comments;
  d: {
    bid: string;
    pid: string;
    eid: string;
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  floatingArea: {
    width: "80vw",
    height: "40vh",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    textAlign: "center",
    overflowY: "scroll",
    backgroundColor: "white",
  },
  gridStyle: {
    marginTop: "3%",
  },
  credits: {
    marginTop: "20px",
  },
  fullSize: {
    width: "100%",
    height: "100%",
  },
}));

export const CommentsType: React.FC<Prop> = ({ d }) => {
  const classes = useStyles();
  const [comment, setComment] = useState("");
  const [commentsArr, setCommentsArr] = useState<BuilderCommentFields[]>([]);
  const { t } = useTranslation();
  const name = useTypedSelector((state) => state.t5.fullName);

  const { data, loading } = useFetch<BuilderCommentFields[]>(
    `/guests/builders/comments/${d.bid}/${d.eid}`,
  );

  const post = async () => {
    if (comment !== "") {
      try {
        const res = await Axios.post(`/guests/builders/comment/${d.bid}`, {
          ...d,
          comment,
          name,
        });
        setCommentsArr((old) => [res.data, ...old]);
        setComment("");
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (data) {
      setCommentsArr(data);
    }
  }, [data]);

  if (loading) return <Loading />;

  return (
    <Grid
      container
      justify="center"
      alignContent="center"
      className={classes.gridStyle}
    >
      <Grid item xs={12} className={classes.floatingArea}>
        <List
          component="nav"
          className={classes.root}
          aria-label="mailbox folders"
        >
          <ListItem>
            <ListItemAvatar>
              <Avatar src={`https://robohash.org/${name}`} />
            </ListItemAvatar>
            <ListItemText
              primary={name}
              secondary={
                <TextField
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  fullWidth
                />
              }
            />
            <Button onClick={post}>{t("t5 add-comment-button")}</Button>
          </ListItem>
          <Divider variant="inset" component="li" />
          {commentsArr.map((item, i) => {
            return (
              <React.Fragment key={i}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar src={`https://robohash.org/${item.name}`} />
                  </ListItemAvatar>
                  <ListItemText primary={item.name} secondary={item.comment} />
                </ListItem>
                <Divider variant="inset" component="li" />
              </React.Fragment>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
};
