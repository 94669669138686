import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main,
    fontSize: 18,
  },
}));

type Option = { label: string; value: any; owner: string };
type Filter = { label: string; value: any };

export type FiltersSelectProps = {
  value: any;
  setValue: (value: any) => void;
  options: Option[];
  filters: Filter[];
};

const FiltersSelect: React.FC<FiltersSelectProps> = ({
  value,
  setValue,
  options,
  filters,
}) => {
  const classes = useStyles();

  const handleChange = (e: any) => {
    const val = e.target.value;
    const index = value.indexOf(val);
    if (index === -1) {
      setValue([...value, val]);
    } else {
      const old = [...value];
      old.splice(index, 1);
      setValue(old);
    }
  };

  return (
    <div>
      {filters.map((f, i) => (
        <FormControl
          style={{ display: "block" }}
          key={i + f.label}
          component="fieldset"
        >
          <FormLabel component="p" className={classes.label}>
            {f.label}
          </FormLabel>
          <FormGroup onChange={handleChange} row>
            {options
              .filter(
                (o: any) => o.owner === f.value || o.owner?._id === f.value,
              )
              .map((o) => (
                <FormControlLabel
                  key={o.value}
                  value={o.value}
                  control={
                    <Checkbox
                      checked={value.indexOf(o.value) !== -1}
                      color="primary"
                    />
                  }
                  label={o.label}
                  labelPlacement="end"
                />
              ))}
          </FormGroup>
        </FormControl>
      ))}
    </div>
  );
};

export default FiltersSelect;
