/* eslint-disable no-shadow */
import React, { useState, useEffect } from "react";
import { functions } from "@project/common";
import axios from "axios";
import FiltersForm from "./FiltersForm";

export default function EditFilters({ user, selectedLang }) {
  const [filters, setFilters] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const filters = await axios("/users/filters/profile");
      const options = await axios("/users/filters/filterOptions");

      setFilters(
        filters.data.map((f) => ({
          label: functions.findFilledLang(f.name, selectedLang),
          value: f._id,
        })),
      );

      setOptions(
        options.data.map((f) => ({
          label: functions.findFilledLang(f.name, selectedLang),
          value: f._id,
          owner: f.owner,
        })),
      );
    };

    fetch();
  }, [selectedLang]);

  return (
    <FiltersForm
      user={user}
      selectedLang={selectedLang}
      filters={filters}
      options={options}
    />
  );
}
