import React from "react";
import { useTranslation } from "react-i18next";
import TableAbstract, { Columns } from "../../../common/TableAbstract";
import { TagForm } from "./TagForm";

const urls = {
  mount: "/admins/tags/",
  delete: (id: string) => `/admins/tags/${id}`,
};

export default function Tags() {
  const { t } = useTranslation();
  const columns: Columns = ({ selectedLang }) => [
    {
      title: t("admin-tags name"),
      field: `name.${selectedLang}`,
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: "מספר תמונות",
      filtering: false,
      render: (rowData) => <span>{rowData.images.length}</span>,
    },
  ];

  return (
    <TableAbstract
      title={t("admin-tags title")}
      columns={columns}
      urls={urls}
      AddForm={TagForm}
      EditForm={TagForm}
      useLang
    />
  );
}
