import { Grid, makeStyles, Typography } from "@material-ui/core";
import { Image, Link } from "@project/types";
import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import ReactPlayer from "react-player";

type Prop = {
  media: Image;
};

const useStyles = makeStyles(() => ({
  floatingArea: {
    width: "80vw",
    height: "60vh",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    textAlign: "center",
  },
  gridStyle: {
    marginTop: "3%",
  },
  credits: {
    marginTop: "20px",
  },
  fullSize: {
    width: "100%",
    height: "100%",
  },
}));

export const ImageType: React.FC<Prop> = ({ media }) => {
  const classes = useStyles();
  const [, setNumPages] = useState(null);
  const [pageNumber] = useState(1);

  const onDocumentLoadSuccess = (num: any) => {
    setNumPages(num);
  };

  return (
    <Grid
      container
      justify="center"
      alignContent="center"
      className={classes.gridStyle}
    >
      <Grid item xs={12} className={classes.floatingArea}>
        <img width={"100%"} height={"100%"} src={media.url} />
      </Grid>
      <Grid
        container
        item
        xs={12}
        justify="center"
        alignContent="center"
        className={classes.credits}
      >
        {media.credit && <Typography>{media.credit}</Typography>}
      </Grid>
    </Grid>
  );
};
