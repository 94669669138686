import React from "react";
import { Container } from "@material-ui/core";

type Props = {
  index: number;
  value: number;
  isHome: boolean;
  other?: { [key: string]: any };
};

const TabPanel: React.FC<Props> = ({
  index,
  value,
  isHome,
  children,
  ...other
}) => {
  const hidden = value !== index;

  // if (isHome) {
  //   return (
  //     <div hidden={hidden} {...other}>
  //       {children}
  //     </div>
  //   );
  // }

  return (
    <Container
      maxWidth={false}
      style={hidden ? { display: "none" } : undefined}
      {...other}
    >
      <div>{children}</div>
    </Container>
  );
};

export default TabPanel;
