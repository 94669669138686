/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-shadow */
/* eslint-disable linebreak-style */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { functions } from "@project/common";
import { Container, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import { useLangSelector } from "../../../redux";
import Button from "./Button";
import { addTab } from "../../../redux/actions/utilsActions";
import { colors } from "../../../helpers/colors";

const useStyles = makeStyles((theme) => ({
  filterGrid: {
    display: "flex",
    justifyContent: "space-between",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  mobileNoPadding: {
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 1,
      paddingRight: 1,
    },
  },
}));

export default function Folders({ folderID = null, tagID = null }) {
  const selectedLang = useLangSelector();
  const classes = useStyles({ isRTL: functions.isRTL(selectedLang) });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  console.log(folderID, tagID);
  const [folders, setFolders] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      let f = [];
      if (tagID) {
        f = await axios(`/users/tags/movies/${tagID}`);
      } else if (!folderID) {
        f = await axios("/users/folders/");
        f.data = f.data.filter(
          (folderFilter) => folderFilter.isFather === true,
        );
      } else {
        f = await axios(`/users/folders/movies/${folderID}`);
      }

      f = f.data
        .filter((f) => functions.gotLang(f.name, selectedLang))
        .sort((a, b) =>
          a.name[selectedLang].toLowerCase() <
          b.name[selectedLang].toLowerCase()
            ? -1
            : 1,
        );

      setFolders(f);
    };
    fetch();
    // eslint-disable-next-line
  }, []);

  if (folders.length === 0) {
    return (
      <Typography
        variant="h4"
        color="primary"
        paragraph
        gutterBottom
        align="center"
        style={{ marginTop: "100px" }}
      >
        {t("no content in folder")}
      </Typography>
    );
  }

  return (
    <Container maxWidth="xl" className={classes.mobileNoPadding}>
      <Grid container spacing={1}>
        {folders.map((folder, i) => (
          <Grid key={folder._id + 2} item lg={3} md={6} xs={12}>
            <Button
              key={folder._id}
              title={folder.name[selectedLang]}
              background={
                folderID || tagID
                  ? { data: folder.imgLink, type: "url" }
                  : { data: colors[i % colors.length], type: "color" }
              }
              movieData={folder}
              extendData={folderID || tagID}
              url={() => {
                dispatch(
                  addTab({
                    label: folder.name[selectedLang],
                    value: folderID || tagID ? folder.name2 : folder._id,
                    type: folderID || tagID ? "movie" : "folder",
                    closeable: true,
                  }),
                );
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
