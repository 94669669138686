import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { BuilderFields, Element } from "@project/types";
import React from "react";
import Loading from "../../../common/Loading";
import { CommentsType } from "./Types/CommentsType";
import { ImageType } from "./Types/ImageType";
import { MediaType } from "./Types/MediaType";
import { MovieType } from "./Types/MovieType";
// eslint-disable-next-line import/no-useless-path-segments
import { Question } from "./Types/Question/";
import { QuoteType } from "./Types/QuoteType";
import { TextType } from "./Types/TextType";

type Prop = {
  page: number;
  pageData: BuilderFields["pages"][0];
  setFullPage: () => void;
  bid: string;
  createdBy: string;
};

const useStyles = makeStyles(() => ({
  item: {
    margin: "15px",
  },
}));

export const PageStruct: React.FC<Prop> = ({
  page,
  pageData,
  setFullPage,
  bid,
  createdBy,
}) => {
  const classes = useStyles();
  const render = (x: Element, pageData: BuilderFields["pages"][0]) => {
    switch (x.type) {
      case "text":
        return <TextType text={x.text!} />;
      case "image":
        if (pageData.elements.length === 1) {
          setFullPage();
          return <ImageType media={x} />;
        }
        break;
      case "quote":
        return <QuoteType quote={x} />;
      case "link":
        setFullPage();
        return <MediaType media={x} />;
      case "comments":
        return (
          <CommentsType
            comments={x}
            d={{ bid, pid: (pageData as any)._id, eid: (x as any)._id }}
          />
        );
      case "question":
        return <Question question={x} />;
      case "movie":
        setFullPage();
        return <MovieType media={x} createdBy={createdBy} />;
      default:
        return <></>;
    }
  };

  if (pageData === undefined) return <Loading />;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4">{pageData.title}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 100 }}>
        {pageData.elements.map((x, i) => (
          <Grid key={i} item xs={12} className={classes.item}>
            {render(x, pageData)}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
