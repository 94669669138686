import React from "react";
import { OtherComponent } from "ez-formikui";
import { useTranslation } from "react-i18next";
import { Typography, Box, Grid, useTheme } from "@material-ui/core";
import { MySelectWrapper } from "../../../common/TableAbstract";

export const Tag = (options: any) => {
  const theme = useTheme();
  const Component: OtherComponent = ({ value, setValue, errorMsg, values }) => {
    const [edit, setEdit] = React.useState(value !== "");
    const { t } = useTranslation();
    const boxShadow = "10px 10px 5px -5px rgba(0,0,0,0.34)";
    const style = {
      background: "white",
      borderRadius: 2,
      boxShadow,
      WebkitBoxShadow: boxShadow,
      MozBoxShadow: boxShadow,
    };

    return (
      <Grid container item xs={12} justify="center">
        <Grid item xs={6}>
          <>
            {edit ? (
              <>
                {MySelectWrapper({
                  label: t("builder tag"),
                  options,
                  other: {
                    isMulti: false,
                    value,
                    styles: {
                      container: (old) => ({
                        ...old,
                        ...(errorMsg ? { border: "1px solid red" } : {}),
                      }),
                      valueContainer: (old) => ({
                        ...old,
                        display: "flex",
                        justifyContent: "center",
                      }),
                      singleValue: (old) => ({
                        ...old,
                        ...theme.typography.h5,
                      }),
                      dropdownIndicator: (old) => ({ display: "none" }),
                      indicatorSeparator: (old) => ({ display: "none" }),
                    },
                  },
                })({ value, setValue, errorMsg, values })}
              </>
            ) : (
              <Box display="flex" justifyContent="center">
                <Typography
                  variant="h5"
                  align="center"
                  style={{ ...style, padding: 5 }}
                  onClick={() => setEdit(true)}
                >
                  נושא השיעור
                </Typography>
              </Box>
            )}
          </>
        </Grid>
      </Grid>
    );
  };

  return Component;
};
