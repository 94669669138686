import React from "react";
import { OtherComponent } from "ez-formikui";
import { useTranslation } from "react-i18next";
import { Collapse, TextField, Button } from "@material-ui/core";
import { functions } from "@project/common";

const Discount: OtherComponent = ({ setValue, value, errorMsg }) => {
  const { t } = useTranslation();
  const [show, setShow] = React.useState(false);

  return (
    <div>
      {!show && !functions.isHersog(window.location.hostname) && (
        <Button color="primary" onClick={(_) => setShow(true)}>
          יש לך קוד קופון ? לחץ כאן
        </Button>
      )}

      <Collapse in={show}>
        <TextField
          error={errorMsg && errorMsg !== ""}
          label={t("coupon")}
          margin="dense"
          helperText={errorMsg}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          fullWidth
        />
      </Collapse>
    </div>
  );
};

export default Discount;
