import React from "react";
import Axios from "axios";
import EZFormikUI, { FormikSubmit, Fields } from "ez-formikui";
import { Grid } from "@material-ui/core";
import { LinkedMoviesFields, TagFields, MovieFields } from "@project/types";
import { validation, functions } from "@project/common";
import { useTranslation } from "react-i18next";
import {
  TableActions,
  MySelectWrapper,
  initialValueToOptions,
  WYSIWYGWrapper,
  initialValueToSingle,
} from "../../../common/TableAbstract";
import { useFetch } from "../../../../hooks";
import Loading from "../../../common/Loading";

const { convertToOptions } = functions;

type Props = {
  tableActions: TableActions;
  rowData?: LinkedMoviesFields;
  moviesArr?: MovieFields[];
};

const LinkedMoviesForm: React.FC<Props> = ({
  tableActions,
  rowData: linkedMovies,
  moviesArr,
}) => {
  const { t } = useTranslation();
  const { selectedLang: lang } = tableActions;
  const { data: movies, loading: l1 } = useFetch<LinkedMoviesFields[]>(
    "/admins/movies/",
  );

  const mid1 = moviesArr ? moviesArr[0] : undefined;
  const mid2 = moviesArr ? moviesArr[1] : undefined;
  const mid3 = moviesArr ? moviesArr[2] : undefined;
  const mid4 = moviesArr ? moviesArr[3] : undefined;

  const { data: tags, loading: l2 } = useFetch<TagFields[]>("/admins/tags/");

  const loading = l1 || l2;
  if (loading) return <Loading />;

  const movieOptions = convertToOptions(movies!, "_id", "name", lang);

  const url = linkedMovies
    ? `/admins/linkedMovies/${linkedMovies._id}`
    : "/admins/linkedMovies/";

  const onSubmit: FormikSubmit = async (values) => {
    const data = { ...values };
    data.tags = data.tags.map((tag: any) => tag.value);

    data.mid1 = data.mid1.value ? data.mid1.value : undefined;
    data.mid2 = data.mid2.value ? data.mid2.value : undefined;
    data.mid3 = data.mid3.value ? data.mid3.value : undefined;
    data.mid4 = data.mid4.value ? data.mid4.value : undefined;

    const Method = linkedMovies ? Axios.put : Axios.post;
    const postAction = linkedMovies
      ? tableActions.updateRow
      : tableActions.addNewRow;
    const res = await Method(url, data);

    tableActions.closeDialog();

    if (moviesArr) return;

    postAction(res.data);
  };

  const fields: Fields = [
    {
      fieldName: "name",
      type: "text",
      options: "text",
      label: t("add-linkeds name"),
      initialValue: linkedMovies?.name[lang] || "",
    },
    {
      fieldName: "keys",
      type: "text",
      options: "text",
      label: t("add-linkeds keys"),
      initialValue: linkedMovies?.keys || "",
    },
    {
      fieldName: "text1",
      type: "other",
      label: "",
      component: WYSIWYGWrapper({ label: t("admin-linkeds text1") }),
      initialValue: linkedMovies?.movies.text1
        ? linkedMovies?.movies.text1[lang]
        : "",
    },
    {
      fieldName: "text2",
      type: "other",
      label: "",
      component: WYSIWYGWrapper({ label: t("admin-linkeds text2") }),
      initialValue: linkedMovies?.movies.text2
        ? linkedMovies?.movies.text2[lang]
        : "",
    },
    {
      fieldName: "text3",
      type: "other",
      label: "",
      component: WYSIWYGWrapper({ label: t("admin-linkeds text3") }),
      initialValue: linkedMovies?.movies.text3
        ? linkedMovies?.movies.text3[lang]
        : "",
    },
    {
      fieldName: "text4",
      type: "other",
      label: "",
      component: WYSIWYGWrapper({ label: t("admin-linkeds text4") }),
      initialValue: linkedMovies?.movies.text4
        ? linkedMovies?.movies.text4[lang]
        : "",
    },
    {
      fieldName: "mid1",
      type: "other",
      label: "",
      component: MySelectWrapper({
        label: t("add-linked mid1"),
        options: movieOptions,
      }),
      initialValue: initialValueToSingle(
        mid1 || (linkedMovies?.movies.mid1 as MovieFields),
        "_id",
        "name",
        lang,
      ),
    },
    {
      fieldName: "mid2",
      type: "other",
      label: "",
      component: MySelectWrapper({
        label: t("add-linked mid2"),
        options: movieOptions,
      }),
      initialValue: initialValueToSingle(
        mid2 || (linkedMovies?.movies.mid2 as MovieFields),
        "_id",
        "name",
        lang,
      ),
    },
    {
      fieldName: "mid3",
      type: "other",
      label: "",
      component: MySelectWrapper({
        label: t("add-linked mid3"),
        options: movieOptions,
      }),
      initialValue: initialValueToSingle(
        mid3 || (linkedMovies?.movies.mid3 as MovieFields),
        "_id",
        "name",
        lang,
      ),
    },
    {
      fieldName: "mid4",
      type: "other",
      label: "",
      component: MySelectWrapper({
        label: t("add-linked mid4"),
        options: movieOptions,
      }),
      initialValue: initialValueToSingle(
        mid4 || (linkedMovies?.movies.mid4 as MovieFields),
        "_id",
        "name",
        lang,
      ),
    },
    {
      fieldName: "tags",
      type: "other",
      label: "",
      component: MySelectWrapper({
        label: t("add-movies tags-label"),
        options: convertToOptions(tags!, "_id", "name", lang),
        other: { isMulti: true },
      }),
      initialValue: initialValueToOptions(
        linkedMovies?.tags as TagFields[],
        "_id",
        "name",
        lang,
      ),
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <EZFormikUI
          fields={fields}
          validationSchema={validation.admins.addLinkedMovie(lang)}
          onSubmit={onSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default LinkedMoviesForm;
