import React from "react";
import { useTranslation } from "react-i18next";
import { changeText } from "ez-formikui";
import Snackbar from "./Snackbar";
import Dialog from "./Dialog";
import CookieAlert from "./CookieAlert";

function Utils() {
  const { t } = useTranslation();
  const text = {
    addNewItem: (name: string) => `${t("formik new item")}`,
    checkCaptcha: t("captcha"),
    selectEmpty: t("empty select"),
    submit: t("inputs submit"),
  };
  changeText(text);

  return (
    <>
      <Snackbar />
      <Dialog />
      <CookieAlert />
    </>
  );
}

export default Utils;
