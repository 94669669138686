import React from "react";
import MyTabs from "./Tabs";

export default function Home() {
  return (
    <div>
      <MyTabs />
    </div>
  );
}
