/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Axios from "axios";
import jwtDecode from "jwt-decode";
import { UserPayload } from "@project/types";
import { isProduction, setAuthToken } from "./functions";
import { logoutUser, refreshJwt, setCurrentUser } from "../redux/actions/authActions";
import store from "../redux";
import { setLanguage, addTab, setMovies } from "../redux/actions/utilsActions";
import { TabItem, SavedMovieItem } from "../redux/slices/utils";

const loadLocalStorage = () => {
  if (localStorage.jwtToken) {
    setAuthToken(localStorage.jwtToken);
    refreshJwt();
    const decoded: UserPayload = jwtDecode(localStorage.jwtToken);
    const currentTime = Date.now() / 1000;

    if (isProduction() && decoded.exp < currentTime) {
      store.dispatch(logoutUser());
      window.location.href = "/login";
    } else {
      store.dispatch(setCurrentUser(decoded));
    }
  }

  if (localStorage.language) {
    store.dispatch(setLanguage(localStorage.language));
    Axios.defaults.headers.common.selectedLang = localStorage.language;
  }

  if (localStorage.tabs) {
    const tabs: TabItem[] = JSON.parse(localStorage.getItem("tabs")!);

    tabs
      .filter((tab) => tab.closeable && tab.type !== "search")
      .map((tab) => store.dispatch(addTab(tab)));
  }

  if (localStorage.savedMovies) {
    const arr: SavedMovieItem[] = JSON.parse(
      localStorage.getItem("savedMovies")!,
    );
    store.dispatch(setMovies(arr));
  }
};

export default loadLocalStorage;
