import React from "react";
import { Container, makeStyles, Box, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    minHeight: "82vh",
    // backgroundColor: "#B0D6E1",
  },
  button: {
    fontWeight: "bold",
    fontSize: "1rem",
  },
}));

type AlignCenterProps = {
  children: React.ReactElement;
};

export const AlignCenter = ({ children }: AlignCenterProps) => {
  const classes = useStyles();

  return (
    <Container maxWidth={false} className={classes.container}>
      {children}
    </Container>
  );
};

type AuthLinksProps = {
  links: { text: string; to: string }[];
};

export const AuthLinks = ({ links }: AuthLinksProps) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={3}
    >
      {links.map((l) => (
        <Button
          className={classes.button}
          key={l.to}
          component={Link}
          to={l.to}
        >
          {l.text}
        </Button>
      ))}
    </Box>
  );
};
