/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { OtherComponent } from "ez-formikui";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@material-ui/core";

const Terms: OtherComponent = ({ setValue, value, errorMsg }) => {
  const { t } = useTranslation();

  return (
    <div>
      {t("please confirm")} <a href="/pages/terms">{t("terms of use")}</a>
      <Checkbox
        color="primary"
        checked={value}
        onChange={(e) => setValue(!value)}
      />
      <p style={{ color: "red" }}>{errorMsg}</p>
    </div>
  );
};

export default Terms;
