/* eslint-disable no-alert */
import React from "react";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import { makeStyles } from "@material-ui/core/styles";
import { functions } from "@project/common";
import Image from "@material-ui/icons/Image";
import { useLangSelector } from "../../redux";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .ql-editor": {
      textAlign: "right!important",
    },
  },
}));

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ align: [] }],
  ["link"],

  ["clean"], // remove formatting button
];

export type Props = {
  value: any;
  setValue: (selected: any) => void;
};

const WYSIWYG: React.FC<Props> = ({ value, setValue }) => {
  const lang = useLangSelector();
  const isRTL = functions.isRTL(lang);
  const classes = useStyles({ isRTL });
  const modules = {
    toolbar: toolbarOptions,
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
    "align",
    "direction",
    "size",
    "code-block",
    "background",
    "image",
    "height",
    "width",
  ];

  const imageHandle = () => {
    const url = prompt("url");
    const height = prompt("height");
    const width = prompt("width");

    if (url) {
      setValue(
        `${value}<img src="${url}" height="${height}"  width="${width}"/>`,
      );
    }
  };
  return (
    <div className="text-editor">
      <Image
        onClick={imageHandle}
        style={{
          cursor: "pointer",
          position: "relative",
          top: 37,
          zIndex: 1001,
        }}
      />
      <ReactQuill
        theme="snow"
        className={classes.root}
        modules={modules}
        formats={formats}
        value={value}
        onChange={setValue}
      />
    </div>
  );
};

export default WYSIWYG;
