import React, { useEffect, useState } from "react";
import { Question as QuestionType } from "@project/types";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../../../redux";
import {
  clearT5Error,
  setT5Answer,
  setT5Error,
} from "../../../../../../redux/actions/t5Actions";
import { T5Text } from "./Text";
import { T5Checkbox } from "./Checkbox";
import { T5Radio } from "./Radio";

type Props = {
  question: QuestionType;
};

export const Question = ({ question }: Props) => {
  const questionID = (question as any)._id;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const errorMsg = useTypedSelector((state) => state.t5.errors[questionID]);
  const answer = useTypedSelector((state) => state.t5.answers[questionID]);
  const [touched, setTouched] = useState(false);
  const { required } = question;
  const label = t("t5 enter-answer");

  const gotError = touched && !!errorMsg;

  const onChange = (newAnswer: string) =>
    dispatch(setT5Answer(questionID, newAnswer));

  const setError = (error: string) => {
    dispatch(setT5Error(questionID, error));
  };

  const clearError = () => dispatch(clearT5Error(questionID));

  const renderQuestion = () => {
    switch (question.qType) {
      case "checkbox":
        return (
          <T5Checkbox
            answer={answer}
            gotError={gotError}
            onChange={onChange}
            setTouched={setTouched}
            errorMsg={errorMsg}
            answers={question.answers}
            label={label}
          />
        );
      case "radio":
        return (
          <T5Radio
            answer={answer}
            gotError={gotError}
            onChange={onChange}
            setTouched={setTouched}
            errorMsg={errorMsg}
            answers={question.answers}
            label={label}
          />
        );
      case "text":
        return (
          <T5Text
            answer={answer}
            gotError={gotError}
            onChange={onChange}
            setTouched={setTouched}
            errorMsg={errorMsg}
            label={label}
          />
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (required) {
      if (touched && answer === "") setError(t("t5 required-field"));
      else if (!!answer && !!errorMsg) clearError();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer, errorMsg]);

  useEffect(() => {
    if (required) setError(t("t5 required-field"));
    onChange("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>{question.question}</Typography>
      </Grid>
      <Grid item xs={12}>
        {renderQuestion()}
      </Grid>
    </Grid>
  );
};
