/* eslint-disable react/no-danger */
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { FolderFields, MovieFields } from "@project/types";
import React from "react";
import { useFetch } from "../../hooks";
import { useLangSelector } from "../../redux";
import Loading from "../common/Loading";

const useStyles = makeStyles((theme) => ({
  borders: {
    border: "1px solid black",
    borderCollapse: "collapse",
    textAlign: "left",
  },
  td: {
    padding: 5,
    textAlign: "left",
  },
}));

export const GenerateCatalog = () => {
  const lang = useLangSelector();
  const classes = useStyles();
  const { loading, data } = useFetch<{
    movies: MovieFields[];
    folders: FolderFields[];
  }>("/admins/generalActions/generateCatalog");

  if (loading) return <Loading />;

  document.getElementById("NagishLiBarStrip")?.remove();

  return (
    <Grid className="pdf" container style={{ display: "block", padding: 10 }}>
      {data!.folders.map((f) => (
        <React.Fragment key={f._id}>
          <Typography variant="h6" color="primary" style={{ margin: "20px 0" }}>
            {f.name[lang]}
          </Typography>

          <table
            className={classes.borders}
            style={{ width: "100%", pageBreakInside: "auto" }}
          >
            {data!.movies
              .filter((m) => (m.folders as string[]).includes(f._id))
              .map((m) => (
                <tr
                  key={`${m._id}:${f._id}`}
                  style={{ pageBreakInside: "avoid", pageBreakAfter: "auto" }}
                >
                  <td className={`${classes.borders} ${classes.td}`}>
                    {m.name[lang]}
                  </td>
                  <td
                    className={`${classes.borders} ${classes.td}`}
                    dangerouslySetInnerHTML={{ __html: m.short[lang] || "" }}
                  />
                </tr>
              ))}
          </table>
        </React.Fragment>
      ))}
    </Grid>
  );
};
