import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Divider, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ShareAnswerFields, ShareFields } from "@project/types";
import { useDispatch } from "react-redux";
import TableAbstract, { Columns, Urls } from "../../common/TableAbstract";
import { openDialog } from "../../../redux/actions/utilsActions";

export const ShareAnswers: React.FC<RouteComponentProps<{ id: string }>> = ({
  match: { params },
}) => {
  const { id } = params;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getLabel = (questionID: string, share: ShareFields) => {
    const index = share.questions.findIndex((q) => q._id === questionID);

    if (index < 0) return "Error";

    return share.questions[index].label;
  };

  const showAnswers = (rowData: ShareAnswerFields) =>
    dispatch(
      openDialog(
        t("share answers"),
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6" color="primary">
              {t("filledBy")}
            </Typography>
          </Grid>

          <Grid item xs={6} style={{ marginBottom: 20 }}>
            <Typography variant="body1">{rowData.filledBy}</Typography>
          </Grid>
          <Divider />

          {rowData.answers.map((a) => (
            <>
              <Grid key={a._id} item xs={12}>
                <Typography variant="h6">
                  {getLabel(a._id, rowData.share as ShareFields)}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">{a.answer}</Typography>
              </Grid>
            </>
          ))}
        </Grid>,
      ),
    );

  const urls: Urls = {
    mount: `/users/shares/${id}`,
  };
  const columns: Columns = ({ selectedLang: lang }) => [
    {
      title: t("filledBy"),
      field: "filledBy",
      editable: "never",
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      render: (rowData) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => showAnswers(rowData as ShareAnswerFields)}
        >
          {t("show answers")}
        </Button>
      ),
    },
  ];

  return (
    <TableAbstract
      title={t("shared movies")}
      columns={columns}
      urls={urls}
      tableOptions={{ exportButton: false }}
    />
  );
};
