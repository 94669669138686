import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid } from "@material-ui/core";
import UserActions from "./UserActions";
import Groupactions from "./Groupactions";
import UserFiles from "./userFiles";
import UserLiked from "./userLiked";
import { useLangSelector, useGuaranteedUserSelector } from "../../../../redux";

const Actions: React.FC = () => {
  const sLang = useLangSelector();
  const u = useGuaranteedUserSelector();

  const selectedLang = sLang;
  const user = u;

  const [actions, setActions] = useState([]);
  const [gactions, setGActions] = useState([]);
  const [factions, setFActions] = useState([]);
  const [lactions, setLActions] = useState([]);
  const hasGroup = user!.group !== undefined;
  useEffect(() => {
    const fetch = async () => {
      const group = await axios("/users/profile/groupActions");
      const my = await axios("/users/profile/userActions");
      const files = await axios(`/users/files/user/${u._id}`);
      const liked = await axios("/users/likes/");

      setActions(my.data.filter((movie: any) => movie.movieID?.isActive));
      setGActions(group.data.filter((movie: any) => movie.movieID?.isActive));
      setFActions(files.data);
      setLActions(liked.data.filter((m: any) => m?.isActive));
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasGroup]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={3}>
        <UserLiked actions={lactions} selectedLang={selectedLang} />
      </Grid>
      <Grid item xs={12} lg={3}>
        <UserFiles actions={factions} selectedLang={selectedLang} />
      </Grid>
      <Grid item xs={12} lg={3}>
        <UserActions
          actions={actions}
          selectedLang={selectedLang}
          userName={user.name}
        />
      </Grid>
      {hasGroup && (
        <Grid item xs={12} lg={3}>
          <Groupactions actions={gactions} selectedLang={selectedLang} />
        </Grid>
      )}
    </Grid>
  );
};

export default Actions;
