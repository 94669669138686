import React from "react";
import { OtherComponent } from "ez-formikui";
import { useTranslation } from "react-i18next";
import {
  TextField,
  Typography,
  Box,
  Grow,
  Grid,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textInput: {
    ...theme.typography.h4,
    textAlign: "center",
  },
}));

export const Title: OtherComponent = ({ value, setValue, errorMsg }) => {
  const classes = useStyles();
  const [edit, setEdit] = React.useState(value !== "");
  const { t } = useTranslation();
  const boxShadow = "10px 10px 5px -5px rgba(0,0,0,0.34)";
  const style = {
    background: "white",
    borderRadius: 2,
    boxShadow,
    WebkitBoxShadow: boxShadow,
    MozBoxShadow: boxShadow,
  };

  return (
    <>
      {edit ? (
        <Grow in={edit}>
          <Box display="flex" justifyContent="center">
            <Grid item md={6} xs={12}>
              <TextField
                error={!!errorMsg}
                value={value}
                helperText={errorMsg}
                style={style}
                placeholder={t("builder title")}
                fullWidth
                autoFocus
                onChange={(e) => setValue(e.target.value)}
                inputProps={{ className: classes.textInput }}
              />
            </Grid>
          </Box>
        </Grow>
      ) : (
        <Box display="flex" justifyContent="center">
          <Typography
            variant="h4"
            align="center"
            style={{ ...style, padding: 5 }}
            onClick={() => setEdit(true)}
          >
            איך היית רוצה לקרוא לשיעור הזה?
          </Typography>
        </Box>
      )}
    </>
  );
};
