import { Container } from "@material-ui/core";
import { BuilderFields } from "@project/types";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useFetch } from "../../../hooks";
import Loading from "../../common/Loading";
import { BuilderForm } from "./Form";

export const EditBuilder: React.FC<RouteComponentProps<{ id: string }>> = ({
  match: { params },
}) => {
  const { id } = params;
  const { data, loading } = useFetch<BuilderFields>(`/users/builders/${id}`);

  if (loading) return <Loading />;

  return (
    <Container maxWidth={false} disableGutters>
      <img
        src="https://images.unsplash.com/photo-1604820468298-c75d4328346f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=758&q=80"
        alt="asd"
        style={{ width: "100%", maxHeight: "20vh", zIndex: -1 }}
      />
      <BuilderForm builder={data!} />
    </Container>
  );
};
