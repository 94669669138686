import { useCallback, useEffect, useState } from "react";

const useTimer = () => {
  const initialTime = 0;
  const interval = 1000;
  const step = 1;
  const timerType = "INCREMENTAL";
  const endTime = undefined;
  const onTimeOver = undefined;

  const [time, setTime] = useState(initialTime);
  const [isRunning, setIsRunning] = useState(false);
  const [isTimeOver, setIsTimeOver] = useState(false);

  const reset = useCallback(() => {
    setIsRunning(false);
    setIsTimeOver(false);
    setTime(initialTime);
  }, [initialTime]);

  const start = useCallback(() => {
    if (isTimeOver) {
      reset();
    }

    setIsRunning(true);
  }, [reset, isTimeOver]);

  const pause = useCallback(() => {
    setIsRunning(false);
  }, []);

  useEffect(() => {
    if (isRunning && time === endTime) {
      setIsRunning(false);
      setIsTimeOver(true);
    }
  }, [endTime, onTimeOver, time, isRunning]);

  useEffect(() => {
    let intervalId: null | NodeJS.Timeout = null;

    if (isRunning) {
      intervalId = setInterval(() => {
        setTime((previousTime) => previousTime + step);
      }, interval);
    } else if (intervalId) {
      clearInterval(intervalId);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isRunning, step, timerType, interval]);

  return { isRunning, pause, reset, start, time };
};

export default useTimer;
