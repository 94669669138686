import { Grid, makeStyles, Typography } from "@material-ui/core";
import { Link } from "@project/types";
import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import ReactPlayer from "react-player";

type Prop = {
  media: Link;
};

const useStyles = makeStyles(() => ({
  floatingArea: {
    width: "80vw",
    height: "60vh",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    textAlign: "center",
  },
  gridStyle: {
    marginTop: "3%",
  },
  credits: {
    marginTop: "20px",
  },
  fullSize: {
    width: "100%",
    height: "100%",
  },
}));

export const MediaType: React.FC<Prop> = ({ media }) => {
  const classes = useStyles();
  const [, setNumPages] = useState(null);
  const [pageNumber] = useState(1);

  const onDocumentLoadSuccess = (num: any) => {
    setNumPages(num);
  };

  const mediaSwitch = () => {
    switch (media.linkType) {
      case "video":
        return (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <ReactPlayer
            width="100%"
            height="100%"
            playing
            controls
            url={media.url}
          />
          // eslint-disable-next-line jsx-a11y/media-has-caption
          // <video src={media.url} />
          // <video src={media.url} width="100%" height="100%" controls />
        );
      case "pdf":
        return (
          <Document
            file="https://www.adobe.com/support/products/enterprise/knowledgecenter/media/c4611_sample_explain.pdf"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        );
      default:
        return (
          <Document
            file="http://www.africau.edu/images/default/sample.pdf"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        );
    }
  };

  return (
    <Grid
      container
      justify="center"
      alignContent="center"
      className={classes.gridStyle}
    >
      <Grid item xs={12} className={classes.floatingArea}>
        {mediaSwitch()}
      </Grid>
      <Grid
        container
        item
        xs={12}
        justify="center"
        alignContent="center"
        className={classes.credits}
      >
        {media.credit && <Typography>{media.credit}</Typography>}
      </Grid>
    </Grid>
  );
};
