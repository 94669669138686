import React from "react";
import { useTranslation } from "react-i18next";
import TableAbstract, { Columns } from "../../../common/TableAbstract";
import FolderForm from "./FolderForm";

const urls = {
  mount: "/admins/folders/",
  delete: (id: string) => `/admins/folders/${id}`,
};

export default function Folders() {
  const { t } = useTranslation();
  const columns: Columns = ({ selectedLang }) => [
    {
      title: t("admin-folders name"),
      field: `name.${selectedLang}`,
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
  ];

  return (
    <TableAbstract
      title={t("admin-folders title")}
      columns={columns}
      AddForm={FolderForm}
      EditForm={FolderForm}
      urls={urls}
      useLang
    />
  );
}
