import React from "react";
import { Typography, Grid, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  clearT5Error,
  setT5Error,
  setT5FullName,
} from "../../../../redux/actions/t5Actions";
import { useTypedSelector } from "../../../../redux";

const fieldName = "fullName";

export const AddName = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fullName = useTypedSelector((state) => state.t5.fullName);
  const [touched, setTouched] = React.useState(false);
  const errorMsg = useTypedSelector((state) => state.t5.errors.fullName);

  const setError = (error: string) => dispatch(setT5Error(fieldName, error));

  React.useEffect(() => {
    if (fullName === "") setError(t("t5 required-field"));
    else dispatch(clearT5Error(fieldName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullName]);

  return (
    <Grid
      container
      justify="center"
      alignContent="center"
      style={{ height: "60%" }}
    >
      <Grid item xs={12}>
        <Typography variant="h5" align="center">
          {t("t5 fill-name")}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          onChange={(e) => dispatch(setT5FullName(e.target.value))}
          error={touched && !!errorMsg}
          helperText={touched && errorMsg}
          onBlur={() => setTouched(true)}
          inputProps={{ style: { textAlign: "center" } }}
        />
      </Grid>
    </Grid>
  );
};
