import i18n from "i18next";
import Hebrew from "./locales/he";
import English from "./locales/en";

i18n.init({
  // we init with resources
  resources: {
    he: {
      translations: Hebrew,
    },
    en: {
      translations: English,
    },
  },
  fallbackLng: "he",
  debug: false,
  lng: "he",

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
