import React from "react";
import { useHistory } from "react-router-dom";
import { validation } from "@project/common";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import LockOutlinedIcon from "@material-ui/icons/Message";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import axios from "axios";
import { useTranslation } from "react-i18next";
import EZFormikUI, { FormikSubmit } from "ez-formikui";
import { useGuaranteedUserSelector, useLangSelector } from "../../redux";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Contact: React.FC = () => {
  const classes = useStyles();
  const user = useGuaranteedUserSelector();
  const lang = useLangSelector();
  const { t } = useTranslation();
  const history = useHistory();

  const onSubmit: FormikSubmit = async (values) => {
    await axios.post("/users/functions/contact", {
      name: user.name,
      email: user.email,
      text: values.text,
      type: "דף צור קשר",
    });
    history.push("/dashboard");
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("contact")}
        </Typography>
        <EZFormikUI
          fields={[
            {
              type: "textarea",
              fieldName: "text",
              label: t("your msg here"),
              rows: 10,
              rowsMax: 50,
              initialValue: "",
            },
          ]}
          onSubmit={onSubmit}
          validationSchema={validation.users.contact(lang)}
        />
      </div>
    </Container>
  );
};

export default Contact;
