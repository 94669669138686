import { Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openDialog } from "../../../../redux/actions/utilsActions";
import TableAbstract, {
  Columns,
  TableActions,
} from "../../../common/TableAbstract";
import LinkedMoviesForm from "../LinkedMovies/LinkedMoviesForm";

const urls = {
  mount: "/admins/suggestedLinked/",
  delete: (id: string) => `/admins/suggestedLinked/${id}`,
};

export default function SuggestedLinked() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const createLinked = (rowData: any, tableActions: TableActions) => {
    const moviesArr = rowData.movies;

    dispatch(
      openDialog(
        "",
        <LinkedMoviesForm tableActions={tableActions} moviesArr={moviesArr} />,
        "",
        true,
      ),
    );
  };

  const columns: Columns = (tableActions) => [
    {
      title: t("created by"),
      field: "createdBy.name",
      filtering: true,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("downloaded movies"),
      field: "movies",
      filtering: false,
      render: (rowData) => (
        <div>
          {rowData.movies
            .map((m: any) => m.name[tableActions.selectedLang])
            .join(", ")}
        </div>
      ),
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("create linked"),
      render: (rowData: any) => (
        <Button
          onClick={() => createLinked(rowData, tableActions)}
          variant="contained"
          color="primary"
        >
          {t("create linked")}
        </Button>
      ),
    },
  ];

  return (
    <TableAbstract
      title={t("admin-pages title")}
      columns={columns}
      urls={urls}
      addFormFullWidth
      useLang
    />
  );
}
