import { Typography } from "@material-ui/core";
import React from "react";

type Prop = {
  text: string;
};

export const TextType: React.FC<Prop> = ({ text }) => {
  return (
    <>
      <Typography>{text}</Typography>
    </>
  );
};
