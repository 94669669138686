import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Movie from ".";

type Props = RouteComponentProps<{ handle: string }>;

const MovieFromHandle: React.FC<Props> = ({ match: { params } }) => {
  const { handle } = params;
  return <Movie handle={handle} />;
};

export default MovieFromHandle;
