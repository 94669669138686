import React from "react";
import moment from "moment";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { GroupFields } from "@project/types";
import TableAbstract, {
  Columns,
  TableActions,
} from "../../../common/TableAbstract";
import GroupForm from "./GroupForm";

const urls = {
  mount: "/admins/groups/",
  delete: (id: string) => `/admins/groups/${id}`,
  payment: "/admins/groups/payGroup",
};

export default function Groups() {
  const { t } = useTranslation();

  const handlePayment = async (
    bid: string,
    gid: string,
    tableActions: TableActions,
  ) => {
    try {
      const res = await Axios.post(urls.payment, { bid, gid });

      tableActions.setTableData((old) => {
        const tmp = [...old];
        const index = tmp.findIndex((i) => i._id === gid);

        if (index < 0) return old;

        const { totalCount } = tmp[index];
        tmp[index] = res.data;
        tmp[index].countBill = 0;
        tmp[index].totalCount = totalCount;

        return tmp;
      });

      tableActions.closeDialog();
    } catch (error) {}
  };

  const viewHistory = (
    billsHistory: GroupFields["billsHistory"],
    gid: string,
    tableActions: TableActions,
  ) => {
    const Component = () => (
      <div>
        {billsHistory.map((p) => (
          <div
            style={{ border: "1px solid black", margin: 5, padding: 5 }}
            key={p._id as string}
          >
            <p>
              {`תאריך יצירת חשבונית:${moment(p.date).format("DD/MM/YYYY")}`}
            </p>
            <p>{`כמות צפיות:${p.amount}`}</p>
            <p>{`מחיר לצפייה:${p.price}`}</p>
            <p>{`סה"כ לתשלום:${p.amount * p.price}`}</p>
            <p>{`שולם?:${p.paid ? "שולם" : "לא שולם"}`}</p>

            {p.paid ? (
              <p>{`תאריך תשלום: ${moment(p.payDate).format("DD/MM/YYYY")}`}</p>
            ) : (
              <Button
                color="secondary"
                variant="contained"
                onClick={() =>
                  handlePayment(p._id as string, gid, tableActions)
                }
              >
                הסדר תשלום
              </Button>
            )}
          </div>
        ))}
      </div>
    );

    tableActions.openDialog(Component);
  };

  const columns: Columns = (tableActions) => [
    {
      title: t("admin-groups name"),
      field: `name.${tableActions.selectedLang}`,
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: "Paying Option",
      field: "payingOption",
      filtering: false,
      lookup: {
        monthly: "חודשי",
        reva: "רבעוני",
      },
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: "Paying Limit",
      field: "payingLimit",
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: "חשבוניות",
      render: (rowData) => (
        <Button
          onClick={() =>
            viewHistory(rowData.billsHistory, rowData._id, tableActions)
          }
        >
          הסטורית תשלומים
        </Button>
      ),
    },
    {
      title: "תשלום חסר?",
      filtering: true,
      render: (rowData) => {
        const bool =
          rowData.billsHistory.filter((b: any) => b.paid === false).length > 0;
        return bool ? "חסר" : "x";
      },
    },
    {
      title: "Price",
      field: "price",
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: "Count from last bill",
      field: "countBill",
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: "Total count",
      field: "totalCount",
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: "Contact Email",
      field: "contactMail",
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
  ];

  return (
    <TableAbstract
      title={t("admin-groups title")}
      columns={columns}
      urls={urls}
      AddForm={GroupForm}
      EditForm={GroupForm}
      addFormFullWidth
      useLang
    />
  );
}
