import React from "react";
import EZFormikUI from "ez-formikui";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { validation } from "@project/common";
import { Grid, Typography, Box } from "@material-ui/core";
import { isProduction } from "../../../helpers/functions";
import { useTypedSelector, useLangSelector } from "../../../redux";
import { FieldsWithTranslation } from "../../../types";
import { AlignCenter } from "../AuthHelpers";

const fields: FieldsWithTranslation = (t) => [
  {
    fieldName: "email",
    label: t("inputs email"),
    type: "text",
    options: "email",
    initialValue: "",
  },
];

export default function SendPwdToken() {
  const lang = useLangSelector();
  const { t } = useTranslation();
  const history = useHistory();
  const isLoggedin = useTypedSelector((state) => state.auth.isLoggedin);
  const [sent, setSent] = React.useState(false);

  const afterDefaultSubmit = () => {
    setSent(true);
  };

  if (isLoggedin) {
    history.push("/dashboard");
  }

  return (
    <AlignCenter>
      <Grid container justify="center">
        {!sent ? (
          <Grid item md={6} xs={12}>
            <EZFormikUI
              fields={fields(t)}
              title={t("reset-password title")}
              paragraph={t("reset-password text")}
              onSubmit="/guests/auth/passwordReset"
              afterDefaultSubmit={afterDefaultSubmit}
              validationSchema={validation.auth.sendEmail(lang)}
              submitProps={{ text: t("inputs submit pass"), grid: { xs: 12 } }}
              useCaptcha={isProduction()}
            />
          </Grid>
        ) : (
          <Box display="flex" flexDirection="column">
            <Typography align="center" variant="h4">
              {t("reset-password success")}
            </Typography>
            <Typography align="center" gutterBottom variant="subtitle1">
              {t("reset-password success text")}
            </Typography>
          </Box>
        )}
      </Grid>
    </AlignCenter>
  );
}
