import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { functions } from "@project/common";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import { Theme, Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import LeftIcon from "@material-ui/icons/ArrowBackIos";
import RightIcon from "@material-ui/icons/ArrowForwardIos";
import { MovieFields } from "@project/types";
import MovieCard from "./MovieCard";
import { useLangSelector } from "../../../redux";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles<Theme, { isRTL: boolean }>((theme) => ({
  arrows: {
    position: "absolute",
    top: "40%",
    width: "100%",
    flexDirection: (props) => (props.isRTL ? "row" : "row-reverse"),
    display: "flex",
    justifyContent: "space-between",
    "& svg": {
      zIndex: 899,
      color: theme.palette.primary.main,
      fontSize: "60px",
    },
  },
}));

type Props = {
  url: string;
  title: string;
};

const HighlightMovies: React.FC<Props> = ({ url, title }) => {
  const selectedLang = useLangSelector();
  const classes = useStyles({ isRTL: functions.isRTL(selectedLang) });
  const [loading, setLoading] = useState(true);
  const [movies, setMovies] = useState<MovieFields[]>([]);
  const [active, setActive] = useState(0);
  const [isCarousel, setIsCarousel] = useState(window.outerWidth < 600);
  const MaxNumOfMovies = 6;
  const numOfMovies = movies.length;

  const handleNext = () => setActive((old) => (old + 1) % numOfMovies);
  const handlePrev = () => {
    const toSet = (active - 1) % numOfMovies;
    setActive(toSet < 0 ? toSet + numOfMovies : toSet);
  };
  const handleResize = () => setIsCarousel(window.outerWidth < 600);

  function shuffle(a: any[]) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await axios(url);
        setMovies(shuffle(res.data).splice(0, MaxNumOfMovies));
        setLoading(false);
      } catch (e) {}
    };

    window.addEventListener("resize", handleResize);
    fetch();

    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, [url]);

  if (loading) {
    return (
      <Grid container>
        {[1, 2, 3, 4, 5, 6].map((i) => (
          <Grid key={i} item xs={6} md={4}>
            <Box width={210} marginRight={0.5} my={5}>
              <Skeleton variant="rect" width={210} height={118} />
              <Box pt={0.5}>
                <Skeleton />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <>
      <Typography
        variant="h4"
        color="primary"
        align="center"
        style={{ margin: "20px 0" }}
      >
        {title}
      </Typography>
      <Grid container spacing={2} style={{ position: "relative" }} key={title}>
        {isCarousel && (
          <div className={classes.arrows}>
            <RightIcon onClick={handleNext} />
            <LeftIcon onClick={handlePrev} />
          </div>
        )}
        {movies.map((movie, i) => (
          <Grid
            item
            sm={4}
            xs={12}
            key={title + movie._id}
            hidden={isCarousel && i !== active}
          >
            <MovieCard movie={movie} selectedLang={selectedLang} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default HighlightMovies;
