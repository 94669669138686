import React from "react";
import Axios from "axios";
import EZFormikUI, { FormikSubmit } from "ez-formikui";
import { constants, validation, functions } from "@project/common";
import { UserFields, GroupFields } from "@project/types";
import { useTranslation } from "react-i18next";
import {
  TableActions,
  MySelectWrapper,
  initialValueToSingle,
} from "../../../common/TableAbstract";
import text from "../../../../helpers/text";
import { useFetch } from "../../../../hooks";
import Loading from "../../../common/Loading";

const { convertToOptions } = functions;

type Props = {
  tableActions: TableActions;
  rowData?: UserFields;
};

const AddUser: React.FC<Props> = ({ tableActions, rowData: user }) => {
  const { t } = useTranslation();
  const {
    selectedLang: lang,
    addNewRow,
    updateRow,
    closeDialog,
  } = tableActions;
  const { data: groups, loading } = useFetch<GroupFields[]>("/admins/groups/");
  const url = user
    ? `/admins/usersManagement/${user._id}`
    : "/admins/usersManagement/";
  const method = user ? Axios.put : Axios.post;
  const schema = user
    ? validation.admins.adminEditUser(lang)
    : validation.admins.adminAddUser(lang);

  if (loading) return <Loading />;

  const onSubmit: FormikSubmit = async (values) => {
    const data: any = { ...values };
    if (data.group === "") {
      data.group = undefined;
    } else {
      data.group = data.group.value;
    }

    const res = await method(url, data);

    if (user) updateRow(res.data);
    else addNewRow(res.data);

    closeDialog();
  };

  return (
    <EZFormikUI
      fields={[
        {
          fieldName: "name",
          label: t("register-name"),
          type: "text",
          options: "text",
          initialValue: user?.name || "",
        },
        {
          fieldName: "email",
          label: t("inputs email"),
          type: "text",
          options: "email",
          initialValue: user?.email || "",
        },
        {
          fieldName: "phone",
          label: t("register-phone"),
          type: "text",
          options: "tel",
          initialValue: user?.phone || "",
        },
        {
          fieldName: "password",
          label: t("reset-password password"),
          type: "text",
          options: "password",
          initialValue: "",
        },
        {
          fieldName: "password2",
          label: t("reset-password password2"),
          type: "text",
          options: "password",
          initialValue: "",
        },
        {
          fieldName: "rank",
          label: text.rankLabel,
          type: "select",
          options: constants.ranks,
          isMulti: false,
          initialValue: user?.rank || constants.ranks[0].value,
        },
        {
          fieldName: "perment",
          label: t("admin-users perment"),
          type: "switch",
          initialValue: user?.perment || false,
        },
        {
          fieldName: "tfa",
          label: t("admin-users tfa"),
          type: "switch",
          initialValue: user?.tfa.use || false,
        },
        {
          fieldName: "activeDate",
          label: t("admin-users activeDate"),
          type: "date",
          initialValue: user?.activeDate,
        },
        {
          fieldName: "group",
          type: "other",
          label: "",
          component: MySelectWrapper({
            label: t("admin-users group"),
            options: convertToOptions(groups!, "_id", "name", lang),
            other: { isMulti: false },
          }),
          initialValue: initialValueToSingle(
            user?.group as GroupFields,
            "_id",
            "name",
            lang,
          ),
        },
      ]}
      onSubmit={onSubmit}
      validationSchema={schema}
    />
  );
};

export default AddUser;
