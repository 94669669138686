/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { validation } from "@project/common";
import EZFormikUI, { AfterDefaultSubmit, Fields } from "ez-formikui";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLangSelector } from "../../../redux";
import { isProduction } from "../../../helpers/functions";
import { loginUser } from "../../../redux/actions/authActions";

type EmailLoginValues = { email: string; password: string };
type PhoneLoginValues = { phone: string };

type Props = {
  values: EmailLoginValues | PhoneLoginValues;
};

const TFA: React.FC<Props> = ({ values }) => {
  const { t } = useTranslation("translations");
  const lang = useLangSelector();
  const dispatch = useDispatch();
  const isPhoneLogin = "phone" in values;

  const emailLoginFields: Fields = [
    {
      fieldName: "email",
      label: "email",
      type: "text",
      options: "email",
      props: { hidden: true, disabled: true, style: { display: "none" } },
      initialValue: (values as EmailLoginValues).email,
    },
    {
      fieldName: "password",
      label: "password",
      type: "text",
      options: "password",
      props: { hidden: true, disabled: true, style: { display: "none" } },
      initialValue: (values as EmailLoginValues).password,
    },
  ];

  const phoneLoginFields: Fields = [
    {
      fieldName: "phone",
      label: "phone",
      type: "text",
      options: "tel",
      props: { hidden: true, disabled: true, style: { display: "none" } },
      initialValue: (values as PhoneLoginValues).phone,
    },
  ];

  const afterDefaultSubmit: AfterDefaultSubmit = (res) => {
    const { token } = res?.data;

    dispatch(loginUser(token));
  };

  return (
    <EZFormikUI
      fields={[
        ...(isPhoneLogin ? phoneLoginFields : emailLoginFields),
        {
          fieldName: "token",
          label: t("login-tokenLabel"),
          type: "text",
          options: "number",
          initialValue: "",
        },
      ]}
      validationSchema={validation.auth.tokenSchema(lang)}
      onSubmit={isPhoneLogin ? "/guests/auth/loginPhone" : "/guests/auth/login"}
      afterDefaultSubmit={afterDefaultSubmit}
      submitProps={{ text: t("login-submit"), grid: { xs: 12 } }}
      useCaptcha={isProduction()}
    />
  );
};

export default TFA;
