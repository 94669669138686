import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import axios from "axios";
import { TFunction } from "i18next";

const labels = (t: TFunction) => ({
  0.5: t("Useless"),
  1: t("Useless+"),
  1.5: t("Poor"),
  2: t("Poor+"),
  2.5: t("Ok"),
  3: t("Ok+"),
  3.5: t("Good"),
  4: t("Good+"),
  4.5: t("Excellent"),
  5: t("Excellent+"),
});

const useStyles = makeStyles({
  rating1: {
    width: 200,
    display: "flex",
    alignItems: "center",
  },
});

type Props = {
  movieID: string;
  userID: string;
  initialValue: number;
  count: number;
};

type Index = keyof ReturnType<typeof labels>;

const Rate: React.FC<Props> = ({ movieID, userID, initialValue, count }) => {
  const [value, setValue] = useState<any>(initialValue / count);
  const [hover, setHover] = useState<any>(-1);
  const classes = useStyles();
  const { t } = useTranslation();
  const index: Index = hover !== -1 ? hover : value;

  const onRateChange = async (rate: number) => {
    setValue(rate);
    await axios.post("/users/functions/rate", {
      userID,
      movieID,
      rate,
    });
  };

  return (
    <div>
      <Box>
        <div className={classes.rating1}>
          <Rating
            name="hover-side"
            value={value}
            precision={0.5}
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
            onChange={(rate, newVal) => {
              if (newVal) {
                onRateChange(newVal);
              }
            }}
          />
          <Box ml={2}>{labels(t)[index]}</Box>
        </div>
      </Box>
    </div>
  );
};

export default Rate;
