import React from "react";
import IdleTimer from "react-idle-timer";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "./redux";
import { logoutUser } from "./redux/actions/authActions";
import { isProduction } from "./helpers/functions";

const Idle = () => {
  const idleTime = React.useRef(null);
  const auth = useTypedSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const onIdle = () => {
    if (isProduction() && auth.isLoggedin) {
      dispatch(logoutUser());
      history.push("/login", { lastPath: window.location.pathname });
    }
  };

  return (
    <IdleTimer
      ref={idleTime}
      element={document}
      onIdle={onIdle}
      debounce={250}
      timeout={1000 * 60 * 60}
    />
  );
};

export default Idle;
