import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: "15px",
  },
  filterGrid: {
    display: "flex",
    justifyContent: "space-between",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

type Props = {
  title: JSX.Element;
  content: JSX.Element;
  other?: { [key: string]: any };
};

const MyCard: React.FC<Props> = ({ title, content, ...other }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card} {...other}>
      <CardHeader
        titleTypographyProps={{ align: "center", color: "primary" }}
        title={title}
      />
      <Divider />

      <CardContent>{content}</CardContent>
    </Card>
  );
};

export default MyCard;
