import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { functions } from "@project/common";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { CommentFields, UserFields } from "@project/types";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AddComment from "./AddComment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxHeight: "60vh",
    overflowY: "scroll",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  primaryColor: {
    backgroundColor: theme.palette.primary.main,
  },
}));

type Props = {
  movieID: string;
  comments: CommentFields[];
  user: UserFields;
};

const Comments: React.FC<Props> = ({
  movieID,
  user,
  comments: initialComments,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [comments, setComments] = useState(initialComments);
  const isAdmin = functions.isAdmin(user);

  const delFunc = async (id: string) => {
    try {
      await axios.delete(`/admins/generalActions/comment/${id}`);
      const old = [...comments];
      const index = old.findIndex((c) => c._id === id);

      if (index !== -1) {
        old.splice(index, 1);
        setComments(old);
      }
    } catch (e) {}
  };
  return (
    <List className={classes.root}>
      <AddComment
        classes={classes}
        user={user}
        movieID={movieID}
        updateComments={setComments}
      />

      <Divider variant="inset" component="li" />
      {comments.map((c) => (
        <React.Fragment key={c._id}>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar className={classes.primaryColor}>
                {(c.userID as UserFields)?.name[0].toUpperCase()}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={(c.userID as UserFields)?.name || t("user deleted")}
              secondary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  />
                  {c.txt}
                  {isAdmin && (
                    <Button onClick={() => delFunc(c._id)}>מחק</Button>
                  )}
                </>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))}
    </List>
  );
};

export default Comments;
