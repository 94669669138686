import React from "react";
import { TextField } from "@material-ui/core";
import { T5QuestionProps } from "./types";

export const T5Text = ({
  answer,
  gotError,
  onChange,
  setTouched,
  errorMsg,
  label,
}: T5QuestionProps) => (
  <TextField
    value={answer || ""}
    label={label}
    onChange={(e) => onChange(e.target.value)}
    error={gotError}
    helperText={gotError && errorMsg}
    onBlur={() => setTouched(true)}
    fullWidth
  />
);
