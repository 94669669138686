import { Link as LinkType } from "@project/types";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { OnChange } from "../../types";

type Props = {
  onChange: OnChange;
  value: LinkType;
  errorObj: { [key: string]: string };
};

export const Link: React.FC<Props> = ({ onChange, value, errorObj }) => {
  const { t } = useTranslation();

  return (
    <Grid container item xs={12} style={{ marginTop: 10 }}>
      <Grid container item md={6} xs={12}>
        <Grid item xs={12}>
          <FormControl variant="filled">
            <InputLabel id="demo-simple-select-outlined-label">
              {t("link type")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={value.linkType}
              onChange={(e) => onChange("linkType", e.target.value)}
              label="qType"
            >
              {/* <MenuItem value="pdf">{t("pdf")}</MenuItem> */}
              <MenuItem value="video">{t("video")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            onChange={(e) => onChange("url", e.target.value)}
            placeholder={t("builder-page link")}
            value={value.url}
            error={"url" in errorObj}
            helperText={errorObj.url}
            fullWidth
            style={{ marginBottom: 5 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            onChange={(e) => onChange("credit", e.target.value)}
            placeholder={t("builder-page credit")}
            value={value.credit}
            fullWidth
          />
        </Grid>
      </Grid>
      {/* <Grid container item md={6} xs={12} justify="center" alignItems="center">
        <Button variant="contained" color="primary">
          {t("upload media file")}
        </Button>
      </Grid> */}
    </Grid>
  );
};
