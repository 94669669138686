import { useState, useEffect, useRef } from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";

type Props = {
  start: number[];
  end: number[];
  autoPlay?: boolean;
  muted?: boolean;
  playing?: boolean;
};

export default ({
  autoPlay = false,
  start,
  end,
  muted = false,
  playing: initialPlaying = true,
}: Props) => {
  const [player, setPlayer] = useState<ReactPlayer | null>(null);
  const [playing, setPlaying] = useState(initialPlaying);
  console.log(start, end);
  const [index, setIndex] = useState(0);
  const onReady: ReactPlayerProps["onReady"] = (player) => {
    if (start.length !== 0) {
      player.seekTo(start[index]);
    }
  };

  const onProg: ReactPlayerProps["onProgress"] = (prog) => {
    if (player !== null && end.length !== 0 && index < end.length) {
      if (end[index] <= prog.playedSeconds) {
        const i = index + 1;
        if (start[i]) player.seekTo(start[i]);
        setIndex(i);
      }
    } else {
      if (end.length !== 0) {
        setPlaying(false);
      }
    }
  };

  return { setPlaying, setPlayer, muted, autoPlay, playing, onReady, onProg };
};
