import React from "react";
import { OtherComponent } from "ez-formikui";
import { functions } from "@project/common";
import { Grid, Typography } from "@material-ui/core";
import MySelect, { MySelectProps } from "../MySelect";
import WYSIWYG from "../WYSIWYG";
import FiltersSelect, { FiltersSelectProps } from "../FiltersSelect";

const { convertToOptions } = functions;

type FParams = Parameters<typeof convertToOptions>;
export const initialValueToOptions = (
  arr: FParams[0] | undefined,
  valueField: FParams[1],
  labelField: FParams[2],
  lang?: FParams[3],
) => (arr ? convertToOptions(arr, valueField, labelField, lang) : []);

// eslint-disable-next-line no-confusing-arrow
export const initialValueToSingle = (
  selected: { [key: string]: any } | undefined,
  valueField: FParams[1],
  labelField: FParams[2],
  lang?: FParams[3],
) =>
  selected ? convertToOptions([selected], valueField, labelField, lang)[0] : "";

type MySelectWrapperProps = Omit<MySelectProps, "value" | "setValue">;
export const MySelectWrapper = (props: MySelectWrapperProps) => {
  const { label, options, grid, name, other } = props;

  const Component: OtherComponent = ({ value, setValue }) => (
    <MySelect
      label={label}
      value={value}
      options={options}
      setValue={setValue}
      grid={grid}
      name={name}
      other={other}
    />
  );

  return Component;
};

type WYSIWYGWrapperProps = { label: string };
export const WYSIWYGWrapper = (props: WYSIWYGWrapperProps) => {
  const { label } = props;

  const Component: OtherComponent = ({ value, setValue }) => (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" color="primary">
          {`${label}:`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <WYSIWYG value={value} setValue={setValue} />
      </Grid>
    </Grid>
  );

  return Component;
};

type FiltersSelectWrapperProps = Omit<FiltersSelectProps, "value" | "setValue">;
export const FiltersSelectWrapper = (props: FiltersSelectWrapperProps) => {
  const { filters, options } = props;

  const Component: OtherComponent = ({ value, setValue }) => (
    <FiltersSelect
      value={value}
      setValue={setValue}
      filters={filters}
      options={options}
    />
  );

  return Component;
};
