import React from "react";
import { useTranslation } from "react-i18next";
import { Box, makeStyles, Theme } from "@material-ui/core";
import { useFetch } from "../../hooks";
import Loading from "../common/Loading";
import { shouldHideNavbarOrFooter } from "../../helpers/functions";

const useStyles = makeStyles<Theme, { hidden: boolean }>((theme) => ({
  footer: {
    minHeight: 100,
    background: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 18,
    display: (props) => (props.hidden ? "none" : "flex"),
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 10,
  },
}));

export default function Footer() {
  const classes = useStyles({ hidden: shouldHideNavbarOrFooter() });
  const { t } = useTranslation();

  const { data, loading } = useFetch<{ count: number }>(
    "/guests/open/moviesCount/",
  );

  const moviesCount = data && data.count > 200 ? data.count : 200;

  return (
    <footer className={classes.footer}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {loading ? (
          <Loading />
        ) : (
          <div>{`${t("num of movies")} - ${moviesCount}`} </div>
        )}
        <div>Copyright © {new Date().getFullYear()} Edu-Movie </div>
        <div>Developed By Plan-it</div>
      </Box>
    </footer>
  );
}
