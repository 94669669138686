import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Axios from "axios";
import { Button } from "@material-ui/core";
import TableAbstract, { Columns, Urls } from "../../../common/TableAbstract";
import MovieForm from "./MovieForm";

const urls: Urls = {
  mount: "/admins/movies/",
  delete: (id: string) => `/admins/movies/${id}`,
  getByID: (id: string) => `/admins/movies/${id}`,
};

export default function Movies() {
  const { t } = useTranslation();
  const columns: Columns = ({ selectedLang: lang, updateRow }) => [
    {
      title: t("admin-movies name"),
      render: (rowData) => (
        <Link to={`/dashboard/movie/${rowData.name2}`} target="_blank">
          {rowData.name[lang]}
        </Link>
      ),
      field: `name.${lang}`,
      filtering: false,
      editable: "never",
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("admin-movies handle"),
      field: "name2",
      editable: "never",
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("admin-movies studio"),
      field: "studio.name",
      editable: "never",
      filtering: true,
      emptyValue: "-",
    },
    {
      title: t("admin-movies lenght"),
      field: "lenght",
      editable: "never",
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("admin-movies percent"),
      field: "watchPercent.percent",
      type: "numeric",
      editable: "never",
      render: (rowData) => rowData.watchPercent.percent.toFixed(2),
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("admin-movies views"),
      field: "views",
      type: "numeric",
      editable: "never",
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("admin-movies monthlyViews"),
      field: "monthlyViews",
      type: "numeric",
      editable: "never",
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("admin-movies monthlyViews"),
      field: "monthlyViews",
      type: "numeric",
      editable: "never",
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
      render: (rowData) => (
        <div>
          {rowData.monthlyViews}
          <Button
            onClick={async () => {
              const res = await Axios.post("/admins/movies/resetMonthlyViews", {
                movieID: rowData._id,
              });
              updateRow(res.data);
            }}
          >
            {t("resetMonth")}
          </Button>
        </div>
      ),
    },
    {
      title: t("admin-movies rating"),
      field: "rating.rate",
      type: "numeric",
      editable: "never",
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("admin-movies active"),
      field: "isActive",
      type: "boolean",
      editable: "never",
      filtering: true,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("admin-movies validatedText"),
      field: "validatedText",
      type: "boolean",
      editable: "never",
      filtering: true,
      emptyValue: t("admin-table emptyValue"),
    },
  ];

  return (
    <TableAbstract
      title={t("admin-movies title")}
      columns={columns}
      urls={urls}
      useLang
      AddForm={MovieForm}
      addFormFullWidth
      EditForm={MovieForm}
      useFreshData
    />
  );
}
