import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import {
  MovieFields,
  CommentFields,
  LinkedMoviesFields,
  UploadFields,
} from "@project/types";
import Loading from "../../common/Loading";
import { useLangSelector } from "../../../redux";
import MovieContainer from "./MovieContainer";

type Props = {
  handle?: string;
};

type Data =
  | {
      movie: MovieFields;
      comments: CommentFields[];
      files: UploadFields[];
      linkedMovie: LinkedMoviesFields;
    }
  | undefined;

const Movie: React.FC<Props> = ({ handle }) => {
  const [data, setData] = useState<Data>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const lang = useLangSelector();

  useEffect(() => {
    const fetch = async () => {
      try {
        const movie: MovieFields = await (
          await Axios(`/users/movies/handle/${handle}`)
        ).data;
        const linkedMovie: LinkedMoviesFields = await (
          await Axios(`/users/linkedMovies/getOne/${movie._id}`)
        ).data;
        const comments: CommentFields[] = await (
          await Axios(`/users/movies/comments/${movie._id}`)
        ).data;
        const files: UploadFields[] = await (
          await Axios(`/users/files/movie/${movie._id}`)
        ).data;

        setData({ comments, files, movie, linkedMovie });
      } catch (e) {
        setError(true);
      }
      setLoading(false);
    };

    fetch();
  }, [handle]);

  if (loading) return <Loading />;
  if (error) return <div>{t("movie notFound")}</div>;
  if (!data?.movie.name[lang]) return <div>{t("movie noLang")}</div>;

  const { comments, files, linkedMovie, movie } = data;

  return (
    <MovieContainer
      comments={comments}
      files={files}
      linkedMovie={linkedMovie}
      movie={movie}
    />
  );
};

export default Movie;
