/* eslint-disable react/no-danger */
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/Accordion";
import MuiExpansionPanelSummary from "@material-ui/core/AccordionSummary";
import MuiExpansionPanelDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

export default function Collapse({ movie, selectedLang }: any) {
  const [expanded, setExpanded] = React.useState([
    { panel1: true, panel2: true, panel3: true, panel4: true },
  ]);
  const { t } = useTranslation();
  type Expand = typeof expanded[0];

  const handleChange = (panel: keyof Expand) => (
    event: any,
    newExpanded: any,
  ) => {
    const old = [...expanded];
    old[0][panel] = !old[0][panel];
    const newArr = old;
    setExpanded(newArr);
  };
  const regex = /(<([^>]+)>)/gi;
  const prob = movie.problemText[selectedLang];
  const q = movie.qna[selectedLang];
  const shi = movie.shimush[selectedLang];
  const kshu = movie.kshurim[selectedLang];
  const result1 = prob.replace(regex, "");
  const result2 = q.replace(regex, "");
  const result3 = shi.replace(regex, "");
  const result4 = kshu.replace(regex, "");
  return (
    <div>
      {result1 && (
        <ExpansionPanel
          square
          expanded={expanded[0].panel1}
          onChange={handleChange("panel1")}
        >
          <ExpansionPanelSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography color="primary">
              <strong>{t("movie problemText")}</strong>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <span
                dangerouslySetInnerHTML={{
                  __html: movie.problemText[selectedLang],
                }}
              />
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {result2 && (
        <ExpansionPanel
          square
          expanded={expanded[0].panel2}
          onChange={handleChange("panel2")}
        >
          <ExpansionPanelSummary
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography color="primary">
              <strong>{t("movie qna")}</strong>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <span
                dangerouslySetInnerHTML={{ __html: movie.qna[selectedLang] }}
              />
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {result3 && (
        <ExpansionPanel
          square
          expanded={expanded[0].panel3}
          onChange={handleChange("panel3")}
        >
          <ExpansionPanelSummary
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography color="primary">
              <strong>{t("movie shimush")}</strong>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <span
                dangerouslySetInnerHTML={{
                  __html: movie.shimush[selectedLang],
                }}
              />
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {result4 && (
        <ExpansionPanel
          square
          expanded={expanded[0].panel4}
          onChange={handleChange("panel4")}
        >
          <ExpansionPanelSummary
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography color="primary">
              <strong>{t("movie kshurim")}</strong>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <span
                dangerouslySetInnerHTML={{
                  __html: movie.kshurim[selectedLang],
                }}
              />
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
    </div>
  );
}
