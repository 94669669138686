import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import RemoveIcon from "@material-ui/icons/Close";
import TextIcon from "@material-ui/icons/TextFields";
import QnaIcon from "@material-ui/icons/QuestionAnswer";
import LinkIcon from "@material-ui/icons/Movie";
import MovieIcon from "@material-ui/icons/Theaters";
import CommentsIcon from "@material-ui/icons/GroupAdd";
import QuoteIcon from "@material-ui/icons/FormatQuote";
import ImageIcon from "@material-ui/icons/Image";

export const builderIcons = {
  moveDown: ArrowDownIcon,
  moveUp: ArrowUpIcon,
  remove: RemoveIcon,
  text: TextIcon,
  qna: QnaIcon,
  link: LinkIcon,
  movie: MovieIcon,
  comments: CommentsIcon,
  quote: QuoteIcon,
  image: ImageIcon,
};
