import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { functions } from "@project/common";
import { Container, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import { useLangSelector } from "../../../redux";
import Button from "./Button";
import { addTab } from "../../../redux/actions/utilsActions";
import { colors } from "../../../helpers/colors";
import { FolderFields, MovieFields } from "@project/types";
import FolderButton from "./FolderButton";
import MovieCard from "../../common/HighlightMovies/MovieCard";

const useStyles = makeStyles((theme) => ({
  filterGrid: {
    display: "flex",
    justifyContent: "space-between",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  mobileNoPadding: {
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 1,
      paddingRight: 1,
    },
  },
}));

type Props = {
  folderID: string | JSX.Element;
  tagID: string | JSX.Element;
};

export const MovieFolder: React.FC<Props> = ({ folderID, tagID }) => {
  const selectedLang = useLangSelector();
  const classes = useStyles({ isRTL: functions.isRTL(selectedLang) });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [movies, setMovies] = useState<MovieFields[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const moviesList =
        tagID === ""
          ? await axios(`/users/folders/movies/${folderID}`)
          : await axios(`/users/tags/movies/${tagID}`);

      const moviesData = moviesList.data
        .filter((movie: any) => functions.gotLang(movie.name, selectedLang))
        .sort((a: any, b: any) =>
          a.name[selectedLang].toLowerCase() <
          b.name[selectedLang].toLowerCase()
            ? -1
            : 1,
        );

      setMovies(moviesData);
    };
    fetch();
    // eslint-disable-next-line
  }, []);
  if (movies.length === 0) {
    return (
      <Typography
        variant="h4"
        color="primary"
        paragraph
        gutterBottom
        align="center"
        style={{ marginTop: "100px" }}
      >
        {t("no content in folder")}
      </Typography>
    );
  }
  return (
    <Container maxWidth="xl" className={classes.mobileNoPadding}>
      <Grid container spacing={1}>
        {movies.map((movie, i) => {
          return (
            <Grid key={movie._id + 2} item lg={3} md={6} xs={12}>
              {/* <FolderButton
                extendData={false}
                title={movie.name[selectedLang]}
                background={{ data: movie.imgLink, type: "url" }}
                url={() =>
                  dispatch(
                    addTab({
                      label: movie.name[selectedLang]!,
                      value: movie.name2,
                      type: "movie",
                      closeable: true,
                    }),
                  )
                }
              /> */}
              <MovieCard movie={movie} selectedLang={selectedLang} />
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default MovieFolder;
