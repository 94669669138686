import React from "react";
import Axios from "axios";
import { validation } from "@project/common";
import EZFormikUI, { Fields, FormikSubmit } from "ez-formikui";
import { TagFields } from "@project/types";
import { TableActions } from "../../../../common/TableAbstract";
import { ImageField } from "./ImageField";

type Props = {
  tableActions: TableActions;
  rowData?: TagFields;
};

export const TagForm: React.FC<Props> = ({ rowData: tag, tableActions }) => {
  const { selectedLang, addNewRow, updateRow, closeDialog } = tableActions;

  const fields: Fields = [
    {
      fieldName: "name",
      label: "שם התגית",
      type: "text",
      options: "text",
      initialValue: tag?.name[selectedLang] || "",
    },
    {
      fieldName: "images",
      type: "array",
      label: "תמונות",
      of: [
        {
          fieldName: "link",
          label: "קישור",
          type: "other",
          component: ImageField,
          initialValue: "",
        },
      ],
      startEmpty: true,
      newItemText: "הוסף תמונה",
      initialValue: tag ? tag.images.map((link) => ({ link })) : [],
    },
  ];

  const onSubmit: FormikSubmit = async (values) => {
    const url = tag ? `/admins/tags/${tag._id}` : "/admins/tags/";
    const method = tag ? Axios.put : Axios.post;

    try {
      const formData = { ...values };
      formData.images = formData.images.map((i: { link: string }) => i.link);
      const res = await method(url, formData);
      const { data } = res;

      if (tag) updateRow(data);
      else addNewRow(data);

      closeDialog();
    } catch (error) {}
  };

  return (
    <EZFormikUI
      validationSchema={validation.admins.addTag(selectedLang)}
      fields={fields}
      onSubmit={onSubmit}
    />
  );
};
