import React from "react";
import { useDispatch } from "react-redux";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import { Checkbox } from "@material-ui/core";
import { UserFields } from "@project/types";
import { toggleLike } from "../../../redux/actions/authActions";

type Props = {
  movieID: string;
  user: UserFields;
};

const Like: React.FC<Props> = ({ movieID, user }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const liked = (user.likes as string[]).includes(movieID);

  const handleClick = async () => {
    try {
      const Method = liked ? Axios.delete : Axios.post;

      await Method(`/users/likes/${movieID}`);
      dispatch(toggleLike(movieID));
    } catch (error) {}
  };

  return (
    <Box display="flex" alignItems="center">
      <Typography component="legend" color="primary">
        {t("like movie")}
      </Typography>
      <Checkbox
        checked={liked}
        icon={<FavoriteIcon style={{ color: "rgba(0, 0, 0, 0.26)" }} />}
        checkedIcon={<FavoriteIcon style={{ color: "#FF3D47" }} />}
        onClick={handleClick}
      />
    </Box>
  );
};

export default Like;
