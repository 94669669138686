import React from "react";
import { validation } from "@project/common";
import EZFormikUI, { Fields, AfterDefaultSubmit } from "ez-formikui";
import { useTranslation } from "react-i18next";
import { PageFields } from "@project/types";
import { TableActions, WYSIWYGWrapper } from "../../../common/TableAbstract";

type Props = {
  tableActions: TableActions;
  rowData?: PageFields;
};

const PageForm: React.FC<Props> = ({ rowData: page, tableActions }) => {
  const { t } = useTranslation();
  const { selectedLang, updateRow, addNewRow, closeDialog } = tableActions;
  const url = page ? `/admins/pages/${page._id}` : "/admins/pages/";
  const method = page ? "put" : "post";

  const fields: Fields = [
    {
      fieldName: "name",
      label: t("admin-pages name"),
      type: "text",
      options: "text",
      initialValue: page?.name[selectedLang] || "",
    },
    {
      fieldName: "handle",
      label: t("admin-pages handle"),
      type: "text",
      options: "text",
      initialValue: page?.handle || "",
    },
    {
      fieldName: "text",
      type: "other",
      label: "",
      component: WYSIWYGWrapper({ label: t("admin-pages text") }),
      initialValue: page?.name[selectedLang] || "",
    },
  ];

  const afterDefaultSubmit: AfterDefaultSubmit = (res) => {
    const { data } = res!;

    if (page) updateRow(data);
    else addNewRow(data);

    closeDialog();
  };

  return (
    <EZFormikUI
      validationSchema={validation.admins.addPage(selectedLang)}
      fields={fields}
      onSubmit={url}
      submitMethod={method}
      afterDefaultSubmit={afterDefaultSubmit}
    />
  );
};

export default PageForm;
