import React from "react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { Question } from "@project/types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { T5QuestionProps } from "./types";

type RadioItemProps = {
  value: string;
  onChange: T5QuestionProps["onChange"];
  answer: T5QuestionProps["answer"];
  label: string;
  setTouched: T5QuestionProps["setTouched"];
};

const RadioItem: React.FC<RadioItemProps> = ({
  label,
  onChange,
  setTouched,
  value,
  answer,
}) => (
  <FormControlLabel
    onBlur={() => setTouched(true)}
    label={label}
    control={<Radio onChange={(e) => onChange(e.target.value)} value={value} />}
    labelPlacement="start"
  />
);

type RadioProps = T5QuestionProps & {
  answers: Question["answers"];
  label: string;
};

export const T5Radio: React.FC<RadioProps> = ({
  onChange,
  label,
  answers,
  gotError,
  setTouched,
  answer,
  errorMsg,
}) => (
  <Grid item xs={12}>
    <FormControl fullWidth margin="dense">
      <FormLabel error={gotError}>{label}</FormLabel>
      <RadioGroup row>
        {answers.map((a, i) => (
          <RadioItem
            key={i}
            value={a}
            label={a}
            onChange={onChange}
            setTouched={setTouched}
            answer={answer}
          />
        ))}
      </RadioGroup>
      {gotError && (
        <FormHelperText error variant="standard" style={{ fontSize: "16px" }}>
          {errorMsg}
        </FormHelperText>
      )}
    </FormControl>
  </Grid>
);
