import axios from "axios";
import jwtDecode from "jwt-decode";
import { UserPayload } from "@project/types";
import { functions } from "@project/common";
import { setUser, toggleLike as sliceToggleLike } from "../slices/auth";
import { setAuthToken } from "../../helpers/functions";
import store from "../index";
import initialTabs from "../../helpers/initialTabs";
import { clearMovies } from "./utilsActions";
import { setTabs } from "../slices/utils";

export const loginUser = (token: string) => {
  localStorage.setItem("jwtToken", token);
  setAuthToken(token);

  const decoded: UserPayload = jwtDecode(token);

  return setUser(decoded);
};

export const setCurrentUser = (decoded: UserPayload) => setUser(decoded);

export const refreshJwt = async () => {
  const res = await axios.post("/users/profile/updateJWT");
  const { token } = res.data;
  store.dispatch(loginUser(token));
};

export const logoutUser = () => {
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("savedMovies");
  localStorage.removeItem("tabs");

  setAuthToken(false);
  store.dispatch(clearMovies());
  store.dispatch(setTabs(initialTabs));
  return setUser(undefined);
};

export const toggleLike = (movieID: string) => sliceToggleLike(movieID);
