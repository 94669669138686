import React from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/NoteAdd";
import ListIcon from "@material-ui/icons/Note";
import EditIcon from "@material-ui/icons/Edit";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import { UploadFields, LangObject } from "@project/types";
import AddFileForm from "./AddFileForm";
import FilesList from "./FilesList";
import {
  addMovie,
  openDialog,
  closeDialog,
} from "../../../redux/actions/utilsActions";
import { downloadAxios } from "../../../helpers/functions";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 380,
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
  speedDial: {
    position: "fixed",
    bottom: "80px",
    right: theme.spacing(1),
  },
}));

type Props = {
  userID: string;
  movieID: string;
  movieName: LangObject;
  movieHandle: string;
  files: UploadFields[];
};

const Menu: React.FC<Props> = ({
  movieID,
  userID,
  movieName,
  movieHandle,
  files,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveMovie = () => {
    dispatch(
      addMovie({
        id: movieID,
        name: movieName,
        handle: movieHandle,
      }),
    );
  };

  const downloadMovie = async () => {
    try {
      const res = await axios.post("/users/movies/makeDoc", {
        arr: [movieID],
        userID,
      });

      await downloadAxios(res.data);
    } catch (error) {}
  };

  const actions = [
    {
      icon: <SaveIcon onClick={saveMovie} />,
      name: t("movie saveMovie"),
    },
    {
      icon: <DownloadIcon onClick={downloadMovie} />,
      name: t("movie downloadMovie"),
    },
    {
      icon: (
        <AddIcon
          onClick={() => {
            dispatch(
              openDialog(
                t("movie addFile"),
                <AddFileForm
                  movieID={movieID}
                  userID={userID}
                  closeDialog={() => {
                    dispatch(closeDialog());
                  }}
                />,
              ),
            );
          }}
        />
      ),
      name: t("movie addFile"),
    },
    {
      icon: (
        <ListIcon
          onClick={() => {
            dispatch(
              openDialog(
                t("movie showFiles"),
                <FilesList
                  movieID={movieID}
                  noFiles={t("no files yet")}
                  files={files}
                />,
              ),
            );
          }}
        />
      ),
      name: t("movie showFiles"),
    },
  ];

  return (
    <SpeedDial
      ariaLabel="SpeedDial openIcon example"
      className={classes.speedDial}
      icon={<SpeedDialIcon openIcon={<EditIcon />} />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={handleClose}
        />
      ))}
    </SpeedDial>
  );
};

export default Menu;
