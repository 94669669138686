import React from "react";
import { validation } from "@project/common";
import EZFormikUI, { Fields, AfterDefaultSubmit } from "ez-formikui";
import { StudioFields } from "@project/types";
import { TableActions } from "../../../common/TableAbstract";

type Props = {
  tableActions: TableActions;
  rowData?: StudioFields;
};

const StudioForm: React.FC<Props> = ({ rowData: studio, tableActions }) => {
  const { selectedLang, addNewRow, updateRow, closeDialog } = tableActions;
  const url = studio ? `/admins/studios/${studio._id}` : "/admins/studios/";
  const method = studio ? "put" : "post";

  const fields: Fields = [
    {
      fieldName: "name",
      label: "שם האולפן",
      type: "text",
      options: "text",
      initialValue: studio?.name || "",
    },
    {
      fieldName: "price",
      label: "מחיר לצפייה",
      type: "text",
      options: "number",
      initialValue: studio?.price || 0,
    },
    {
      fieldName: "currency",
      label: "מטבע",
      type: "select",
      options: [
        { value: "₪", label: "₪" },
        { value: "$", label: "$" },
      ],
      isMulti: false,
      initialValue: studio?.currency || "₪",
    },
    {
      fieldName: "viewsType",
      label: "מחיר לפי",
      type: "select",
      options: [
        { value: "views", label: "צפיות" },
        { value: "sec", label: "שניות" },
      ],
      isMulti: false,
      initialValue: studio?.viewsType || "views",
    },
    {
      fieldName: "notificationsMail",
      label: "מייל להתראות",
      type: "text",
      options: "email",
      initialValue: studio?.notificationsMail || "",
    },
    {
      fieldName: "newMovieNotification",
      label: "שליחת התראות סרט חדש?",
      type: "switch",
      initialValue: studio?.newMovieNotification || false,
    },
  ];

  const afterDefaultSubmit: AfterDefaultSubmit = (res) => {
    const { data } = res!;

    if (studio) updateRow(data);
    else addNewRow(data);

    closeDialog();
  };

  return (
    <EZFormikUI
      validationSchema={validation.admins.addStudio(selectedLang)}
      fields={fields}
      onSubmit={url}
      submitMethod={method}
      afterDefaultSubmit={afterDefaultSubmit}
    />
  );
};

export default StudioForm;
