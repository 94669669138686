import React from "react";
import { constants } from "@project/common";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Lang } from "@project/types";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
}));

type Props = {
  value: Lang;
  setValue: (lang: Lang) => void;
  style?: React.CSSProperties;
};

const SelectLang: React.FC<Props> = ({ value, setValue, style }) => {
  const { availableLanguages } = constants;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <FormControl className={classes.formControl} style={style}>
        <InputLabel htmlFor="age-simple">{t("inputs selectLang")}</InputLabel>
        <Select
          value={value}
          onChange={(e: React.ChangeEvent<any>) => setValue(e.target.value)}
          fullWidth
          inputProps={{
            name: "selectedLang",
            id: "selectedLang",
          }}
        >
          {availableLanguages.map((l, i) => (
            <MenuItem value={l.value} key={i + l.value}>
              {l.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectLang;
