import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { PageIcon } from "./PageIcon";
import { builderIcons } from "../Icons";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    marginTop: 25, // equal to header top
  },
  card: {
    overflow: "visible",
  },
  number: {
    fontSize: theme.spacing(12),
    color: "#E1EFF3",
  },
  header: {
    position: "absolute",
    top: -25,
    right: 0,
  },
  actions: {
    justifyContent: "center",
    position: "relative",
    top: 20,
    marginBottom: 20,
  },
}));

type Void = () => void;

export type Props = {
  component: React.ReactNode;
  number?: number;
  header?: { remove: Void; moveUp: Void; moveDown: Void };
  actions?: {
    movie?: Void;
    qna?: Void;
    link?: Void;
    comments?: Void;
    text?: Void;
    quote?: Void;
    image?: Void;
  };
  style?: React.CSSProperties;
};

export const PageCard: React.FC<Props> = ({
  component,
  actions,
  header,
  number,
  ...other
}) => {
  const classes = useStyles();

  return (
    <Grid container item xs={12} {...other}>
      {number && (
        <Grid item md={1} xs={2}>
          <span className={classes.number}>{number}.</span>
        </Grid>
      )}
      <Grid item xs={number ? 10 : 12} className={classes.root}>
        <Card className={classes.card}>
          {header && (
            <CardHeader
              className={classes.header}
              action={
                <Box display="flex" justifyContent="space-around">
                  <PageIcon
                    Icon={builderIcons.moveDown}
                    onClick={header.moveDown}
                    tooltip="הזז למטה"
                    variant="header"
                  />
                  <PageIcon
                    Icon={builderIcons.moveUp}
                    onClick={header.moveUp}
                    tooltip="הזז למעלה"
                    variant="header"
                  />
                  <PageIcon
                    Icon={builderIcons.remove}
                    onClick={header.remove}
                    tooltip="מחק"
                    variant="header"
                  />
                </Box>
              }
            />
          )}
          <CardContent>{component}</CardContent>
          {actions && (
            <Grid container item xs={12} className={classes.actions}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom align="center">
                  בחר כלי לשים בחלק זה
                </Typography>
              </Grid>
              <Box display="flex" justifyContent="center">
                {actions.text && (
                  <PageIcon
                    Icon={builderIcons.text}
                    onClick={actions.text}
                    variant="action"
                    tooltip="טקסט חופשי"
                  />
                )}
                {actions.quote && (
                  <PageIcon
                    Icon={builderIcons.quote}
                    onClick={actions.quote}
                    variant="action"
                    tooltip="ציטוט"
                  />
                )}
                {actions.qna && (
                  <PageIcon
                    Icon={builderIcons.qna}
                    onClick={actions.qna}
                    variant="action"
                    tooltip="שאלות"
                  />
                )}
                {actions.image && (
                  <PageIcon
                    Icon={builderIcons.image}
                    onClick={actions.image}
                    variant="action"
                    tooltip="תמונה"
                  />
                )}
                {actions.link && (
                  <PageIcon
                    Icon={builderIcons.link}
                    onClick={actions.link}
                    variant="action"
                    tooltip="מדיה חיצונית"
                  />
                )}
                {actions.movie && (
                  <PageIcon
                    Icon={builderIcons.movie}
                    onClick={actions.movie}
                    variant="action"
                    tooltip="סרטון מהמחולל"
                  />
                )}
                {actions.comments && (
                  <PageIcon
                    Icon={builderIcons.comments}
                    onClick={actions.comments}
                    variant="action"
                    tooltip="דיון"
                  />
                )}
              </Box>
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};
