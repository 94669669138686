import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  hover: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  wrap: {
    flexWrap: "wrap",
  },
}));

type Props = {
  totalItems: number;
  itemsPerPage: number;
  handleClick: (p: number) => void;
};

const Pagination: React.FC<Props> = ({
  totalItems,
  itemsPerPage,
  handleClick,
}) => {
  const classes = useStyles();
  const [pages, setPages] = useState<number[]>([]);
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    const numOfPages = Math.ceil(totalItems / itemsPerPage);
    setPages(Array.from(new Array(numOfPages), (_, i) => i));
    setActivePage(1);
  }, [itemsPerPage, totalItems]);

  return (
    <Grid container direction="row" justify="center">
      <Grid item>
        <ButtonGroup color="primary" className={classes.wrap}>
          {pages.map((p) => (
            <Button
              className={
                activePage === p + 1
                  ? `${classes.active} ${classes.hover}`
                  : classes.hover
              }
              key={p + 1}
              onClick={() => {
                if (p + 1 !== activePage) {
                  handleClick(p + 1);
                  setActivePage(p + 1);
                }
              }}
            >
              {p + 1}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

export default Pagination;
