import React from "react";
import {
  Avatar,
  AvatarProps,
  makeStyles,
  Tooltip,
  Theme,
} from "@material-ui/core";

type Variant = "header" | "action";

type VariantObj<T = string> = { [key in Variant]: T };

const backgroundColor: VariantObj = {
  header: "#fff",
  action: "#009CBB",
};

const textColor: VariantObj = {
  header: "#717171",
  action: "#fff",
};

const avatarVariants: VariantObj<AvatarProps["variant"]> = {
  header: "circle",
  action: "rounded",
};

const useStyles = makeStyles<
  Theme,
  { variant: Variant; useAnimations: boolean }
>((theme) => ({
  avatar: {
    backgroundColor: ({ variant }) => backgroundColor[variant],
    color: ({ variant }) => textColor[variant],
    boxShadow: theme.shadows[3],
    margin: "0 5px",
    cursor: "pointer",
    transition: "all ease 0.2s",
    "&:hover": {
      backgroundColor: ({ variant, useAnimations }) =>
        useAnimations && variant === "action"
          ? "#0D272D"
          : backgroundColor[variant],
      transform: ({ variant, useAnimations }) =>
        useAnimations ? "scale(1.2)" : "",
    },
  },
}));

type Props = {
  Icon: React.ElementType;
  onClick?: () => void;
  tooltip?: string;
  variant: Variant;
  useAnimations?: boolean;
};

export const PageIcon: React.FC<Props> = ({
  Icon,
  onClick,
  tooltip,
  variant,
  useAnimations = true,
}) => {
  const classes = useStyles({ variant, useAnimations });

  const avatar = (
    <Avatar
      onClick={onClick}
      variant={avatarVariants[variant]}
      className={classes.avatar}
    >
      <Icon fontSize="default" />
    </Avatar>
  );

  return <>{tooltip ? <Tooltip title={tooltip}>{avatar}</Tooltip> : avatar}</>;
};
