import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TableAbstract, { Columns } from "../../../common/TableAbstract";

const urls = (owner: string) => ({
  mount: `/admins/filterOptions/getByOwner/${owner}`,
  delete: (id: string) => `/admins/filterOptions/${id}`,
  update: (id: string) => `/admins/filterOptions/${id}`,
  add: "/admins/filterOptions/",
});

const FilterOptions: React.FC<RouteComponentProps<{ owner: string }>> = ({
  match,
}) => {
  const { owner } = match.params;
  const { t } = useTranslation();
  const columns: Columns = ({ selectedLang }) => [
    {
      title: t("admin-tags name"),
      field: `name.${selectedLang}`,
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: "",
      field: "fid",
      filtering: false,
      render: () => <span />,
      editable: "never",
      initialEditValue: owner,
    },
  ];

  return (
    <TableAbstract
      title={t("admin-tags title")}
      columns={columns}
      urls={urls(owner)}
      useLang
    />
  );
};

export default FilterOptions;
