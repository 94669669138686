import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import { Quote } from "@project/types";

type Prop = {
  quote: Quote;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px 0`,
  },
  quoteIcon: {
    fontSize: 100,
  },
  textSection: {
    color: "#8A8A8A",
  },
  credit: {
    color: theme.palette.primary.main,
    marginTop: 30,
  },
  textContainer: {
    paddingLeft: 30,
    paddingRight: 5,
  },
}));

export const QuoteType: React.FC<Prop> = ({ quote }) => {
  const classes = useStyles();

  return (
    <Paper variant="outlined" className={classes.root}>
      <Grid container>
        <Grid item xs={2}>
          <div>
            <FormatQuoteIcon className={classes.quoteIcon} />
          </div>
        </Grid>
        <Grid item xs={10} className={classes.textContainer}>
          <Typography className={classes.textSection}>{quote.text}</Typography>
          <Typography className={classes.credit}>{quote.credit}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
