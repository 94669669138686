import React from "react";
import moment from "moment";
import { functions } from "@project/common";
import { useTranslation } from "react-i18next";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import DateIcon from "@material-ui/icons/Today";
import GroupIcon from "@material-ui/icons/Group";

export default function InfoList({ user, selectedLang }) {
  const { t } = useTranslation();

  const groupName = user.group
    ? functions.findFilledLang(user.group.name, selectedLang)
    : null;
  const activeDate = user.perment
    ? t("paying monthly")
    : moment(user.activeDate).format("L");

  const secondaryText = user.perment ? "" : t("expired account msg");

  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <EmailIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={user.email} />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <PhoneIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={user.phone} />
      </ListItem>
      {groupName && (
        <ListItem>
          <ListItemIcon>
            <GroupIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={groupName} />
        </ListItem>
      )}
      <ListItem>
        <ListItemIcon>
          <DateIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={activeDate} secondary={secondaryText} />
      </ListItem>
    </List>
  );
}
