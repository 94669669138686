import {
  Box,
  LinearProgress,
  withStyles,
  Typography,
  Card,
} from "@material-ui/core";
import React from "react";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 20,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

type Props = { page: number; pagesLength: number };

export const Progressbar: React.FC<Props> = ({ page, pagesLength }) => {
  return (
    <Box display="flex" alignItems="flex-start" flexDirection="column">
      <Card square>
        <Typography
          variant="h6"
          color="textPrimary"
          style={{ margin: "5px 10px" }}
        >
          {page}/{pagesLength}
        </Typography>
      </Card>
      <Box width="100%">
        <BorderLinearProgress
          variant="determinate"
          value={(page / pagesLength) * 100}
        />
      </Box>
    </Box>
  );
};
