import indigo from "@material-ui/core/colors/indigo";
import pink from "@material-ui/core/colors/pink";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";
import purple from "@material-ui/core/colors/purple";

export const colors = [
  indigo.A200,
  pink.A200,
  green.A200,
  blue.A200,
  red.A200,
  purple.A200,
];
