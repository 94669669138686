import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import Loading from "../../../common/Loading";

type Prop = {
  page: number;
  pageData: { title: string; short: string };
  align: string;
};

export const MainStruct: React.FC<Prop> = ({ page, pageData, align }) => {
  if (pageData === undefined) return <Loading />;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" alignItems={align} justifyContent="center">
          <Typography variant="h4">{pageData.title}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {pageData.short}
      </Grid>
    </Grid>
  );
};
