import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import ExpansionPanel from "@material-ui/core/Accordion";
import ExpansionPanelSummary from "@material-ui/core/AccordionSummary";
import ExpansionPanelDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { FilterFields } from "@project/types";

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main,
    fontSize: "18px",
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

type Option = { label: string; value: any; owner: FilterFields };
type Filter = { label: string; value: any };

type Props = {
  value: any;
  setValue: (value: any) => void;
  options: Option[];
  filters: Filter[];
};

const FiltersSelectSearch: React.FC<Props> = ({
  value,
  setValue,
  options,
  filters,
}) => {
  const classes = useStyles();

  const handleChange = (e: any) => {
    const val = e.target.value;
    const index = value.indexOf(val);
    if (index === -1) {
      setValue([...value, val]);
    } else {
      const old = [...value];
      old.splice(index, 1);
      setValue(old);
    }
  };

  return (
    <Grid container spacing={2}>
      {filters.map((f, i) => (
        <Grid key={i + i + f.label} item xs={4}>
          <FormControl
            style={{ display: "block" }}
            key={i + f.label}
            component="fieldset"
          >
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>{f.label}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <FormGroup aria-label="position" onChange={handleChange} row>
                  {options
                    .filter((o) => o.owner._id === f.value)
                    .map((o) => (
                      <FormControlLabel
                        key={o.value}
                        value={o.value}
                        control={
                          <Checkbox
                            checked={value.indexOf(o.value) !== -1}
                            color="primary"
                          />
                        }
                        label={o.label}
                        labelPlacement="end"
                      />
                    ))}
                </FormGroup>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </FormControl>
        </Grid>
      ))}
    </Grid>
  );
};

export default FiltersSelectSearch;
