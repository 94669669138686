/* eslint-disable import/no-cycle */
import React from "react";
// import Actions from "../components/dashboard/user/actions/Actions";
import Home from "../components/user/Home";
import Folders from "../components/user/Folders/index";
import Actions from "../components/user/Profile/actions/Actions";
import { Builder } from "../components/user/Builder";
// import Folders from "../components/dashboard/Folders/Folders";

export type InitialTabs = {
  label: string;
  value: JSX.Element | string;
  closeable: boolean;
  type:
    | "home"
    | "folders"
    | "actions"
    | "tag"
    | "other"
    | "movie"
    | "search"
    | "builder"
    | "folder"
    | "moviesFolder";
}[];

const initialTabs: InitialTabs = [
  { label: "tabs-home", value: <Home />, closeable: false, type: "home" },
  {
    label: "tabs-folders",
    value: <Folders folderID="" isIndex />,
    closeable: false,
    type: "folders",
  },
  {
    label: "tabs-mainActivity",
    value: <Actions />,
    closeable: false,
    type: "actions",
  },
  {
    label: "new-builder",
    value: <Builder />,
    closeable: false,
    type: "builder",
  },
  // {
  //   label: "tabs-folders",
  //   value: <Folders />,
  //   closeable: false,
  //   type: "regular"
  // },
  // {
  //   label: "tabs-mainActivity",
  //   value: <Actions />,
  //   closeable: false,
  //   type: "regular"
  // }
];

export default initialTabs;
