import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, List, Button } from "@material-ui/core";
import { Lang, UploadFields } from "@project/types";
import MyCard from "../../../common/Card";
import ActionUI from "./ActionUI";
import Pagination from "../../Search/Pagination";
import { downloadAxios } from "../../../../helpers/functions";

type Props = {
  actions: any;
  selectedLang: Lang;
};

const FilesActions: React.FC<Props> = ({ actions, selectedLang }) => {
  const actionsPerPage = 10;
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  actions = actions.filter((a: any) => a.userID !== null);
  const actionsToShow = actions.slice(
    page * actionsPerPage,
    page * actionsPerPage + actionsPerPage,
  );

  const handlePage = (p: number) => setPage(p - 1);

  const noActionMsg =
    actions.length === 0 ? (
      <Typography variant="subtitle2" align="center">
        {t("no action yet")}
      </Typography>
    ) : null;

  return (
    <MyCard
      title={t("profile filesActions")}
      content={
        noActionMsg || (
          <>
            <List>
              {actionsToShow.map((a: UploadFields) => {
                const showAction = a.userID !== null;
                return (
                  <div key={a._id}>
                    {showAction && (
                      <ActionUI
                        userName="↓"
                        text={
                          <Button
                            onClick={async () => {
                              await downloadAxios({
                                _id: a._id,
                                location: a.location,
                              });
                            }}
                          >
                            {a.name}
                          </Button>
                        }
                      />
                    )}
                  </div>
                );
              })}
            </List>
            <Pagination
              totalItems={actions.length}
              itemsPerPage={actionsPerPage}
              handleClick={handlePage}
            />
          </>
        )
      }
    />
  );
};

export default FilesActions;
