import React from "react";
import EZFormikUI, { Fields, FormikSubmit } from "ez-formikui";
import { validation } from "@project/common";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { useLangSelector } from "../../../redux";
import { isProduction } from "../../../helpers/functions";

type Props = {
  closeDialog: () => void;
  userID: string;
  movieID: string;
};

const AddFileForm: React.FC<Props> = ({ closeDialog, movieID }) => {
  const lang = useLangSelector();
  const { t } = useTranslation();

  const fields: Fields = [
    {
      fieldName: "name",
      type: "text",
      options: "text",
      label: t("movie addFile-name"),
      initialValue: "",
    },
    {
      fieldName: "movieID",
      type: "text",
      options: "text",
      label: "",
      initialValue: movieID,
      props: { disabled: true, style: { display: "none" } },
    },
    {
      fieldName: "file",
      type: "other",
      label: "",
      initialValue: "",
      component: ({ value, setValue, errorMsg }) => (
        <div>
          <input
            accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
            id="raised-button-file"
            type="file"
            onChange={(e) => {
              setValue(e.target.files![0]);
            }}
          />

          <Typography color="error" variant="subtitle1" gutterBottom>
            {`*${t("allowed files")} pdf, doc, docx, ppt, pptx`}
            {errorMsg}
          </Typography>
        </div>
      ),
    },
  ];

  const onSubmit: FormikSubmit = async (values) => {
    try {
      const data = new FormData();
      data.append("file", values.file);
      data.append("name", values.name);
      data.append("movieID", values.movieID);

      await Axios.post("/users/files/upload", data);
      closeDialog();
    } catch (error) {}
  };

  return (
    <EZFormikUI
      fields={fields}
      onSubmit={onSubmit}
      useCaptcha={isProduction()}
      validationSchema={validation.users.upload(lang)}
    />
  );
};

export default AddFileForm;
