/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable no-alert */
import React, { useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import ShowMoreIcon from "@material-ui/icons/ExpandMore";
import ShowLessIcon from "@material-ui/icons/ExpandLess";
import InfoList from "./InfoList";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    marginBottom: "15px",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    fontSize: "72px",
    margin: 10,
    width: 120,
    height: 120,
  },
  card: {
    width: "100%",
    position: "relative",
    paddingTop: "15px",
  },
  cardContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  cardActions: {
    justifyContent: "center",
  },
  cardHeader: {
    padding: 0,
    paddingRight: "10px",
    color: theme.palette.primary.main,
    "& svg": {
      fontSize: "2rem",
    },
  },
}));

export default function ProfileInfo({ selectedLang, user, showCollapse }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showInfo, setShowInfo] = useState(showCollapse);
  const [del, setDel] = useState(null);

  const deleteAccount = async (user) => {
    // eslint-disable-next-line no-undef
    if (window.confirm(t("delete confirm"))) {
      try {
        await axios.post("/api/loggedin/contact", {
          name: user.name,
          email: user.email,
          text: user.phone,
          type: "בקשת מחיקת משתמש",
        });
        setDel(t("delete sent"));
      } catch (e) {}
    }
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item md={6} xs>
        <Card className={classes.card}>
          <CardHeader
            className={classes.cardHeader}
            action={
              <div onClick={(_) => setShowInfo((old) => !old)}>
                {showInfo ? <ShowLessIcon /> : <ShowMoreIcon />}
              </div>
            }
          />

          <Collapse in={showInfo}>
            <CardContent className={classes.cardContent}>
              <Avatar className={classes.avatar}>
                {user.name[0].toUpperCase()}
              </Avatar>
              <Typography variant="h4">{user.name}</Typography>
              <InfoList user={user} selectedLang={selectedLang} />
              {del || (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => deleteAccount(user)}
                >
                  {t("delete account")}
                </Button>
              )}
            </CardContent>
          </Collapse>

          <CardActions className={classes.cardActions}>
            <Button
              color="primary"
              size="small"
              component={Link}
              onClick={(_) => setShowInfo(false)}
              to="/dashboard/profile/info"
            >
              {t("profile updateInfo")}
            </Button>
            <Button
              color="primary"
              size="small"
              component={Link}
              onClick={(_) => setShowInfo(false)}
              to="/dashboard/profile/filters"
            >
              {t("profile updateFilters")}
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
