import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useTypedSelector } from "../../../redux";
import initialTabs from "../../../helpers/initialTabs";
import { TabItem } from "../../../redux/slices/utils";
import { removeTab } from "../../../redux/actions/utilsActions";
import TabPanel from "./TabPanel";
import Alert from "../Alert";
import Movie from "../Movie";
import Folders from "../Folders/index";
import MoviesFolders from "../Folders/MovieFolder";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    padding: "10px 0",
    // backgroundColor: "#eee",
  },
  customTab: {
    "& .MuiTab-wrapper": {
      display: "flex",
      flexDirection: "row!important",
      justifyContent: "space-evenly!important",
    },
  },
  tabLabel: {
    fontSize: "16px",
  },
  tabsBar: {
    top: "64px",
    [theme.breakpoints.down("xs")]: {
      top: "56px",
    },
  },
  content: {
    marginTop: "50px",
  },
}));

const MyTabs: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const tabs = useTypedSelector((state) => state.utils.tabs);
  const initialTabsLength = initialTabs.length;

  const tabToType = (tab: TabItem) => {
    switch (tab.type) {
      case "movie":
        return <Movie handle={tab.value as string} />;

      case "folder":
        return <Folders isIndex={false} folderID={tab.value} />;

      case "moviesFolder":
        return <MoviesFolders tagID="" folderID={tab.value} />;

      case "tag":
        return <MoviesFolders tagID={tab.value} folderID={""} />;

      default:
        return tab.value;
    }
  };

  useEffect(() => {
    if (tabs.length > initialTabsLength) {
      setActiveTab(tabs.length - 1);
    }
  }, [tabs, initialTabsLength]);

  const RemoveTab = (i: number) => {
    setActiveTab(0);
    dispatch(removeTab(i));
  };
  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="default" className={classes.tabsBar}>
        <Tabs
          onContextMenu={(e: any) => e.preventDefault()}
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
        >
          {tabs.map((tab, i) => (
            <Tab
              className={classes.customTab}
              key={tab.label + i}
              onMouseDown={(e) =>
                // eslint-disable-next-line no-nested-ternary
                tab.closeable
                  ? e.button === 2
                    ? RemoveTab(i)
                    : setActiveTab(i)
                  : setActiveTab(i)
              }
              label={
                <>
                  <span className={classes.tabLabel}>{t(tab.label)}</span>
                  {tab.closeable && <CloseIcon onClick={(_) => RemoveTab(i)} />}
                </>
              }
            />
          ))}
        </Tabs>
      </AppBar>
      <Alert />
      <div className={classes.content}>
        {tabs.map((tab, i) => (
          <TabPanel
            key={i + tab.label}
            value={activeTab}
            index={i}
            isHome={tab.type === "home"}
          >
            {tabToType(tab)}
          </TabPanel>
        ))}
      </div>
    </div>
  );
};

export default MyTabs;
