import React from "react";
import EZFormikUI from "ez-formikui";
import { validation } from "@project/common";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { isProduction } from "../../../../helpers/functions";
import { refreshJwt } from "../../../../redux/actions/authActions";
import EditPassword from "./EditPassword";
import { useGuaranteedUserSelector, useLangSelector } from "../../../../redux";

export default function EditInfo() {
  const user = useGuaranteedUserSelector();
  const lang = useLangSelector();
  const { t } = useTranslation();

  const afterDefaultSubmit = async () => {
    await refreshJwt();
    window.location.href = "/dashboard/profile/home";
  };

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item md={6} xs={12}>
        <EZFormikUI
          fields={[
            {
              fieldName: "email",
              label: t("register-email"),
              type: "text",
              options: "email",
              props: { disabled: true },
              initialValue: user.email,
            },
            {
              fieldName: "name",
              label: t("register-name"),
              type: "text",
              options: "text",
              initialValue: user.name,
            },
            {
              fieldName: "passwords",
              type: "other",
              label: t("click to change pass"),
              component: EditPassword as any,
              initialValue: { password: "", password2: "" },
            },
            {
              fieldName: "tfa",
              type: "switch",
              label: t("profile tfa"),
              initialValue: user.tfa.use || false,
            },
          ]}
          onSubmit="/users/profile/updateInfo"
          afterDefaultSubmit={afterDefaultSubmit}
          validationSchema={validation.users.editInfo(lang)}
          useCaptcha={isProduction()}
        />
      </Grid>
    </Grid>
  );
}
