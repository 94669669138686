import GuestHome from "../components/guest/Home";
import Login from "../components/auth/Login";
import Register from "../components/auth/Register";
import SendPwdToken from "../components/auth/passwordReset/SendPwdToken";
import ChangePassword from "../components/auth/passwordReset/ChangePassword";
import { RouteType } from "./RouteType";
import LoginPhone from "../components/auth/Login/LoginPhone";
import DynamicPages from "../components/guest/DynamicPages";
import Payment from "../components/guest/Payment";
import { Shares } from "../components/guest/Shares";
import { T5Index } from "../components/guest/T5";

const routes: RouteType[] = [
  {
    path: "/",
    component: GuestHome,
  },
  {
    path: "/login",
    component: LoginPhone,
    linkText: "navbar-login",
    restriction: "loggedOut",
  },
  {
    path: "/register",
    component: Register,
    linkText: "navbar-register",
    restriction: "loggedOut",
  },
  {
    path: "/reg.php",
    component: Register,
  },
  {
    path: "/passwordReset",
    component: SendPwdToken,
  },
  {
    path: "/loginEmail",
    component: Login,
  },
  {
    path: "/passwordReset/:token",
    component: ChangePassword,
  },
  {
    path: "/pages/:handle",
    component: DynamicPages,
  },
  {
    path: "/payment",
    component: Payment,
  },
  {
    path: "/shares/:id",
    component: Shares,
  },
  {
    path: "/lecture/:id",
    component: T5Index,
  },
];

export default routes;
