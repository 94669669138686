import React from "react";
import { Typography, Button } from "@material-ui/core";
import Axios from "axios";

export default function GroupPopup() {
  const unGroup = async () => {
    try {
      await Axios.post("/users/profile/leaveGroup");
      window.location.reload();
    } catch (error) {}
  };

  return (
    <div>
      <Typography>
        תם תוקף ההתחברות שלך. אנא התחבר למערכת דרך אתר המכללה. סיימת ללמוד ?
      </Typography>
      <Button onClick={unGroup}>לחץ כאן</Button>
    </div>
  );
}
