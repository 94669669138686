import React from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { functions } from "@project/common";
import GroupPopup from "./GroupPopup";
import { useGuaranteedUserSelector, useTypedSelector } from "../../redux";
import { openDialog } from "../../redux/actions/utilsActions";
import { refreshJwt } from "../../redux/actions/authActions";

export default function Payment() {
  const user = useGuaranteedUserSelector();
  const auth = useTypedSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isUsingFromHersog, setIsUsingFromHersog] = React.useState(false);

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const res = await axios.post("/users/profile/getgrouppop");
        if (res.data.popMessage) {
          dispatch(openDialog("הודעת מערכת !", <GroupPopup />, ""));
          setIsUsingFromHersog(true);
        }
      } catch (error) {}
    };
    fetch();
    if (functions.isPayingUser(auth.user)) window.location.href = "/";
  }, [dispatch, auth]);

  return (
    <div>
      {user.group && user.group !== "" && (
          <div style={{ textAlign: "center" }}>
            <h1>במידה והתחברתם דרך המכללה יש לבצע רענון לדף</h1>
          </div>
        )}
      <div style={{ position: "relative", paddingTop: "100%" }}>
        <iframe
          title="payment"
          src={`https://secure.cardcom.solutions/e/WT7/?&subscribers_email=${user.email}&subscribers_name=${user.name}`}
          frameBorder="0"
          allowFullScreen
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }}
        />
      </div>
    </div>
  );
}
