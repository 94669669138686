/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect, useRef } from "react";
import clsx from "classnames";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useTranslation } from "react-i18next";
import { useMediaQuery, Tooltip, Box, Badge } from "@material-ui/core";
import SavedMovies from "@material-ui/icons/CloudDownload";
import UserIcon from "@material-ui/icons/Person";
import { functions } from "@project/common";
import { logoutUser } from "../../../redux/actions/authActions";
import SelectMenu from "../SelectMenu";
import useStyles from "./style";
import NavLink from "./NavLink";
import { RouteType } from "../../../routes/RouteType";
import { useTypedSelector, useLangSelector } from "../../../redux";
import {
  downloadSaved,
  shouldHideNavbarOrFooter,
} from "../../../helpers/functions";
import { clearMovies, addTab } from "../../../redux/actions/utilsActions";
import Img from "../../../img/logo3.png";
import DynamicLinks from "./DynamicLinks";

type LinksArray = RouteType[];

type Props = {
  links: LinksArray;
  adminLinks?: LinksArray;
};

const NavbarAbstract: React.FC<Props> = ({ links, adminLinks }) => {
  const hiddenNavbar = shouldHideNavbarOrFooter();
  const history = useHistory();
  const lang = useLangSelector();
  const auth = useTypedSelector((state) => state.auth);
  const savedMovies = useTypedSelector((state) => state.utils.savedMovies);
  const dispatch = useDispatch();
  const matches = useMediaQuery("(min-width:640px)");
  const isRTL = functions.isRTL(lang);
  const ref = useRef<HTMLDivElement>();
  const [open, setOpen] = useState(false);
  const [margin, setMargin] = useState(0);
  const classes = useStyles({ margin, hiddenNavbar });
  const { t } = useTranslation();
  const { isLoggedin } = auth;
  const isAdmin = isLoggedin && functions.isAdmin(auth.user);
  const gotSaved = savedMovies.length > 0;

  const goToHome = () => {
    history.push("/");
  };

  const savedExtraLinks = gotSaved
    ? [
        { text: t("clear saved"), to: () => dispatch(clearMovies()) },
        {
          text: t("download saved"),
          to: () => downloadSaved(savedMovies, auth.user!._id),
        },
      ]
    : [{ text: t("no saved"), to: "#" }];

  const typeToState = (type?: "admin" | "loggedIn" | "loggedOut") => {
    switch (type) {
      case "admin":
        return isAdmin;
      case "loggedIn":
        return isLoggedin;
      case "loggedOut":
        return !isLoggedin;
      default:
        return true;
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const renderLinks = (linksArr: LinksArray) =>
    linksArr.map((link, i) => (
      <React.Fragment key={i}>
        {typeToState(link.restriction) && (
          <NavLink
            to={link.path}
            text={t(link.linkText!)}
            closeMenu={handleDrawerClose}
          />
        )}
      </React.Fragment>
    ));

  const logout = () => {
    dispatch(logoutUser());
    history.push("/");
  };

  useEffect(() => {
    const resizeListener = () => {
      const newMargin = ref.current?.clientHeight;
      setMargin(newMargin || 80);
    };

    resizeListener();

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(
          classes.appBar,
          open && classes.appBarShift,
          classes.tabtopcolor,
        )}
        ref={ref}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden,
            )}
          >
            <Tooltip title={t("menu tooltip") as string} placement="bottom">
              <MenuIcon />
            </Tooltip>
          </IconButton>

          {matches ? (
            <Box
              className={classes.title}
              display="flex"
              alignItems="center"
              justifyContent="space-around"
              onClick={goToHome}
            >
              <img
                src={Img}
                style={{ height: "40px", width: "40px" }}
                alt="logo"
              />
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                {t("navbar-logo")}
              </Typography>
            </Box>
          ) : (
            <div className={classes.title} onClick={goToHome}>
              <img
                src={Img}
                style={{ height: "30px", width: "30px" }}
                alt="logo"
              />
            </div>
          )}

          {/* <SelectMenu
            buttonText={selectedLang.toUpperCase()}
            links={availableLanuages.map(l => {
              return {
                name: l.label,
                func: () => {
                  switchLan(l.value);
                  refreshPage();
                }
              };
            })}
          /> */}
          {isLoggedin && (
            <>
              <SelectMenu
                buttonText={
                  <Tooltip
                    title={t("menu saved tooltip") as string}
                    placement="bottom"
                  >
                    <Badge badgeContent={savedMovies.length} color="primary">
                      <SavedMovies color="inherit" />
                    </Badge>
                  </Tooltip>
                }
                links={[
                  ...savedMovies.map((saved) => ({
                    text: functions.findFilledLang(saved.name, lang),
                    to: () => {
                      dispatch(
                        addTab({
                          label:
                            saved.name[lang] ||
                            (t("admin-table emptyValue") as string),
                          value: saved.handle,
                          closeable: true,
                          type: "movie",
                        }),
                      );
                    },
                  })),
                  ...savedExtraLinks,
                ]}
              />
              <SelectMenu
                buttonText={
                  <Tooltip
                    title={t("profile tooltip") as string}
                    placement="bottom"
                  >
                    <UserIcon color="inherit" />
                  </Tooltip>
                }
                links={[
                  {
                    text: t("navbar-profile"),
                    to: "/dashboard/profile/home",
                  },
                  {
                    text: t("shared movies"),
                    to: "/dashboard/shares",
                  },
                  {
                    text: t("builder menage"),
                    to: "/dashboard/builderList",
                  },
                  {
                    text: t("navbar-logout"),
                    to: logout,
                  },
                ]}
              />
            </>
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {t("navbar-title")}
          </Typography>

          <IconButton onClick={handleDrawerClose}>
            {isRTL ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {renderLinks(links)}
          <DynamicLinks closeMenu={handleDrawerClose} />
          {adminLinks && isAdmin && (
            <>
              <Divider />
              <ListSubheader
                className={classes.textCenter}
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/admin")}
              >
                Admin
              </ListSubheader>
              {renderLinks(
                adminLinks.sort((a, b) =>
                  t(a.linkText!).toLowerCase() < t(b.linkText!).toLowerCase()
                    ? -1
                    : 1,
                ),
              )}
            </>
          )}
        </List>
      </Drawer>
    </div>
  );
};

export default NavbarAbstract;
