import React from "react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { Question } from "@project/types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import { T5QuestionProps } from "./types";

type CheckboxItemProps = {
  value: string;
  onChange: T5QuestionProps["onChange"];
  label: string;
  setTouched: T5QuestionProps["setTouched"];
  answer: T5QuestionProps["answer"];
};

const CheckboxItem: React.FC<CheckboxItemProps> = ({
  label,
  onChange,
  setTouched,
  value,
  answer,
}) => (
  <FormControlLabel
    onBlur={() => setTouched(true)}
    label={label}
    control={
      <Checkbox
        value={value}
        onChange={(e) => {
          if (e.target.checked && !answer?.includes(value)) {
            onChange(`${value}!%${answer || ""}`);
          } else if (!e.target.checked && answer?.includes(value)) {
            const tmp = answer.replace(value, "");
            onChange(tmp);
          }
        }}
      />
    }
    labelPlacement="start"
  />
);

type CheckboxProps = T5QuestionProps & {
  answers: Question["answers"];
  label: string;
};

export const T5Checkbox: React.FC<CheckboxProps> = ({
  onChange,
  label,
  answers,
  gotError,
  setTouched,
  answer,
  errorMsg,
}) => (
  <Grid item xs={12}>
    <FormControl fullWidth margin="dense">
      <FormLabel error={gotError}>{label}</FormLabel>
      <FormGroup row>
        {answers.map((a, i) => (
          <CheckboxItem
            key={i}
            value={a}
            label={a}
            answer={answer}
            onChange={onChange}
            setTouched={setTouched}
          />
        ))}
      </FormGroup>
      {gotError && (
        <FormHelperText error variant="standard" style={{ fontSize: "16px" }}>
          {errorMsg}
        </FormHelperText>
      )}
    </FormControl>
  </Grid>
);
