import React, { useState, useEffect } from "react";
import Axios from "axios";
import EZFormikUI, { FormikSubmit } from "ez-formikui";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { validation, functions } from "@project/common";
import { Grid } from "@material-ui/core/";
import { useTypedSelector, useLangSelector } from "../../../redux";
import Register from "../Register";
import TFA from "./TFA";
import { isProduction } from "../../../helpers/functions";
import { AlignCenter, AuthLinks } from "../AuthHelpers";
// import TfaPhone from "./TfaPhone.js";

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = RouteComponentProps<{}, {}, { lastPath?: string }>;

const LoginPhone: React.FC<Props> = ({ location: { state: redirect } }) => {
  const [nextStep, setNextStep] = useState(false);
  const [register, setRegister] = useState(false);
  const [phone, setPhone] = useState<string | undefined>(undefined);
  const history = useHistory();
  const lastPath = redirect ? redirect.lastPath : null;
  const lang = useLangSelector();
  const { t } = useTranslation();
  const isLoggedin = useTypedSelector((state) => state.auth.isLoggedin);
  const isHersog = functions.isHersog(window.location.hostname);

  const onSubmit: FormikSubmit = async (values) => {
    setPhone(values.phone);

    try {
      const res = await Axios.post("/guests/auth/loginPhone", values);

      if (res.data.nextStep) {
        setNextStep(true);
      }
    } catch (error) {
      if ("phone" in (error?.response?.data || {})) {
        setRegister(true);
      }
    }
  };

  useEffect(() => {
    if (isLoggedin) {
      if (lastPath) history.push(lastPath);
      else history.push("/dashboard");
    }
  }, [isLoggedin, history, lastPath]);

  return (
    <AlignCenter>
      <Grid container>
        {register ? (
          <Register phone={phone} />
        ) : (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            {nextStep ? (
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              <TFA values={{ phone: phone! }} />
            ) : (
              <EZFormikUI
                title={isHersog ? "ברוכים הבאים! " : t("login-header")}
                paragraph={
                  isHersog
                    ? "משתמשי מכללת הרצוג - הזינו כאן את מספר הטלפון שלכם כדי להתחבר"
                    : t("login-text")
                }
                fields={[
                  {
                    fieldName: "phone",
                    type: "text",
                    options: "tel",
                    label: t("login-phoneLabel"),
                    initialValue: "",
                  },
                ]}
                onSubmit={onSubmit}
                typographyProps={{
                  paragraph: { color: "textPrimary" },
                }}
                submitProps={{ text: t("login-submit"), grid: { xs: 12 } }}
                validationSchema={validation.auth.phoneLogin(lang)}
                useCaptcha={isProduction()}
              />
            )}
            <AuthLinks
              links={[
                { text: t("dont have account?"), to: "/register" },
                { text: t("login-regular"), to: "/loginEmail" },
                { text: t("login-reset"), to: "/passwordReset" },
              ]}
            />
          </Grid>
        )}
      </Grid>
    </AlignCenter>
  );
};

export default LoginPhone;
