import React from "react";
import { OtherComponent } from "ez-formikui";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { openSnackbar } from "../../../../redux/actions/utilsActions";

const autoSaveTime = 10;

export const AutoSave: OtherComponent = ({ values }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    let time = autoSaveTime;

    interval = setInterval(() => {
      time--;

      if (time === 0) {
        dispatch(openSnackbar(t("auto save successfully"), "info"));
        localStorage.setItem("builder", JSON.stringify(values));
      }
      if (time === -1 && interval) clearInterval(interval);
    }, 1000);

    return () => {
      if (interval) clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return <></>;
};
