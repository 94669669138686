import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  primaryColor: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function ActionUI({ text, userName = undefined }) {
  const classes = useStyles();

  return (
    <>
      <ListItem alignItems="center">
        {userName && (
          <ListItemAvatar>
            <Avatar className={classes.primaryColor}>
              {userName[0].toUpperCase()}
            </Avatar>
          </ListItemAvatar>
        )}
        <ListItemText primary={text} />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
}
