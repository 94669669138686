import React from "react";
import { useTranslation } from "react-i18next";
import EZFormikUI from "ez-formikui";
import { validation } from "@project/common";
import { useLangSelector } from "../../../../redux";

export type QnaArray = { label: string }[];

type QnAFormProps = {
  setQuestions: (questions: QnaArray) => void;
  setNextStep: () => void;
};

export const QnAForm: React.FC<QnAFormProps> = ({
  setQuestions,
  setNextStep,
}) => {
  const { t } = useTranslation();
  const lang = useLangSelector();

  const onSubmit = (values: any) => {
    setQuestions(values.questions);
    setNextStep();
  };

  return (
    <EZFormikUI
      title={t("add questions")}
      paragraph={t("add questions paragraph")}
      onSubmit={onSubmit}
      typographyProps={{ title: { variant: "h5" } }}
      validationSchema={validation.users.questions(lang)}
      fields={[
        {
          fieldName: "questions",
          type: "array",
          of: [
            {
              fieldName: "label",
              label: t("question label"),
              type: "text",
              options: "text",
              initialValue: "",
            },
          ],
          label: t("questions"),
          initialValue: [],
        },
      ]}
      submitProps={{ text: t("next"), grid: { xs: 12 } }}
    />
  );
};
