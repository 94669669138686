import { Grid, makeStyles } from "@material-ui/core";
import { Movie, MovieFields, UserPayload } from "@project/types";
import React from "react";
import { useFetch } from "../../../../../hooks";
import Loading from "../../../../common/Loading";
import VideoContainer from "../../../../user/Movie/VideoContainer";

type Prop = {
  media: Movie;
  createdBy: string;
};

const useStyles = makeStyles(() => ({
  floatingArea: {
    width: "80vw",
    height: "60vh",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    textAlign: "center",
  },
  gridStyle: {
    marginTop: "3%",
    marginBottom: 200,
  },
  credits: {
    marginTop: "20px",
  },
  fullSize: {
    width: "100%",
    height: "100%",
  },
}));

export const MovieType: React.FC<Prop> = ({ media, createdBy }) => {
  const classes = useStyles();
  const { loading, data } = useFetch<any>(
    `/guests/t5/${media.movie}/${createdBy}`,
  );

  if (loading) return <Loading />;

  return (
    <Grid
      container
      justify="center"
      alignContent="center"
      className={classes.gridStyle}
    >
      <Grid item xs={12} className={classes.floatingArea}>
        <VideoContainer
          movie={data!.movie as MovieFields}
          user={data!.user as UserPayload}
          share
        />
      </Grid>
    </Grid>
  );
};
