import React from "react";
import { OtherComponent } from "ez-formikui";
import { useTranslation } from "react-i18next";
import { TextField, Grid } from "@material-ui/core";
import { PageCard } from "../PageCard";

export const Short: OtherComponent = ({ value, setValue, errorMsg }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <PageCard
        component={
          <TextField
            error={!!errorMsg}
            variant="standard"
            helperText={errorMsg}
            placeholder={t("builder short")}
            fullWidth
            rows={3}
            rowsMax={3}
            multiline
            InputLabelProps={{ focused: true }}
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
        }
        number={1}
      />
    </Grid>
  );
};
