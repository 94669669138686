import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { create } from "jss";
import { functions } from "@project/common";
import rtl from "jss-rtl";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider, StylesProvider, jssPreset } from "@material-ui/styles";
import { pdfjs } from "react-pdf";
import i18n from "./i18n";
import { useLangSelector } from "./redux";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AppProvider: React.FC = ({ children }) => {
  const lang = useLangSelector();
  const isRTL = functions.isRTL(lang);
  const theme = createMuiTheme({
    direction: isRTL ? "rtl" : "ltr",
    typography: {
      h1: {
        fontFamily: "fbduckas-blackwebfont",
      },
      h2: {
        fontFamily: "fbduckas-blackwebfont",
      },
      h3: {
        fontFamily: "fbduckas-blackwebfont",
      },
      h4: {
        fontFamily: "fbduckas-blackwebfont",
      },
      h5: {
        fontFamily: "fbduckas-blackwebfont",
      },
      h6: {
        fontFamily: "fbduckas-blackwebfont",
      },
      fontFamily: "fbduckas-regularwebfont",
    },
    palette: {
      primary: {
        main: "#133841",
      },
      secondary: {
        main: "#343a40",
      },
      text: {
        primary: "#133841",
      },
      background: {
        default: "#B0D6E1",
      },
    },
  });

  const presets = [...jssPreset().plugins];
  const rtlPresets = [...presets, rtl()];

  const jss = create({ plugins: isRTL ? rtlPresets : presets });

  document.body.style.direction = isRTL ? "rtl" : "ltr";

  return (
    <Router>
      <I18nextProvider i18n={i18n}>
        <StylesProvider jss={jss}>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </StylesProvider>
      </I18nextProvider>
    </Router>
  );
};

export default AppProvider;
