import { Container, Grid, Paper } from "@material-ui/core";
import { MovieFields, ShareFields, UserPayload } from "@project/types";
import { validation } from "@project/common";
import EZFormikUI, { Fields, FormikSubmit } from "ez-formikui";
import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import Axios from "axios";
import { useFetch } from "../../hooks";
import Loading from "../common/Loading";
import VideoContainer from "../user/Movie/VideoContainer";
import { useLangSelector } from "../../redux";

export const Shares: React.FC<RouteComponentProps<{ id: string }>> = ({
  match: { params },
}) => {
  const { id } = params;
  const { loading, data: share } = useFetch<ShareFields>(
    `/guests/shares/${id}`,
  );
  const { t } = useTranslation();
  const lang = useLangSelector();
  const [showQuestions, setShowQuestions] = React.useState(false);

  const questionsToFormik = () =>
    share!.questions.map((q): Fields[0] => ({
      fieldName: q._id,
      type: "textarea",
      label: q.label,
      rows: 3,
      initialValue: "",
    }));

  const onSubmit: FormikSubmit = async (values) => {
    const { filledBy } = values;
    const answers = Object.keys(values)
      .filter((k) => k !== "filledBy")
      .map((k) => ({ _id: k, answer: values[k] }));

    try {
      await Axios.post(`/guests/shares/${share!._id}`, { filledBy, answers });
      setShowQuestions(false);
    } catch (error) {}
  };

  React.useEffect(() => {
    setShowQuestions(share !== null && share.questions.length > 0);
  }, [share]);

  if (loading) return <Loading />;

  return (
    <Container style={{ margin: "10px auto" }}>
      <Grid container justify="center">
        <Grid item xs={12}>
          <VideoContainer
            movie={share!.movie as MovieFields}
            user={share!.createdBy as UserPayload}
            share
          />
        </Grid>
        {showQuestions && (
          <Grid item xs={12}>
            <Paper style={{ padding: 10 }}>
              <EZFormikUI
                onSubmit={onSubmit}
                fields={[
                  {
                    fieldName: "filledBy",
                    type: "text",
                    options: "text",
                    label: t("filledBy"),
                    initialValue: "",
                  },
                  ...questionsToFormik(),
                ]}
                validationSchema={validation.guests.shareAnswer(
                  lang,
                  share!.questions.map((q) => q._id),
                )}
              />
            </Paper>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
