import React from "react";
import { BuilderFields } from "@project/types";
import {
  Card,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Button,
  Box,
} from "@material-ui/core";
import ShareIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useGuaranteedUserSelector } from "../../../../redux";
import { openSnackbar } from "../../../../redux/actions/utilsActions";
import { isProduction } from "../../../../helpers/functions";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
  },
  mb: {
    marginBottom: theme.spacing(2),
  },
  shareButton: {
    margin: "0 5px",
  },
}));

type Props = {
  builder: BuilderFields;
};

export const FinishPage: React.FC<Props> = ({ builder }) => {
  const classes = useStyles();
  const user = useGuaranteedUserSelector();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const shareLink = `${
    isProduction() ? "edu-movie.com" : "localhost:3000"
  }/lecture/${builder._id}`;

  const copyLink = () => {
    const el = document.createElement("textarea");
    el.value = shareLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    dispatch(openSnackbar(t("copied successfully"), "success"));
  };

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Typography variant="h3" align="center">
          {t("builder by", { name: user.name })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" align="center">
          {builder.title}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} className={classes.mb}>
        <Card className={classes.card}>
          <Grid container item xs={12} justify="center">
            <Grid item xs={12} className={classes.mb}>
              <TextField
                value={shareLink}
                disabled
                fullWidth
                inputProps={{ style: { textAlign: "center" } }}
              />
            </Grid>
            <Button
              variant="contained"
              color="primary"
              onClick={copyLink}
              className={classes.mb}
            >
              {t("copy link")}
            </Button>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Button
                  component="a"
                  href={`https://twitter.com/intent/tweet?text=${shareLink}`}
                  target="_blank"
                  variant="outlined"
                  className={classes.shareButton}
                >
                  <TwitterIcon />
                </Button>
                <Button
                  component="a"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`}
                  target="_blank"
                  variant="outlined"
                  className={classes.shareButton}
                >
                  <FacebookIcon />
                </Button>
                <Button
                  component="a"
                  href={`https://api.whatsapp.com/send?text=${shareLink}`}
                  target="_blank"
                  variant="outlined"
                  className={classes.shareButton}
                >
                  <ShareIcon />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Button color="primary" component="a" href="/">
            {t("go back home")}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
