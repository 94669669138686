import React from "react";
import { Button, Link, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TableAbstract, { Columns, Urls } from "../../../common/TableAbstract";

const urls: Urls = {
  mount: "/users/builders/",
  delete: (id) => `/users/builders/${id}`,
};

export const MenageBuilder = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const columns: Columns = ({ selectedLang: lang }) => [
    {
      title: t("form name"),
      field: "title",
      editable: "never",
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      render: (rowData) => (
        <Button
          variant="outlined"
          target="_blank"
          href={`/lecture/${rowData._id}`}
        >
          {t("builder menage link")}
        </Button>
      ),
    },
    {
      render: (rowData) => (
        <Button
          variant="outlined"
          target="_blank"
          href={`/editBuilder/${rowData._id}`}
        >
          {t("builder edit link")}
        </Button>
      ),
    },
  ];

  return (
    <TableAbstract
      title={t("builder menage")}
      columns={columns}
      urls={urls}
      tableOptions={{ exportButton: false }}
    />
  );
};
