import React, { useEffect, useState } from "react";
import Axios from "axios";
import MaterialTable, { Column, Options } from "material-table";
import { useDispatch } from "react-redux";
import { functions } from "@project/common";
import { Lang } from "@project/types";
import {
  openSnackbar,
  openDialog,
  closeDialog,
} from "../../../redux/actions/utilsActions";
import text from "../../../helpers/text";
import hebrewBody from "./hebrew";
import { useLangSelector } from "../../../redux";
import SelectLang from "../SelectLang";
import { useFetch } from "../../../hooks";
import Loading from "../Loading";

type EditFormProps = {
  url: string;
  Form: React.ElementType;
  tableActions: TableActions;
};

export const EditFormWrapper: React.FC<EditFormProps> = ({
  url,
  Form,
  tableActions,
}) => {
  const { loading, data } = useFetch(url);

  if (loading) return <Loading />;

  return <Form rowData={data} tableActions={tableActions} />;
};

export type Urls = {
  mount: string;
  delete?: (id: string) => string;
  update?: (id: string) => string;
  getByID?: (id: string) => string;
  add?: string;
  /**
   * other urls will be ignored by this Component
   */
  [key: string]: any;
};

export type TableActions = {
  setTableData: React.Dispatch<
    React.SetStateAction<
      {
        [key: string]: any;
      }[]
    >
  >;
  addNewRow: (newItem: any) => void;
  updateRow: (newData: { _id: string; [key: string]: any }) => void;
  closeDialog: () => void;
  openDialog: (Form: React.ElementType, rowData?: any) => void;
  selectedLang: Lang;
};

export type Columns = (
  tableActions: TableActions,
) => Column<{ [key: string]: any }>[];

type Props = {
  title: string;
  urls: Urls;
  columns: Columns;
  AddForm?: React.ElementType;
  EditForm?: React.ElementType;
  addFormFullWidth?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  tableOptions?: Options<{}>;
  useLang?: boolean;
  /**
   * if true when updating component it will fetch from server (need to provide getByID url)
   */
  useFreshData?: boolean;
};

// TODO fix console errors (errors comes from Material-table package, need to wait for fix)
const TableAbstract: React.FC<Props> = ({
  title,
  urls,
  columns,
  AddForm,
  EditForm,
  addFormFullWidth = false,
  tableOptions = {},
  useLang = false,
  useFreshData = false,
}) => {
  const [tableData, setTableData] = useState<{ [key: string]: any }[]>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const lang = useLangSelector();
  const isRTL = functions.isRTL(lang);
  const [selectedLang, setSelectedLang] = useState(lang);

  const addNewRow: TableActions["addNewRow"] = (newItem) => {
    setTableData((old) => [newItem, ...old]);
  };

  const updateRow: TableActions["updateRow"] = (newData) => {
    const d = [...tableData];
    const index = tableData.findIndex((a) => a._id === newData._id);
    if (index > -1) {
      d[index] = newData;
      setTableData(d);
    }
  };

  const closeTableDialog: TableActions["closeDialog"] = () => {
    dispatch(closeDialog());
  };

  const tableActions: TableActions = {
    setTableData,
    addNewRow,
    updateRow,
    closeDialog: closeTableDialog,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    openDialog: formDialog,
    selectedLang,
  };

  function formDialog(Form: React.ElementType, rowData?: any) {
    dispatch(
      openDialog(
        title,
        <Form tableActions={tableActions} rowData={rowData} />,
        "",
        addFormFullWidth,
      ),
    );
  }

  const editDialog = (Form: React.ElementType, id: string) => {
    dispatch(
      openDialog(
        title,
        urls.getByID ? (
          <EditFormWrapper
            tableActions={tableActions}
            url={urls.getByID(id)}
            Form={Form}
          />
        ) : (
          <p>Need to provide getByID url</p>
        ),
        "",
        addFormFullWidth,
      ),
    );
  };

  /**
   * if got key with languages then it flattens it
   */
  const objConvertor = (obj: {
    [key: string]: any;
  }): { [key: string]: any } => {
    const res = { ...obj };

    Object.keys(res).forEach((k) => {
      const val = res[k];

      if (val && typeof val === "object") {
        res[k] = val[selectedLang];
      }
    });

    return res;
  };

  useEffect(() => {
    const getData = async () => {
      if (urls.mount) {
        setLoading(true);
        const res = await Axios(urls.mount);
        setTableData(res.data);
        setLoading(false);
      }
    };
    getData();
  }, [urls.mount]);

  return (
    <>
      {useLang && (
        <SelectLang
          value={selectedLang}
          setValue={setSelectedLang}
          style={{ margin: "10px 0" }}
        />
      )}
      <MaterialTable
        title={title}
        data={tableData}
        localization={isRTL ? hebrewBody : undefined}
        isLoading={loading}
        options={{
          selection: false,
          maxBodyHeight: "100vh",
          filtering: true,
          exportButton: true,
          sorting: true,
          columnsButton: true,
          pageSize: 10,
          pageSizeOptions: [10, 100, 1000],
          padding: "dense",
          ...tableOptions,
        }}
        columns={columns(tableActions)}
        editable={{
          onRowUpdate: urls.update
            ? (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const data = useLang ? objConvertor(newData) : newData;

                  Axios.put(urls.update!(data._id), {
                    ...data,
                    selectedLang,
                  })
                    .then(() => {
                      const d = [...tableData];
                      const index = tableData.indexOf(oldData || {});
                      if (index > -1) {
                        d[index] = newData;
                        setTableData(d);
                      }

                      resolve();
                    })
                    .catch((err) => {
                      const errObj = err.response.data;
                      const errors = (
                        <div>
                          {Object.keys(errObj).map((e, i) => (
                            <p key={i}>{`${e} : ${errObj[e]}\n`}</p>
                          ))}
                        </div>
                      );

                      dispatch(openSnackbar(errors, "error"));
                      reject();
                    });
                })
            : undefined,
          onRowDelete: urls.delete
            ? (oldData) =>
                new Promise((resolve, reject) => {
                  Axios.delete(urls.delete!(oldData._id))
                    .then(() => {
                      const d = [...tableData];
                      const index = d.indexOf(oldData);
                      d.splice(index, 1);
                      setTableData(d);
                      resolve();
                    })
                    .catch(() => {
                      dispatch(openSnackbar(text.serverError, "error"));
                      reject();
                    });
                })
            : undefined,
          onRowAdd: urls.add
            ? (newData) =>
                new Promise((resolve, reject) => {
                  const data = useLang ? objConvertor(newData) : newData;

                  Axios.post(urls.add!, { ...data, selectedLang })
                    .then((res) => {
                      setTableData((old) => [...old, res.data]);
                      resolve();
                    })
                    .catch((err) => {
                      const errObj = err.response.data;
                      const errors = (
                        <div>
                          {Object.keys(errObj).map((e, i) => (
                            <p key={i}>{`${e} : ${errObj[e]}\n`}</p>
                          ))}
                        </div>
                      );

                      dispatch(openSnackbar(errors, "error"));
                      reject();
                    });
                })
            : undefined,
        }}
        actions={[
          ...(AddForm
            ? [
                {
                  icon: "add",
                  tooltip: text.tableAdd,
                  isFreeAction: true,
                  onClick: () => formDialog(AddForm),
                },
              ]
            : []),
          ...(EditForm
            ? [
                {
                  icon: "edit",
                  tooltip: "ערוך",
                  onClick: (e: any, rowData: any) =>
                    useFreshData
                      ? editDialog(EditForm, rowData._id)
                      : formDialog(EditForm, rowData),
                },
              ]
            : []),
        ]}
      />
    </>
  );
};

export default TableAbstract;
export * from "./helpers";
