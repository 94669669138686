import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { functions } from "@project/common";
import { Typography, List } from "@material-ui/core";
import moment from "moment";
import MyCard from "../../../common/Card";
import ActionUI from "./ActionUI";
import Pagination from "../../Search/Pagination";
import { Lang } from "@project/types";

type Props = {
  actions: any;
  selectedLang: Lang;
  userName: string;
};

const UserActions: React.FC<Props> = ({ actions, selectedLang, userName }) => {
  const actionsPerPage = 10;
  const { t } = useTranslation();
  const [page, setPage] = useState(0);

  const actionsToShow = actions.slice(
    page * actionsPerPage,
    page * actionsPerPage + actionsPerPage,
  );

  const handlePage = (p: any) => setPage(p - 1);

  const noActionMsg =
    actions.length === 0 ? (
      <Typography variant="subtitle2" align="center">
        {t("no action yet")}
      </Typography>
    ) : null;

  return (
    <MyCard
      title={t("profile userActions")}
      content={
        noActionMsg || (
          <>
            <List>
              {actionsToShow.map((a: any) => (
                <div key={a._id}>
                  {a.movieID && (
                    <ActionUI
                      userName={userName}
                      text={
                        <>
                          <Typography>
                            {t(
                              `profile action ${functions.actionIDToStr(
                                a.actionID,
                              )}`,
                            )}
                            <Link to={`/dashboard/movie/${a.movieID.name2}`}>
                              {functions.findFilledLang(
                                a.movieID.name,
                                selectedLang,
                              )}
                            </Link>
                          </Typography>{" "}
                          <Typography variant="caption">
                            {moment(a.createdAt).format("HH:mm DD/MM/YY")}
                          </Typography>
                        </>
                      }
                    />
                  )}
                </div>
              ))}
            </List>
            <Pagination
              totalItems={actions.length}
              itemsPerPage={actionsPerPage}
              handleClick={handlePage}
            />
          </>
        )
      }
    />
  );
};

export default UserActions;
