import React from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import pink from "@material-ui/core/colors/pink";
import red from "@material-ui/core/colors/red";
import indigo from "@material-ui/core/colors/indigo";
import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";
import purple from "@material-ui/core/colors/purple";
import { TagFields, Lang } from "@project/types";
import { addTab } from "../../../redux/actions/utilsActions";

const colors = [
  indigo.A200,
  pink.A200,
  green.A200,
  blue.A200,
  red.A200,
  purple.A200,
];

const useStyles = makeStyles({
  tags: {
    position: "absolute",
    bottom: "100px",
    display: "flex",
    flexWrap: "wrap",
    zIndex: 30,
  },
});

type Props = {
  tags: TagFields[];
  selectedLang: Lang;
};

const Tags: React.FC<Props> = ({ tags, selectedLang }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClick = (t: TagFields) => {
    dispatch(
      addTab({
        label: t.name[selectedLang] || "",
        value: t._id,
        type: "tag",
        closeable: true,
      }),
    );
  };

  return (
    <div className={classes.tags}>
      {tags.map((t, i) =>
        t ? (
          <Chip
            key={t._id}
            style={{
              margin: "2px",
              color: "white",
              backgroundColor: colors[i % colors.length],
            }}
            size="small"
            label={t.name[selectedLang]}
            clickable
            onClick={() => handleClick(t)}
          />
        ) : null,
      )}
    </div>
  );
};

export default Tags;
