import React from "react";
import { OtherComponent } from "ez-formikui";
import { Grid, TextField } from "@material-ui/core";

export const ImageField: OtherComponent = ({ setValue, value, errorMsg }) => (
  <Grid container justify="space-between">
    <Grid item md={6} xs={12}>
      <TextField
        placeholder="קישור לתמונה"
        error={!!errorMsg}
        helperText={errorMsg}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        fullWidth
      />
    </Grid>
    <Grid
      item
      md={6}
      xs={12}
      style={{ display: "flex", justifyContent: "center" }}
    >
      {!!value && <img src={value} alt="" height={150} width={150} />}
    </Grid>
  </Grid>
);
