import React, { useEffect, useState } from "react";
import Axios from "axios";
import EZFormikUI from "ez-formikui";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { validation } from "@project/common";
import { Grid, Typography, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { isProduction } from "../../../helpers/functions";
import Loading from "../../common/Loading";
import { useTypedSelector, useLangSelector } from "../../../redux";
import { FieldsWithTranslation } from "../../../types";
import { AlignCenter } from "../AuthHelpers";

const fields: FieldsWithTranslation = (t) => [
  {
    fieldName: "password",
    label: t("reset-password password"),
    type: "text",
    options: "password",
    initialValue: "",
  },
  {
    fieldName: "password2",
    label: t("reset-password password2"),
    type: "text",
    options: "password",
    initialValue: "",
  },
];

const ChangePassword: React.FC<RouteComponentProps<{ token: string }>> = ({
  match: { params },
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isLoggedin = useTypedSelector((state) => state.auth.isLoggedin);
  const [validToken, setValidToken] = useState(false);
  const [userInfo, setUserInfo] = useState<{ name?: string; email?: string }>(
    {},
  );
  const [loading, setLoading] = useState(false);
  const lang = useLangSelector();
  const { token } = params;

  const afterDefaultSubmit = () => {
    history.push("/login");
  };

  if (isLoggedin) {
    history.push("/dashboard");
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const res = await Axios.post("/guests/auth/validateResetToken", {
          token,
        });
        setUserInfo({ name: res.data.name, email: res.data.email });
        setValidToken(true);
      } catch (error) {}
      setLoading(false);
    };

    fetch();
  }, [token]);

  if (loading) {
    return <Loading />;
  }

  return (
    <AlignCenter>
      <Grid container justify="center" alignItems="center" direction="column">
        <Grid item md={6} xs={12}>
          <Typography align="center" gutterBottom variant="h3">
            {t("reset-password title")}
          </Typography>
          {validToken && (
            <>
              <Typography align="center" gutterBottom variant="h5" paragraph>
                {t("reset-password for")}
              </Typography>
              <Typography align="center" variant="subtitle2" paragraph>
                {userInfo.name}
              </Typography>
              <Typography align="center" variant="subtitle2" paragraph>
                {userInfo.email}
              </Typography>
            </>
          )}

          {validToken ? (
            <EZFormikUI
              fields={fields(t)}
              onSubmit={`/guests/auth/passwordReset/${token}`}
              afterDefaultSubmit={afterDefaultSubmit}
              validationSchema={validation.auth.changePassword(lang)}
              submitProps={{
                text: t("reset-password submit"),
                grid: { xs: 12 },
              }}
              useCaptcha={isProduction()}
            />
          ) : (
            <Box display="flex" flexDirection="column">
              <Typography align="center" variant="h4">
                {t("error")}
              </Typography>
              <Typography
                align="center"
                gutterBottom
                variant="subtitle1"
                color="error"
              >
                {t("reset-password invalid token")}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </AlignCenter>
  );
};

export default ChangePassword;
