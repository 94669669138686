import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BuilderFields } from "@project/types";

export type State = Pick<BuilderFields, "pages" | "short" | "title"> & {
  tag: string;
};

const initialState: State = {
  title: "",
  tag: "",
  short: "",
  pages: [],
};

const slice = createSlice({
  name: "builder",
  initialState,
  reducers: {
    setField: (
      state,
      {
        payload: { field, value },
      }: PayloadAction<{ field: keyof State; value: any }>,
    ) => {
      state[field] = value;
    },
  },
});

export const { setField } = slice.actions;

export default slice;
