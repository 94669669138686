import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "reaptcha";
import axios from "axios";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Collapse from "@material-ui/core/Collapse";
import { UserFields } from "@project/types";

type Props = {
  classes: any;
  user: UserFields;
  updateComments: (newComment: any) => void;
  movieID: string;
};

const AddComment: React.FC<Props> = ({
  classes,
  user,
  updateComments,
  movieID,
}) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState("");
  const [error, setError] = useState(false);
  const [captcha, setCaptcha] = React.useState(false);
  const [showActions, setShowhowActions] = useState(false);
  const [cap, setCap] = useState<ReCAPTCHA | null>(null);

  const resetCaptcha = () => {
    cap?.reset();
    setCaptcha(false);
  };

  const toggleShowActions = () => setShowhowActions(true);

  const cancelComment = () => {
    setShowhowActions(false);
    setComment("");
    resetCaptcha();
  };

  const handleChange = (e: any) => {
    if (error) {
      setError(false);
    }
    setComment(e.target.value);
  };

  const onSubmit = async (newComment: string) => {
    try {
      if (comment === "") {
        setError(true);
        return;
      }
      if (captcha) {
        const res = await axios.post("/users/movies/comment", {
          txt: newComment,
          userID: user._id,
          movieID,
        });
        cancelComment();
        updateComments((old: any) => [res.data, ...old]);
      }
    } catch (e) {}
  };

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar className={classes.primaryColor}>
          {user.name[0].toUpperCase()}
        </Avatar>
      </ListItemAvatar>
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              placeholder={`${t("comment as")} ${user.name}`}
              multiline
              rowsMax={3}
              fullWidth
              value={comment}
              onChange={handleChange}
              error={error}
              onFocus={toggleShowActions}
              helperText={error ? t("inputs emptyError") : ""}
            />
          </Grid>

          <Grid item xs={12}>
            <Collapse in={showActions}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-around"
                flexWrap="wrap"
              >
                <Box display="flex" justifyContent="center">
                  <ReCAPTCHA
                    ref={(r) => setCap(r)}
                    sitekey={process.env.REACT_APP_CAPTCHA_KEY || ""}
                    onVerify={() => setCaptcha(true)}
                  />
                </Box>

                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="default"
                    style={{ margin: "0 10px" }}
                    onClick={cancelComment}
                  >
                    {t("inputs cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onSubmit(comment)}
                  >
                    {t("inputs submit")}
                  </Button>
                </Box>
              </Box>
            </Collapse>
          </Grid>
        </Grid>
      </Container>
    </ListItem>
  );
};

export default AddComment;
