import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { functions } from "@project/common";
import { Container, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import { FolderFields } from "@project/types";
import { useLangSelector } from "../../../redux";
import Button from "./Button";
import { addTab } from "../../../redux/actions/utilsActions";
import { colors } from "../../../helpers/colors";
import FolderButton from "./FolderButton";

const useStyles = makeStyles((theme) => ({
  filterGrid: {
    display: "flex",
    justifyContent: "space-between",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  mobileNoPadding: {
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 1,
      paddingRight: 1,
    },
  },
}));

type Props = {
  isIndex: boolean;
  folderID: string | JSX.Element;
};

export const Folder: React.FC<Props> = ({ isIndex, folderID }) => {
  const selectedLang = useLangSelector();
  const classes = useStyles({ isRTL: functions.isRTL(selectedLang) });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [folders, setFolders] = useState<FolderFields[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const folderList = await axios("/users/folders/");
      if (isIndex) {
        folderList.data = folderList.data.filter(
          (folderFilter: any) => folderFilter.isFather === true,
        );
      } else {
        folderList.data = folderList.data.filter(
          (folderFilter: any) => folderFilter._id === folderID,
        );
        folderList.data = folderList.data[0].folders;
      }
      const foldersData = folderList.data
        .filter((folder: any) => functions.gotLang(folder.name, selectedLang))
        .sort((a: any, b: any) =>
          a.name[selectedLang].toLowerCase() <
          b.name[selectedLang].toLowerCase()
            ? -1
            : 1,
        );

      setFolders(foldersData);
    };
    fetch();
    // eslint-disable-next-line
  }, []);

  return (
    <Container maxWidth="xl" className={classes.mobileNoPadding}>
      <Grid container spacing={1}>
        {folders.map((folder, i) => {
          const isNestedFolder = folder.folders.length > 0;
          return (
            <Grid key={folder._id + 2} item lg={3} md={6} xs={12}>
              <FolderButton
                extendData={false}
                title={folder.name[selectedLang]}
                background={{ data: colors[i % colors.length], type: "color" }}
                url={() =>
                  dispatch(
                    addTab({
                      label: folder.name[selectedLang]!,
                      value: folder._id,
                      type: !isNestedFolder ? "moviesFolder" : "folder",
                      closeable: true,
                    }),
                  )
                }
              />
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default Folder;
