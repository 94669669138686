import Filters from "./filters.json";
import Cities from "./cities.json";
import Discounts from "./discounts.json";
import Movies from "./movies.json";
import Groups from "./groups.json";
import Tags from "./tags.json";
import Folders from "./folders.json";
import Users from "./users.json";
import Linked from "./linked.json";
import Pages from "./pages.json";
import General from "./general.json";

export default {
  ...Filters,
  ...Cities,
  ...Discounts,
  ...Movies,
  ...Groups,
  ...Tags,
  ...Folders,
  ...Users,
  ...Linked,
  ...Pages,
  ...General,
};
