import React from "react";
import Axios from "axios";
import EZFormikUI, { FormikSubmit, Fields } from "ez-formikui";
import { Grid } from "@material-ui/core";
import { MovieFields, FolderFields } from "@project/types";
import { validation, functions } from "@project/common";
import { useTranslation } from "react-i18next";
import {
  TableActions,
  MySelectWrapper,
  initialValueToOptions,
} from "../../../common/TableAbstract";
import { useFetch } from "../../../../hooks";
import Loading from "../../../common/Loading";

const { convertToOptions } = functions;

type Props = {
  tableActions: TableActions;
  rowData?: FolderFields;
};

const FolderForm: React.FC<Props> = ({ tableActions, rowData: folder }) => {
  const { t } = useTranslation();
  const { selectedLang: lang } = tableActions;
  const { data: folders, loading: l3 } = useFetch<FolderFields[]>(
    "/admins/folders/",
  );

  const loading = l3;
  if (loading) return <Loading />;

  const url = folder ? `/admins/folders/${folder._id}` : "/admins/folders/";

  const onSubmit: FormikSubmit = async (values) => {
    const data = { ...values };
    data.folders = data.folders?.map((f: any) => f.value) || [];

    const Method = folder ? Axios.put : Axios.post;
    const postAction = folder ? tableActions.updateRow : tableActions.addNewRow;

    const res = await Method(url, data);
    postAction(res.data);
    tableActions.closeDialog();
  };

  const fields: Fields = [
    {
      fieldName: "name",
      type: "text",
      options: "text",
      label: t("add-movie name"),
      initialValue: folder?.name[lang] || "",
    },
    {
      fieldName: "isFather",
      type: "switch",
      label: t("add-movie name"),
      initialValue: folder?.isFather || false,
    },
    {
      fieldName: "folders",
      type: "other",
      label: "",
      component: MySelectWrapper({
        label: t("movie folders-label"),
        options: convertToOptions(folders!, "_id", "name", lang),
        other: { isMulti: true },
      }),
      initialValue: initialValueToOptions(
        folder?.folders as FolderFields[],
        "_id",
        "name",
        lang,
      ),
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <EZFormikUI
          fields={fields}
          validationSchema={validation.admins.addFolder(lang)}
          onSubmit={onSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default FolderForm;
