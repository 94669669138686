/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { functions } from "@project/common";
import {
  Container,
  Button,
  Typography,
  Divider,
  Collapse,
  ButtonGroup,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import { useGuaranteedUserSelector, useLangSelector } from "../../../redux";
import FiltersSelect from "../../common/FiltersSelectSearch";
import MovieCard from "../../common/HighlightMovies/MovieCard";
import Pagination from "./Pagination";
import Loading from "../../common/Loading";
import SearchBar from "./SearchBar";
import LinkedSearch from "./LinkedSearch";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: "0 auto",
    marginBottom: "30px",
  },
  marginY: {
    margin: "15px 0",
  },
  btn: {
    display: "flex",
    justifyContent: "center",
  },
}));

type Props = {
  initialSearch?: any;
  isOpen?: boolean;
};

const Search: React.FC<Props> = ({ initialSearch = null, isOpen = false }) => {
  const selectedLang = useLangSelector();
  const user = useGuaranteedUserSelector();
  const classes = useStyles();
  const { t } = useTranslation();
  const [search, setSearch] = useState([]);
  const [loading, setLoading] = useState(true);
  const [advancedSearch, setAdvancedSearch] = useState(isOpen);
  const [name, setName] = useState(initialSearch?.text || "");
  const [flag, setFlag] = useState(false);
  const [queryCount, setQueryCount] = useState(0);
  const [filters, setFilters] = useState([]);
  const [filtersOpt, setFiltersOpt] = useState([]);
  const [filterSearch, setFilterSearch] = useState(initialSearch?.arr || []);
  const [tags, setTags] = useState([]);
  const moviesPerPage = 40;

  const SearchFunc = async () => {
    setLoading(true);
    const res = await axios.post(
      `/users/movies/search?page=0&length=${moviesPerPage}`,
      {
        text: name,
        filters: [...filterSearch, ...user.filters],
      },
    );

    setLoading(false);
    setFlag(true);
    setSearch(res.data.data);
    setQueryCount(res.data.count);
  };

  useEffect(() => {
    const fetch = async () => {
      const res = await axios("/users/filters/search");
      const resOpt = await axios("/users/filters/filterOptions");
      setFilters(
        res.data.map((f: any) => ({
          label: functions.findFilledLang(f.name, selectedLang),
          value: f._id,
        })),
      );
      setFiltersOpt(
        resOpt.data.map((f: any) => ({
          label: functions.findFilledLang(f.name, selectedLang),
          value: f._id,
          owner: f.owner,
        })),
      );
      setLoading(false);
    };
    fetch();
  }, [selectedLang]);

  useEffect(() => {
    if (initialSearch) {
      SearchFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSearch]);

  const handleNext = async (page: number) => {
    const res = await axios.post(
      `/users/movies/search?page=${page - 1}&length=${moviesPerPage}`,
      {
        text: name,
        filters: [...filterSearch, ...user.filters],
      },
    );
    setSearch(res.data.data);
    const anchor = document.querySelector("#movies-start");
    if (anchor) {
      anchor.scrollIntoView({ behavior: "auto", block: "start" });
    }
  };

  if (loading) return <Loading />;

  return (
    <Container maxWidth="xl">
      <Paper className={classes.paper}>
        <Container maxWidth="md">
          <Grid container spacing={1} justify="center">
            <Grid item md={8} xs={12}>
              <SearchBar
                name={name}
                initialSearch={initialSearch}
                setName={setName}
                tags={tags}
                setTags={setTags}
              />
            </Grid>
            <Grid item md={4} xs={12} className={classes.btn}>
              <ButtonGroup>
                {!advancedSearch && (
                  <Button
                    color="primary"
                    onClick={() => setAdvancedSearch(true)}
                  >
                    {t("go to advanced search")}
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={SearchFunc}
                >
                  {t("search submit")}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Container>
        <Grid container spacing={1} justify="center">
          <Collapse in={advancedSearch}>
            <Typography
              variant="h4"
              color="primary"
              align="center"
              className={classes.marginY}
            >
              {t("search filtersTitle")}
            </Typography>

            <FiltersSelect
              value={filterSearch}
              filters={filters}
              options={filtersOpt}
              setValue={setFilterSearch}
            />
            <Divider />

            <Grid item xs={12} className={`${classes.btn} ${classes.marginY}`}>
              <Button variant="contained" color="primary" onClick={SearchFunc}>
                {t("search submit")}
              </Button>
            </Grid>
          </Collapse>
        </Grid>
      </Paper>

      <Grid container spacing={3} id="movies-start">
        {search.map((s: any) => (
          <Grid key={s._id} item lg={3} md={6} xs={12}>
            <MovieCard movie={s} selectedLang={selectedLang} />
          </Grid>
        ))}
        {queryCount > 0 && (
          <Pagination
            totalItems={queryCount}
            itemsPerPage={moviesPerPage}
            handleClick={handleNext}
          />
        )}
      </Grid>
      <br />
      <br />
      <LinkedSearch searchText={name} flag={flag} setFlag={setFlag} />
    </Container>
  );
};

export default Search;
