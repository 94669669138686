import React from "react";
import {
  MovieFields,
  LinkedMoviesFields,
  CommentFields,
  UploadFields,
  FilterOptionFields,
} from "@project/types";
import { Grid, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Menu from "./Menu";
import { useGuaranteedUserSelector, useLangSelector } from "../../../redux";
import VideoContainer from "./VideoContainer";
import MyCard from "../../common/Card";
import Comments from "./Comments";
import LinkedMovies from "../../common/LinkedMovies";
import FiltersContainer from "./FiltersContainer";
import Collapse from "./Collapse";

type Props = {
  movie: MovieFields;
  linkedMovie: LinkedMoviesFields;
  comments: CommentFields[];
  files: UploadFields[];
};

const MovieContainer: React.FC<Props> = ({
  movie,
  comments,
  files,
  linkedMovie,
}) => {
  const user = useGuaranteedUserSelector();
  const matches = useMediaQuery("(min-width:1280px)");
  const selectedLang = useLangSelector();
  const { t } = useTranslation();

  return (
    <>
      <Menu
        files={files}
        movieHandle={movie.name2}
        movieID={movie._id}
        movieName={movie.name}
        userID={user._id}
      />

      <Grid container spacing={1}>
        <Grid item lg={8} xs={12}>
          <VideoContainer movie={movie} user={user} />
          {matches && (
            <Grid item xs={12}>
              <MyCard
                title={t("movie comments")}
                content={
                  <Comments
                    movieID={movie._id}
                    user={user}
                    comments={comments}
                  />
                }
              />
              <LinkedMovies linkedMovie={linkedMovie} />
            </Grid>
          )}
        </Grid>
        <Grid item lg={4} xs={12}>
          <FiltersContainer
            filters={movie.filters as FilterOptionFields[]}
            selectedLang={selectedLang}
          />
          <MyCard
            title={t("movie otherHeader")}
            content={<Collapse movie={movie} selectedLang={selectedLang} />}
          />

          {!matches && (
            <Grid item xs={12}>
              <MyCard
                title={t("movie comments")}
                content={
                  <Comments
                    movieID={movie._id}
                    user={user}
                    comments={comments}
                  />
                }
              />
              <LinkedMovies linkedMovie={linkedMovie} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default MovieContainer;
