import React, { useState } from "react";
import CSVReader from "react-csv-reader";
import axios from "axios";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function ImportUsers() {
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>([]);
  const [result, setResult] = useState([]);

  const downloadTemplate = async () => {
    try {
      const res = await axios(
        "https://edu-storage.s3.eu-central-1.amazonaws.com/template.csv",
      );
      console.log(res);
    } catch (error) {}
  };

  const onSubmit = async () => {
    if (data.length === 0) {
      // eslint-disable-next-line no-alert
      alert(t("input select file"));
    } else {
      try {
        await axios.post("/admins/usersManagement/addFromCSV", {
          data,
        });
      } catch (error) {
        if (typeof error === "object") setResult(error?.response?.data || []);
      }
    }
  };

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12}>
        <Typography align="center" variant="h4" color="primary" gutterBottom>
          {t("import users")}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Button
            onClick={downloadTemplate}
            component="a"
            href="https://edu-storage.s3.eu-central-1.amazonaws.com/template.csv"
            target="__blank"
            variant="contained"
            color="primary"
            style={{ marginBottom: 10 }}
          >
            הורד תבנית
          </Button>
        </Box>
      </Grid>

      {result.map((r, i) => (
        <Grid item md={3} sm={6} xs={12} key={i + r}>
          {r}
        </Grid>
      ))}

      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        <CSVReader
          onFileLoaded={(value) => {
            setData(value);
          }}
        />
      </Grid>
      <Button variant="contained" color="primary" onClick={onSubmit}>
        {t("import")}
      </Button>
    </Grid>
  );
}
