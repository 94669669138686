import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography, List } from "@material-ui/core";
import { Lang } from "@project/types";
import { addTab } from "../../../../redux/actions/utilsActions";
import MyCard from "../../../common/Card";
import ActionUI from "./ActionUI";
import Pagination from "../../Search/Pagination";
import Button from "../../Folders/Button";

type Props = {
  actions: any;
  selectedLang: Lang;
};

const LikedActions: React.FC<Props> = ({ actions, selectedLang }) => {
  const actionsPerPage = 10;
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // actions = actions.filter(a => a.userID !== null);
  const actionsToShow = actions.slice(
    page * actionsPerPage,
    page * actionsPerPage + actionsPerPage,
  );

  const handlePage = (p: any) => setPage(p - 1);

  const noActionMsg =
    actions.length === 0 ? (
      <Typography variant="subtitle2" align="center">
        {t("no action yet")}
      </Typography>
    ) : null;

  return (
    <MyCard
      title={t("profile likedActions")}
      content={
        noActionMsg || (
          <>
            <List>
              {actionsToShow.map((a: any) => {
                const showAction = true;
                return (
                  <div key={a._id}>
                    {showAction && (
                      <ActionUI
                        text={
                          <>
                            <Button
                              key={a._id}
                              title={a.name[selectedLang]}
                              background={{ data: a.imgLink, type: "url" }}
                              url={() => {
                                dispatch(
                                  addTab({
                                    label: a.name[selectedLang],
                                    value: a.name2,
                                    type: "movie",
                                    closeable: true,
                                  }),
                                );
                              }}
                            />
                          </>
                        }
                      />
                    )}
                  </div>
                );
              })}
            </List>
            <Pagination
              totalItems={actions.length}
              itemsPerPage={actionsPerPage}
              handleClick={handlePage}
            />
          </>
        )
      }
    />
  );
};

export default LikedActions;
