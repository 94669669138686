import React, { useState, useEffect } from "react";
import Axios from "axios";
import { functions, validation } from "@project/common";
import { useDispatch } from "react-redux";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ShareFields } from "@project/types";
import EZFormikUI from "ez-formikui";
import { ChoiceSelect } from "./ChoiceSelect";
import { QnaArray } from "./QnAForm";
import { AddQnA } from "./AddQna";
import Loading from "../../../common/Loading";
import { isProduction } from "../../../../helpers/functions";
import {
  closeDialog,
  openSnackbar,
} from "../../../../redux/actions/utilsActions";
import { useLangSelector } from "../../../../redux";

type Choice = "link" | "file";

type Props = {
  movieID: string;
  qna?: string;
};

export const Share: React.FC<Props> = ({ movieID, qna }) => {
  const lang = useLangSelector();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [choice, setChoice] = useState<Choice | undefined>(undefined);
  const [step, setStep] = useState(1);
  const [questions, setQuestions] = useState<QnaArray>([]);
  const [loading, setLoading] = useState(true);
  const [share, setShare] = useState<undefined | ShareFields>(undefined);
  const [name, setName] = useState("");

  const setNextStep = () => setStep((old) => old + 1);

  const onSubmit = async () => {
    try {
      const res = await Axios.post("/users/shares/", {
        movie: movieID,
        questions,
        name,
      });

      setShare(res.data);
      setLoading(false);
    } catch (error) {}
  };

  const showError = () => {
    dispatch(openSnackbar(t("Erorr, try again later..."), "error"));
    dispatch(closeDialog());
  };

  const copyLink = () => {
    if (!share) {
      showError();
    } else {
      navigator.clipboard.writeText(
        functions.genShareURL(share._id, isProduction()),
      );
      dispatch(openSnackbar(t("copied successfully"), "success"));
    }
  };

  const downloadFile = async () => {
    if (!share) {
      showError();
    } else {
      const result = await Axios.get(
        `/users/files/downloadShare/${share._id}`,
        {
          responseType: "blob",
        },
      );

      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.html");
      document.body.appendChild(link);
      link.click();
    }
  };

  useEffect(() => {
    if (step === 4) onSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <Grid container style={{ minWidth: "65vw" }} justify="center">
      {step === 1 && (
        <>
          <ChoiceSelect
            title={t("how would you like to share?")}
            buttons={[
              {
                text: t("as a file"),
                onClick: () => {
                  setChoice("file");
                  setNextStep();
                },
              },
              {
                text: t("as a link"),
                onClick: () => {
                  setChoice("link");
                  setNextStep();
                },
              },
            ]}
          />
          <Typography color="error" style={{ marginTop: 10 }}>
            *{t("share movie terms")}
          </Typography>
        </>
      )}

      {step === 2 && (
        <Grid item xs={12}>
          <EZFormikUI
            title={t("form name")}
            paragraph={t("form name description")}
            onSubmit={(values) => {
              setName(values.name);
              setNextStep();
            }}
            typographyProps={{ title: { variant: "h5" } }}
            validationSchema={validation.users.name(lang)}
            fields={[
              {
                fieldName: "name",
                type: "text",
                options: "text",
                label: t("form name"),
                initialValue: "",
              },
            ]}
            submitProps={{ text: t("next"), grid: { xs: 12 } }}
          />
        </Grid>
      )}

      {step === 3 && (
        <AddQnA
          setQuestions={setQuestions}
          qna={qna}
          setNextStep={setNextStep}
        />
      )}

      {step === 4 && (
        <>
          {loading && <Loading />}
          {!loading && (
            <Grid container item xs={12} justify="center" alignItems="center">
              {choice === "file" && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={downloadFile}
                >
                  {t("download file")}
                </Button>
              )}

              {choice === "link" && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Grid item xs={12}>
                    <Typography variant="body1" align="center" gutterBottom>
                      {functions.genShareURL(share!._id, isProduction())}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={copyLink}
                    >
                      {t("copy link")}
                    </Button>
                  </Grid>
                </Box>
              )}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
