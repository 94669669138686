import React from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TableAbstract, { Columns, Urls } from "../../common/TableAbstract";

const urls: Urls = {
  mount: "/users/shares/",
  delete: (id) => `/users/shares/${id}`,
};

export const Shares = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const showAnswers = (id: string) => history.push(`/dashboard/shares/${id}`);

  const columns: Columns = ({ selectedLang: lang }) => [
    {
      title: t("form name"),
      field: "name",
      editable: "never",
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      render: (rowData) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => showAnswers(rowData._id)}
        >
          {t("show answers")}
        </Button>
      ),
    },
    {
      render: (rowData) => (
        <Button
          variant="outlined"
          target="_blank"
          href={`/shares/${rowData._id}`}
        >
          {t("builder menage link")}
        </Button>
      ),
    },
  ];

  return (
    <TableAbstract
      title={t("shared movies")}
      columns={columns}
      urls={urls}
      tableOptions={{ exportButton: false }}
    />
  );
};
