import React from "react";
import Axios from "axios";
import { validation, functions } from "@project/common";
import EZFormikUI, { Fields, FormikSubmit } from "ez-formikui";
import { useTranslation } from "react-i18next";
import { BuilderFields, TagFields } from "@project/types";
import { Container } from "@material-ui/core";
import { initialValueToSingle } from "../../../common/TableAbstract";
import { useFetch } from "../../../../hooks";
import Loading from "../../../common/Loading";
import { useLangSelector } from "../../../../redux";
import { Title } from "./Title";
import { Short } from "./Short";
import { Tag } from "./Tag";
import { Pages } from "./Pages";
import { AutoSave } from "./AutoSave";
import { FinishPage } from "./FinishPage";

type Props = {
  builder?: BuilderFields;
};

export const BuilderForm: React.FC<Props> = ({ builder }) => {
  const { t } = useTranslation();
  const { loading, data: tags } = useFetch<TagFields[]>("/users/tags/");
  const selectedLang = useLangSelector();
  const storageBuilder = JSON.parse(localStorage.getItem("builder") || "null");
  const [posted, setPosted] = React.useState<null | BuilderFields>(null);

  if (loading) return <Loading />;

  const fields: Fields = [
    {
      fieldName: "title",
      label: t("builder title"),
      type: "other",
      component: Title,
      initialValue: builder?.title || storageBuilder?.title || "",
    },
    {
      fieldName: "tag",
      type: "other",
      label: "",
      component: Tag(
        functions.convertToOptions(tags!, "_id", "name", selectedLang),
      ),
      initialValue:
        initialValueToSingle(
          builder?.tag as TagFields,
          "_id",
          "name",
          selectedLang,
        ) || storageBuilder?.tag,
    },
    {
      fieldName: "short",
      label: t("builder short"),
      type: "other",
      component: Short,
      initialValue: builder?.short || storageBuilder?.short || "",
    },
    {
      fieldName: "pages",
      label: "קישור",
      type: "other",
      component: Pages,
      initialValue: builder?.pages || storageBuilder?.pages || [],
    },
    {
      fieldName: "autoSave",
      label: "",
      type: "other",
      component: AutoSave,
      initialValue: "",
    },
  ];

  const onSubmit: FormikSubmit = async (values) => {
    const url = builder ? `/users/builders/${builder._id}` : "/users/builders/";
    const method = builder ? Axios.put : Axios.post;

    const data = { ...values };

    data.tag = data.tag.value;

    const res = await method(url, data);

    localStorage.removeItem("builder");
    setPosted(res.data);
  };

  return (
    <Container maxWidth={false} style={{ position: "relative", top: "-15vh" }}>
      {!posted && (
        <EZFormikUI
          validationSchema={validation.users.builder(selectedLang)}
          fields={fields}
          onSubmit={onSubmit}
        />
      )}

      {posted && <FinishPage builder={posted} />}
    </Container>
  );
};
