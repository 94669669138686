import {
  clearFieldError,
  setFieldError,
  setFullName,
  setFieldAnswer,
} from "../slices/t5";

export const setT5FullName = (name: string) => setFullName(name);

export const setT5Error = (field: string, error: string) =>
  setFieldError({ field, error });

export const clearT5Error = (field: string) => clearFieldError(field);

export const setT5Answer = (field: string, answer: string) =>
  setFieldAnswer({ field, answer });
