import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { useSelector, TypedUseSelectorHook } from "react-redux";
import logger from "redux-logger";
import { Lang } from "@project/types";
import reducer from "./reducer";

const isProd = process.env.NODE_ENV === "production";

const defaultMiddleware = getDefaultMiddleware({ serializableCheck: false });
const middleware = isProd
  ? [...defaultMiddleware]
  : [...defaultMiddleware, logger];

const store = configureStore({
  reducer,
  middleware,
  devTools: !isProd,
});

export type RootState = ReturnType<typeof store.getState>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useGuaranteedUserSelector = () =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useTypedSelector((state) => state.auth.user!);

export const useLangSelector = (): Lang =>
  useTypedSelector((state) => state.utils.lang);

export default store;
