import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    zIndex: 20000,
    bottom: 0,
    width: "100%",
    minHeight: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  msg: {
    margin: "0 5px",
  },
}));

export default function CookieAlert() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [closed, setClosed] = useState(
    localStorage.getItem("acceptcookies") !== null,
  );

  const handleAccept = () => {
    localStorage.setItem("acceptcookies", "true");
    setClosed(true);
  };

  return closed ? null : (
    <Paper className={classes.root}>
      <div className={classes.msg}>{t("cookie msg")}</div>
      <Button variant="contained" color="primary" onClick={handleAccept}>
        {t("accept")}
      </Button>
    </Paper>
  );
}
