import React from "react";
import { useTranslation } from "react-i18next";
import TableAbstract, { Columns } from "../../../common/TableAbstract";
import PageForm from "./PageForm";

const urls = {
  mount: "/admins/pages/",
  delete: (id: string) => `/admins/pages/${id}`,
};

export default function Pages() {
  const { t } = useTranslation();

  const columns: Columns = (tableActions) => [
    {
      title: t("admin-pages name"),
      field: `name.${tableActions.selectedLang}`,
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("admin-pages handle"),
      field: "handle",
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
  ];

  return (
    <TableAbstract
      title={t("admin-pages title")}
      columns={columns}
      urls={urls}
      AddForm={PageForm}
      EditForm={PageForm}
      addFormFullWidth
      useLang
    />
  );
}
