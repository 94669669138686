import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import TableAbstract, { Columns } from "../../../common/TableAbstract";

const urls = {
  mount: "/admins/filters/",
  delete: (id: string) => `/admins/filters/${id}`,
  update: (id: string) => `/admins/filters/${id}`,
  add: "/admins/filters/",
};

export default function Filters() {
  const { t } = useTranslation();
  const history = useHistory();
  const columns: Columns = ({ selectedLang: lang }) => [
    {
      title: t("admin-filters name"),
      field: `name.${lang}`,
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("admin-filters OrderBy"),
      field: "OrderBy",
      filtering: true,
      emptyValue: "0",
    },
    {
      title: t("admin-filters options"),
      field: "options",
      filtering: false,
      editable: "never",
      render: (rowData) => (
        <Button
          onClick={() => history.push(`/admin/filters/options/${rowData._id}`)}
        >
          {t("admin-filters optionsClick")}
        </Button>
      ),
    },
    {
      title: t("admin-filters profile"),
      field: "UserProfile",
      type: "boolean",
      filtering: true,
    },
    {
      title: t("admin-filters search"),
      field: "Search",
      type: "boolean",
      filtering: true,
    },
  ];

  return (
    <TableAbstract
      title={t("admin-filters title")}
      columns={columns}
      urls={urls}
      useLang
    />
  );
}
