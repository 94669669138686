import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";

type ChoiceSelectProps = {
  title: string;
  buttons: { text: string; onClick: () => void }[];
};

export const ChoiceSelect: React.FC<ChoiceSelectProps> = ({
  title,
  buttons,
}) => (
  <>
    <Grid item xs={12}>
      <Typography variant="h5" align="center" gutterBottom>
        {title}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Box display="flex" justifyContent="space-evenly">
        {buttons.map((b, i) => (
          <Button
            key={`${i}:${b.text}`}
            variant="contained"
            color="primary"
            onClick={b.onClick}
          >
            {b.text}
          </Button>
        ))}
      </Box>
    </Grid>
  </>
);
