import React from "react";
import EZFormikUI, { Fields, AfterDefaultSubmit } from "ez-formikui";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { validation, functions } from "@project/common";
import { Grid } from "@material-ui/core";
import { isProduction } from "../../../helpers/functions";
import { useTypedSelector, useLangSelector } from "../../../redux";
import Discount from "./Discount";
import Terms from "./Terms";
import { AlignCenter, AuthLinks } from "../AuthHelpers";

type Props = {
  phone?: string;
};

const Register: React.FC<Props> = ({ phone }) => {
  const history = useHistory();
  const isLoggedin = useTypedSelector((state) => state.auth.isLoggedin);
  const { t } = useTranslation();
  const lang = useLangSelector();
  const isHersog = functions.isHersog(window.location.hostname);

  const fields: Fields = [
    {
      fieldName: "name",
      label: t("register-name"),
      type: "text",
      options: "text",
      initialValue: "",
    },
    {
      fieldName: "email",
      label: t("register-email"),
      type: "text",
      options: "email",
      initialValue: "",
    },
    {
      fieldName: "phone",
      label: t("register-phone"),
      type: "text",
      options: "tel",
      initialValue: phone || "",
    },
    {
      fieldName: "discount",
      label: t("register-discount"),
      type: "other",
      component: Discount,
      initialValue: "",
    },
    {
      fieldName: "ck",
      label: t("terms of use"),
      type: "other",
      component: Terms,
      initialValue: false,
    },
  ];

  const afterDefaultSubmit: AfterDefaultSubmit = () => {
    if (phone) window.location.reload();
    else history.push("/login");
  };

  if (isLoggedin) {
    history.push("/dashboard");
  }

  const textGen = () => {
    if (phone) {
      return {
        title: t("didn't register yet?"),
        paragraph: t("3 clicks and you're there"),
      };
    }

    if (isHersog) {
      return {
        title: "איזה כיף שהצטרפת אלינו!",
        paragraph: " רק צריך להשלים את הפרטים כדי לגשת דרך המכללה לכל התוכן =)",
      };
    }

    return {
      title: t("register-title"),
      paragraph: t("register-text"),
    };
  };

  const { paragraph, title } = textGen();

  return (
    <AlignCenter>
      <Grid container justify="center">
        <Grid item md={6} xs={12}>
          <EZFormikUI
            fields={fields}
            title={title}
            paragraph={paragraph}
            typographyProps={{
              paragraph: { variant: "h6", color: "textPrimary" },
            }}
            onSubmit="/guests/auth/register"
            afterDefaultSubmit={afterDefaultSubmit}
            validationSchema={validation.auth.registerWithTerms(lang)}
            useCaptcha={isProduction()}
            submitProps={{ text: t("register-submit"), grid: { xs: 12 } }}
          />

          <AuthLinks links={[{ text: t("already user?"), to: "/login" }]} />
        </Grid>
      </Grid>
    </AlignCenter>
  );
};

export default Register;
