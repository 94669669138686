import { RouteType } from "./RouteType";
import Users from "../components/admin/tables/Users";
import Discounts from "../components/admin/tables/Discounts";
import Tags from "../components/admin/tables/Tags";
import Movies from "../components/admin/tables/Movie";
import Filters from "../components/admin/tables/Filters";
import FilterOptions from "../components/admin/tables/FilterOptions";
import LinkedMovies from "../components/admin/tables/LinkedMovies";
import Folders from "../components/admin/tables/Folders";
import Groups from "../components/admin/tables/Groups";
import Pages from "../components/admin/tables/Pages";
import Studios from "../components/admin/tables/Studio";
import CopyFolder from "../components/admin/CopyFolder";
import ImportUsers from "../components/admin/ImportUsers";
import SuggestedLinked from "../components/admin/tables/SuggestedLinked";
import { GenerateCatalog } from "../components/admin/GenerateCatalog";

const routes: RouteType[] = [
  {
    path: "/admin/users",
    component: Users,
    linkText: "admin-navbar users",
  },
  {
    path: "/admin/movies",
    component: Movies,
    linkText: "admin-navbar movies",
  },
  {
    path: "/admin/folders",
    component: Folders,
    linkText: "admin-navbar folders",
  },
  {
    path: "/admin/groups",
    component: Groups,
    linkText: "admin-navbar groups",
  },
  {
    path: "/admin/pages",
    component: Pages,
    linkText: "admin-navbar pages",
  },
  {
    path: "/admin/studios",
    component: Studios,
    linkText: "admin-navbar studios",
  },
  {
    path: "/admin/discounts",
    component: Discounts,
    linkText: "admin-navbar discounts",
  },
  {
    path: "/admin/filters",
    component: Filters,
    linkText: "admin-navbar filters",
  },
  {
    path: "/admin/suggestedLinked",
    component: SuggestedLinked,
    linkText: "admin-navbar suggestedLinked",
  },
  {
    path: "/admin/linkedMovies",
    component: LinkedMovies,
    linkText: "admin-navbar linkedMovies",
  },
  {
    path: "/admin/filters/options/:owner",
    component: FilterOptions,
  },
  {
    path: "/admin/tags",
    component: Tags,
    linkText: "admin-navbar tags",
  },
  {
    path: "/admin/copyFolder",
    component: CopyFolder,
    linkText: "admin-navbar copyFolder",
  },
  {
    path: "/admin/importUsers",
    component: ImportUsers,
    linkText: "admin-navbar importUsers",
  },
  {
    path: "/admin/generateCatalog",
    component: GenerateCatalog,
    linkText: "admin-navbar generate catalog",
  },
];

export default routes;
