import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Container from "@material-ui/core/Container";
import MyCard from "../../common/Card";
import EditProfile from "./EditInfo";
import ProfileInfo from "./profileInfo/ProfileInfo";
import UserActions from "./actions/Actions";
import EditFilters from "./filters/EditFilters";
import ProfileSkeleton from "./ProfileSkeleton";

export default function UserProfile({ match }) {
  const { t } = useTranslation();
  const selectedLang = useSelector((state) => state.utils.lang);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const showCollapse =
    // eslint-disable-next-line no-undef
    window.location.pathname.replace("/dashboard/profile/", "") === "home";

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await axios("/users/profile/");
        setUser(res.data);
      } catch (error) {}
      setLoading(false);
    };
    fetch();
  }, []);

  // eslint-disable-next-line no-shadow
  const matchToComponent = (match) => {
    switch (match) {
      case "info":
        return (
          <MyCard
            title={t("profile updateInfo")}
            content={<EditProfile selectedLang={selectedLang} user={user} />}
          />
        );
      case "filters":
        return <EditFilters selectedLang={selectedLang} user={user} />;
      case "actions":
        return <UserActions selectedLang={selectedLang} user={user} />;

      default:
        return null;
    }
  };

  return (
    <Container maxWidth="xl">
      {loading ? (
        <ProfileSkeleton />
      ) : (
        <>
          <ProfileInfo
            selectedLang={selectedLang}
            user={user}
            showCollapse={showCollapse}
          />
          {matchToComponent(match.params.field, user)}
        </>
      )}
    </Container>
  );
}
