/* eslint-disable import/no-cycle */
import axios from "axios";
import { SavedMovieItem } from "../redux/slices/utils";
import store from "../redux";

/**
 * sets axios default authorization header
 * @param token false: deletes token, string: sets default token
 */
export const setAuthToken = (token: string | false) => {
  if (token) {
    axios.defaults.headers.common.Authorization = token;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

export const isProduction = () => process.env.NODE_ENV === "production";

export const downloadAxios = async (
  data: {
    _id: string;
    location: string;
  },
  html?: boolean,
) => {
  const result = await axios.get(
    `/users/files/${html ? "downloadHtml" : "download"}/${data._id}`,
    {
      responseType: "blob",
    },
  );

  const url = window.URL.createObjectURL(new Blob([result.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", data.location.split("/").pop()!);
  document.body.appendChild(link);
  link.click();
};

export const downloadAxiosHtml = async (data: { _id: string }) => {
  const result = await axios.get(`/users/files/downloadHtml/${data._id}`, {
    responseType: "blob",
  });

  const url = window.URL.createObjectURL(new Blob([result.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "file.html");
  document.body.appendChild(link);
  link.click();
};

export const downloadSaved = async (arr: SavedMovieItem[], uid: string) => {
  try {
    const res = await axios.post("/users/movies/makeDoc", {
      arr,
      userID: uid,
      arrBool: true,
    });
    await downloadAxios(res.data);
  } catch (error) {}
};

export const handleTimer = (movieID: string, userID: string) => {
  const state = store.getState();
  const time = state.utils.timers[movieID];

  axios.post("/guests/builders/addView", { mid: movieID, time, userID });
};

export const shouldHideNavbarOrFooter = () =>
  window.location.pathname.split("/").includes("t5");
