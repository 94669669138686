import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios/index";
import { functions } from "@project/common";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useLangSelector } from "../../../redux";

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    flexGrow: 1,
    margin: "0 7px",
    "& .MuiAutocomplete-input": {
      padding: "0!important",
    },
    // eslint-disable-next-line @typescript-eslint/quotes
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: "6px!important",
    },
  },
  margin: {
    marginRight: 3,
  },
}));

export default function SearchBar({ name = "", setName }: any) {
  const classes = useStyles();
  const { t } = useTranslation();
  const selectedLang = useLangSelector();
  const [taglist, setSTags] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const resTags = await axios("/users/tags");
      setSTags(
        resTags.data.map((tag: any) => ({
          name: functions.findFilledLang(tag.name, selectedLang),
        })),
      );
    };
    fetch();
  }, [selectedLang]);

  return (
    <Box
      id="basic-example"
      display="flex"
      alignItems="cemter"
      className={classes.autocomplete}
    >
      <Typography
        variant="subtitle1"
        color="primary"
        className={classes.margin}
      >
        {t("searchbar label")}
      </Typography>
      <Autocomplete
        freeSolo
        className={classes.autocomplete}
        id="free-solo-2-demo"
        disableClearable
        defaultValue={name}
        options={taglist.map((option: any) => option.name)}
        renderInput={(params: any) => {
          setName(params.inputProps.value);
          return (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              InputProps={{ ...params.InputProps, type: "search" }}
            />
          );
        }}
      />
    </Box>
  );
}
