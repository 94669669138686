import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import amber from "@material-ui/core/colors/amber";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "@material-ui/core";
import Axios from "axios";
import { useGuaranteedUserSelector } from "../../redux";
import { refreshJwt } from "../../redux/actions/authActions";

const useStyles = makeStyles((theme) => ({
  userMsg: {
    backgroundColor: amber[400],
    position: "fixed",
    zIndex: 1001,
    color: "black",
    marginTop: 40,
    marginBottom: 10,
    padding: "10px 20px",
    width: "100%",
  },
}));

export default function Alerts() {
  const classes = useStyles();
  const user = useGuaranteedUserSelector();
  const { t } = useTranslation();
  const [closed, setClosed] = useState(false);

  const handleClick = async () => {
    try {
      await Axios.post("/users/profile/hidealert");
      setClosed(true);
      await refreshJwt();
    } catch (error) {}
  };

  return user.firstTime ? (
    <Paper className={classes.userMsg} hidden={closed}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <div>
          {t("user first time msg")}
          <Link to="/dashboard/profile/filters">{t("click here")}</Link>
        </div>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Button onClick={handleClick}>{t("dont show again")}</Button>
          <CloseIcon onClick={() => setClosed(true)} />
        </Box>
      </Box>
    </Paper>
  ) : null;
}
