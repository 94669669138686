import { Movie as MovieType, MovieFields } from "@project/types";
import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField, Button as CoreButton, Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useLangSelector, useTypedSelector } from "../../../../../../redux";
import Loading from "../../../../../common/Loading";
import { OnChange } from "../../types";
import Button from "../../../../Folders/Button";
import Search from "../../../../Search";
import { addTab } from "../../../../../../redux/actions/utilsActions";

type Props = {
  onChange: OnChange;
  value: MovieType;
  errorObj: { [key: string]: string };
};

export const Movie: React.FC<Props> = ({ onChange, value }) => {
  const [movies, setMovies] = useState<null | MovieFields[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedMovie, setSelectedMovie] = useState<null | MovieFields>(null);
  const selectedLang = useLangSelector();
  const { t } = useTranslation();
  const tabMovies = useTypedSelector((state) => state.utils.tabs).filter(
    (f) => f.type === "movie",
  );
  const [textValue, setTextValue] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const fetch = async () => {
      if (tabMovies.length > 0) {
        try {
          const res = await Axios.post("/users/movies/handleArr", {
            arr:
              value.movie !== ""
                ? [value.movie]
                : tabMovies.map((m) => m.value),
          });
          setMovies(res.data);
        } catch (error) {}
      } else {
        setMovies(null);
      }
      setLoading(false);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabMovies]);

  if (loading) return <Loading />;

  return (
    <div>
      {movies === null && (
        <Grid container>
          <Grid item xs={12}>
            {t("builder-page no-movies-exist")}
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={8}>
              <TextField
                variant="filled"
                onChange={(e) => setTextValue(e.target.value)}
                fullWidth
                placeholder={t("builder-search-placeholder")}
                value={textValue}
              />
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <CoreButton
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    dispatch(
                      addTab({
                        label: "tabs-search",
                        value: (
                          <Search
                            initialSearch={{
                              text: textValue,
                            }}
                          />
                        ),
                        closeable: true,
                        type: "search",
                      }),
                    );
                  }}
                >
                  {t("search")}
                </CoreButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
      {movies &&
        !selectedMovie &&
        movies.map((m) => (
          <Button
            key={m._id}
            title={m.name[selectedLang]}
            background={{ data: m.imgLink, type: "url" }}
            url={() => {
              if (!selectedMovie) {
                onChange("movie", m._id);
                setSelectedMovie(m);
              }
            }}
          />
        ))}
      {movies && selectedMovie && (
        <Button
          key={selectedMovie._id}
          title={selectedMovie.name[selectedLang]}
          background={{ data: selectedMovie.imgLink, type: "url" }}
          url={() => {}}
        />
      )}
    </div>
  );
};
