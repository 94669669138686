import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FilterSelect from "../../../common/FiltersSelect";
import MyCard from "../../../common/Card";
import { refreshJwt } from "../../../../redux/actions/authActions";

export default function FiltersForm({ user, selectedLang, filters, options }) {
  const { t } = useTranslation();
  const [value, setValue] = useState(user.filters || []);
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post("/users/profile/updateFilters", { filters: value });
      refreshJwt();
      history.push("/dashboard/profile/home");
    } catch (error) {}
  };

  return (
    <MyCard
      title={t("profile updateFilters")}
      content={
        <form onSubmit={handleSubmit}>
          <Typography variant="h6" align="center" gutterBottom>
            {t("profile filters desc")}
          </Typography>
          <FilterSelect
            value={value}
            filters={filters}
            options={options}
            setValue={setValue}
          />

          <Button type="submit" variant="contained" color="primary" fullWidth>
            {t("inputs submit")}
          </Button>
        </form>
      }
    />
  );
}
