import React from "react";
import { TextField, Typography } from "@material-ui/core";
import { BuilderFields } from "@project/types";
import { TFunction } from "i18next";
import { Movie } from "./Elements/Movie";
import { Qna } from "./Elements/Qna";
import { Link } from "./Elements/Link";
import { OnChange } from "../types";
import { ElementCard } from "./Elements/ElementCard";
import { Quote } from "./Elements/Quote";
import { Image } from "./Elements/Image";

type Page = BuilderFields["pages"][0];
type ElementType = Page["elements"][0]["type"];

export const pageFunctions = (value: Page[], setValue: (v: any) => void) => {
  const addPage = () => setValue([...value, { title: "", elements: [] }]);

  const removePage = (index: number) => {
    const old = [...value];
    old.splice(index, 1);
    setValue(old);
  };

  const movePageUp = (index: number) => {
    if (index === 0) return;

    const old = [...value];
    const el = old[index];

    old[index] = old[index - 1];
    old[index - 1] = el;

    setValue(old);
  };

  const movePageDown = (index: number) => {
    if (index === value.length - 1) return;

    const old = [...value];
    const el = old[index];

    old[index] = old[index + 1];
    old[index + 1] = el;

    setValue(old);
  };

  return { movePageDown, movePageUp, removePage, addPage };
};

export const elementFunctions = (
  page: Page,
  pageIndex: number,
  value: Page[],
  setValue: (v: any) => void,
) => {
  const removeElement = (indexPage: number, elementIndex: number) => {
    const old = [...value];
    const p: Page = old[indexPage];
    p.elements.splice(elementIndex, 1);
    setValue(old);
  };

  const addElement = (type: ElementType) => {
    const old = [...value];
    const p: Page = old[pageIndex];

    switch (type) {
      case "comments":
        if (
          p.elements.length < 2 &&
          page.elements.findIndex((e) => e.type === "text") === -1
        ) {
          p.elements.push({ type: "text", text: "" });
        }
        p.elements.push({ type });
        break;
      case "link":
        p.elements.push({ type, url: "", credit: "", linkType: "video" });
        break;
      case "image":
        p.elements.push({ type, url: "", credit: "" });
        break;
      case "movie":
        p.elements.push({ type, movie: "" });
        break;
      case "question":
        p.elements.push({
          type,
          qType: "text",
          question: "",
          answers: [],
          required: false,
        });
        break;
      case "quote":
        p.elements.push({ type, text: "" });
        break;
      case "text":
        p.elements.push({ type, text: "" });
        break;
      default:
    }

    setValue(old);
  };

  const pageGotSingleElement = () =>
    page.elements.findIndex((e) => ["movie", "link"].includes(e.type)) !== -1;
  const AddQuote = !pageGotSingleElement()
    ? () => addElement("quote")
    : undefined;

  const addComments =
    !pageGotSingleElement() &&
    page.elements.findIndex((e) => e.type === "comments") === -1
      ? () => addElement("comments")
      : undefined;
  const addLink =
    !pageGotSingleElement() && page.elements.length === 0
      ? () => addElement("link")
      : undefined;
  const addImage =
    !pageGotSingleElement() &&
    page.elements.findIndex((e) => e.type === "image") === -1
      ? () => addElement("image")
      : undefined;
  const addMovie =
    page.elements.length === 0 ? () => addElement("movie") : undefined;
  const addQuestion = !pageGotSingleElement()
    ? () => addElement("question")
    : undefined;
  const addText = !pageGotSingleElement()
    ? () => addElement("text")
    : undefined;
  const canAddElement = () =>
    page.elements.length < 3 && !pageGotSingleElement();

  const setPageValue = (field: keyof Page, newVal: any) => {
    const old = [...value];
    old[pageIndex][field] = newVal;
    setValue(old);
  };

  return {
    canAddElement,
    addText,
    addQuestion,
    addMovie,
    addImage,
    addLink,
    addComments,
    AddQuote,
    removeElement,
    setPageValue,
  };
};

export const fieldSwitch = (
  type: Page["elements"][0]["type"],
  onChange: OnChange,
  input: any,
  errorObj: any,
  t: TFunction,
) => {
  const error = errorObj && typeof errorObj === "object" ? errorObj : {};
  switch (type) {
    case "text":
      return (
        <ElementCard
          component={
            <TextField
              onChange={(e) => onChange("text", e.target.value)}
              placeholder={t("builder-page text")}
              rows={2}
              rowsMax={3}
              multiline
              fullWidth
              error={"text" in error}
              helperText={error.text}
              value={input.text}
            />
          }
          icon="text"
        />
      );
    case "quote":
      return (
        <ElementCard
          component={
            <Quote value={input} onChange={onChange} errorObj={error} />
          }
          icon="quote"
        />
      );
    case "comments":
      return (
        <ElementCard
          component={
            <Typography variant="h4" align="center">
              {t("builder-page comments")}
            </Typography>
          }
          icon="comments"
        />
      );
    case "link":
      return (
        <ElementCard
          component={
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link value={input} onChange={onChange} errorObj={error} />
          }
          icon="link"
        />
      );
    case "image":
      return (
        <ElementCard
          component={
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Image value={input} onChange={onChange} errorObj={error} />
          }
          icon="link"
        />
      );
    case "movie":
      return (
        <ElementCard
          component={
            <Movie value={input} onChange={onChange} errorObj={error} />
          }
          icon="movie"
        />
      );
    case "question":
      return (
        <ElementCard
          component={<Qna value={input} onChange={onChange} errorObj={error} />}
          icon="qna"
        />
      );
    default:
      return <></>;
  }
};
