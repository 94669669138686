/* eslint-disable no-case-declarations */
import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Lang, FilterOptionFields } from "@project/types";
import Card from "../../common/Card";

const useStyles = makeStyles((theme) => ({
  filterGrid: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

type Props = {
  selectedLang: Lang;
  filters: FilterOptionFields[];
};

const FiltersContainer: React.FC<Props> = ({ selectedLang, filters }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const filtersObject: { [key: string]: any } = {};
  // eslint-disable-next-line
  filters.map((f: any) => {
    if (!f) {
      console.log("empty");
    } else {
      const { owner } = f;
      if (!filtersObject[owner.name[selectedLang]]) {
        filtersObject[owner.name[selectedLang]] = [];
      }
      filtersObject[owner.name[selectedLang]].push(f.name[selectedLang]);
    }
  });

  const filterSwitch = (k: any) => {
    switch (k) {
      case "גילאים":
        return filtersObject[k];
      case "צניעות":
        const opts = ["סרט נקי", "רמה בינונית", "רמה נמוכה"];
        const got: any[] = [];
        filtersObject[k].forEach((f: any) => {
          got.push(opts.indexOf(f));
        });
        return opts[Math.min(...got)];
      case "סוג מסר":
        return filtersObject[k].map((f: any, i: any) => (
          <React.Fragment key={i}>
            <span>{f}</span>
            <br />
          </React.Fragment>
        ));
      default:
        return filtersObject[k].join(", ");
    }
  };

  if (filtersObject["גילאים"]) {
    const grades = [
      "גן",
      "א",
      "ג",
      "ד",
      "ו",
      "ז",
      "ח",
      "ט",
      "י",
      "יא",
      "יב",
      "סטודנטים",
    ];
    const a = filtersObject["גילאים"]
      .map((i: any) => i.replace("כיתות ", "").split("-"))
      .flat()
      .map((i: any) => grades.indexOf(i));

    filtersObject["גילאים"] = `כיתות ${grades[Math.min(...a)]} - ${
      grades[Math.max(...a)]
    }`;
  }

  return (
    <Card
      title={t("movie filterHeader")}
      content={
        <Grid container className={classes.filterGrid}>
          {Object.keys(filtersObject).map((k) => (
            <Grid item xs={4} key={k}>
              <Typography color="primary">
                <strong>{k}:</strong>
              </Typography>{" "}
              {filterSwitch(k)}
            </Grid>
          ))}
        </Grid>
      }
    />
  );
};

export default FiltersContainer;
