import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserPayload } from "@project/types";

export type State = {
  isLoggedin: boolean;
  user?: UserPayload;
};

const initialState: State = {
  isLoggedin: false,
  user: undefined,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (_, { payload }: PayloadAction<UserPayload | undefined>) => ({
      isLoggedin: payload !== undefined,
      user: payload,
    }),
    toggleLike: (state, { payload }: PayloadAction<string>) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const likesArr = state.user!.likes as string[];
      const index = likesArr.indexOf(payload);

      if (index === -1) likesArr.push(payload);
      else likesArr.splice(index, 1);
    },
  },
});

export const { setUser, toggleLike } = slice.actions;

export default slice;
