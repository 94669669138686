import { Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../../../../redux";

type Props = {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  pagesLength: number;
  setHalfPage: () => void;
};

export const Buttons: React.FC<Props> = ({
  setPage,
  setHalfPage,
  page,
  pagesLength,
}) => {
  const { t } = useTranslation();
  const errors = useTypedSelector((state) => state.t5.errors);
  const gotErrors = Object.keys(errors).length > 0;

  const btnStyle: React.CSSProperties | undefined = gotErrors
    ? { backgroundColor: "red", color: "white" }
    : undefined;

  const changePage = (way: string) => {
    if (gotErrors) return;
    switch (way) {
      case "down":
        setHalfPage();
        if (page > 1) setPage(page - 1);
        break;
      case "up":
        setHalfPage();
        setPage(page + 1);
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ zIndex: 10, pointerEvents: "all" }}>
      <Button
        variant="contained"
        style={{ ...btnStyle, margin: "0 5px" }}
        onClick={() => changePage("down")}
      >
        {t("back")}
      </Button>
      <Button
        variant="contained"
        style={btnStyle}
        onClick={() => changePage("up")}
      >
        {t("next")}
      </Button>
    </div>
  );
};
