import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import RadioIcon from "@material-ui/icons/RadioButtonChecked";
import { Question as QnaType } from "@project/types";
import React from "react";
import { useTranslation } from "react-i18next";
import { OnChange } from "../../types";

type Props = {
  onChange: OnChange;
  value: QnaType;
  errorObj: { [key: string]: string };
};

export const Qna: React.FC<Props> = ({ onChange, value, errorObj }) => {
  const { qType, answers, required } = value;
  const { t } = useTranslation();

  const addAnswer = () => {
    onChange("answers", [...answers!, ""]);
  };

  const AnswersMap = () => (
    <>
      <Typography variant="h6">{t("possible answers")}</Typography>
      {answers!.map((a, i) => (
        <Grid key={i} container item xs={12} style={{ margin: "5px 0" }}>
          <Grid item md={6} xs={12}>
            <Box display="flex">
              {" "}
              <div>
                {qType === "checkbox" && <CheckBoxIcon />}
                {qType === "radio" && <RadioIcon />}
              </div>
              <TextField
                onChange={(e) => onChange("answers", e.target.value, i)}
                placeholder={t("builder-page qna-add")}
                value={value.answers[i]}
                fullWidth
              />
            </Box>
          </Grid>
        </Grid>
      ))}
    </>
  );

  return (
    <Grid container item xs={12} style={{ marginTop: 15 }}>
      <Grid item xs={12}>
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label"> </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={qType}
            onChange={(e) => onChange("qType", e.target.value)}
            label="qType"
          >
            <MenuItem value="text">{t("builder-page qna-text")}</MenuItem>
            <MenuItem value="radio">{t("builder-page qna-radio")}</MenuItem>
            <MenuItem value="checkbox">
              {t("builder-page qna-checkbox")}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid container item xs={12} justify="space-between">
        <Grid item xs={6}>
          <TextField
            fullWidth
            onChange={(e) => onChange("question", e.target.value)}
            placeholder={t("builder-page qna")}
            value={value.question}
            error={"question" in errorObj}
            helperText={errorObj.question}
            rows={2}
            rowsMax={2}
            multiline
          />
        </Grid>
        <Grid item xs={6} style={{ textAlign: "center" }}>
          <FormControlLabel
            control={
              <Switch
                checked={required}
                onChange={(e) => onChange("required", !required)}
                color="primary"
              />
            }
            label={t("builder-page required")}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={qType === "text"}>{t("builder-page qna-text")}</Collapse>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={qType === "radio"}>
          {AnswersMap()}
          <Button onClick={addAnswer}>{t("builder-page qna-add")}</Button>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={qType === "checkbox"}>
          {AnswersMap()}
          <Button onClick={addAnswer}>{t("builder-page qna-add")}</Button>
        </Collapse>
      </Grid>
    </Grid>
  );
};
