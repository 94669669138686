import React from "react";
import { constants } from "@project/common";
import { useTranslation } from "react-i18next";
import TableAbstract, { Columns } from "../../../common/TableAbstract";
import text from "../../../../helpers/text";
import UserForm from "./UserForm";
import { useLangSelector } from "../../../../redux";

const ranksToLookup = (ranks: { label: string; value: any }[]) => {
  const res: { [key: string]: any } = {};
  ranks.forEach((r) => {
    res[r.value] = r.label;
  });

  return res;
};

const urls = {
  mount: "/admins/usersManagement/",
  delete: (id: string) => `/admins/usersManagement/${id}`,
};

export default function Users() {
  const { t } = useTranslation();
  const lang = useLangSelector();

  const columns: Columns = () => [
    { title: t("admin-users name"), field: "name", filtering: false },
    {
      title: t("admin-users email"),
      field: "email",
      filtering: false,
      editable: "never",
    },
    {
      title: t("admin-users phone"),
      field: "phone",
      filtering: false,
    },
    {
      title: t("admin-users perment"),
      field: "perment",
      type: "boolean",
    },
    {
      title: t("admin-users group"),
      field: `group.name.${lang}`,
      filtering: true,
      editable: "never",
    },
    {
      title: t("admin-users rank"),
      field: "rank",
      lookup: ranksToLookup(constants.ranks),
    },
    {
      title: t("admin-users tfa"),
      field: "tfa.use",
      filtering: true,
      type: "boolean",
    },
    {
      title: t("admin-users points"),
      field: "points",
      filtering: false,
      editable: "never",
    },
    {
      title: t("admin-movies percent"),
      field: "watchPercent.percent",
      filtering: false,
      type: "numeric",
      editable: "never",
    },
    {
      title: t("admin-movies views"),
      field: "watchPercent.count",
      filtering: false,
      type: "numeric",
      editable: "never",
    },
    {
      title: t("admin-users createdAt"),
      field: "createdAt",
      filtering: false,
      editable: "never",
      type: "date",
    },
    {
      title: t("admin-users activeDate"),
      field: "activeDate",
      filtering: false,
      type: "date",
    },
    {
      title: t("admin-users lastLogin"),
      field: "lastLogin.date",
      filtering: false,
      editable: "never",
      type: "date",
    },
  ];

  return (
    <TableAbstract
      title={text.adminUsers}
      columns={columns}
      urls={urls}
      AddForm={UserForm}
      EditForm={UserForm}
    />
  );
}
