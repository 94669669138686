/* eslint-disable react/no-danger */
import React, { useEffect, useState } from "react";
import { Divider, Box, Typography, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import Link from "@material-ui/core/Link";
import { functions } from "@project/common";
import { UserFields, MovieFields } from "@project/types";
import PlayIcon from "@material-ui/icons/PlayArrow";
import Card from "../../common/Card";
import Rate from "./Rate";
import Like from "./Like";
import useTimer from "../../../hooks/useTimer";
import { handleTimer } from "../../../helpers/functions";
import {
  addTimer,
  openDialog,
  closeDialog,
} from "../../../redux/actions/utilsActions";
import MovieForm from "../../admin/tables/Movie/MovieForm";
import { useLangSelector } from "../../../redux";
import { Share } from "./Share";
import logo from "../../../img/logo3.png";
import VimeoPlayer from "./VimeoPlayer";
import { useVimeo } from "../../../hooks";

const useStyles = makeStyles((theme) => ({
  vidWidth: {
    "& > :first-child": {
      width: "100% !important",
      height: "55vh !important",
    },
  },
}));

const IFrameWrapper = ({ link, img }: { link: string; img: string }) => {
  const [play, setPlay] = React.useState(false);

  return (
    <>
      {play ? (
        <div dangerouslySetInnerHTML={{ __html: link }} />
      ) : (
        <Box
          onClick={() => setPlay(true)}
          style={{
            width: "100%",
            height: "55vh ",
            position: "relative",
            backgroundColor: "black",
            cursor: "pointer",
            backgroundImage: `url(${img})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <div>
            <PlayIcon
              fontSize="large"
              style={{
                width: "3em",
                height: "3em",
                color: "white",
                backgroundColor: "rgba(241, 128, 44, 0.65)",
                borderRadius: 30,
              }}
            />
          </div>
          <img
            src={logo}
            alt="logo"
            style={{
              height: 100,
              width: 100,
              position: "absolute",
              top: 10,
              right: 10,
            }}
          />
        </Box>
      )}
    </>
  );
};

type Props = {
  movie: MovieFields;
  user: UserFields;
  /**
   * if true then will hide some stuff
   */
  share?: boolean;
};

const VideoContainer: React.FC<Props> = ({ movie, user, share = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isAdmin = functions.isAdmin(user);
  const lang = useLangSelector();
  const { time, start, pause } = useTimer();
  const isIframe = movie.movieLink.includes("<iframe");
  const isVimeo = movie.movieLink.includes("vimeo.com");
  const streamURL = "/guests/open/video/";
  const isS3 = movie.movieLink.includes(streamURL);
  const url = isS3
    ? `${streamURL}${user._id}/${movie.movieLink.split(streamURL).pop()}`
    : movie.movieLink;

  const { onProg, onReady, playing, setPlayer, setPlaying } = useVimeo({
    start: movie.playTimes.map((x) => x.start),
    end: movie.playTimes.map((x) => x.end),
  });

  if (isIframe) {
    const text = movie.movieLink;
    let newText = text.replace(
      /&embed=true.*' style/i,
      `&fade=true&&embed=true&ref=edumovie&ref_id=${user._id}---${movie._id}' style`,
    );
    if (!movie.movieLink.includes("webkitallowfullscreen")) {
      newText = text.replace(
        "name='vodiframe'",
        "name='vodiframe' webkitallowfullscreen = 'true' mozallowfullscreen = 'true' allowFullScreen = 'true'",
      );
    }
    movie.movieLink = newText;
  }

  const handleShareClick = () => {
    dispatch(
      openDialog(
        t("movie share"),
        <Share movieID={movie._id} qna={movie.qna[lang]} />,
      ),
    );
  };

  useEffect(() => {
    const listener = () => handleTimer(movie._id, user._id);

    if (!isIframe) window.addEventListener("beforeunload", listener);

    return () => {
      if (!isIframe) {
        handleTimer(movie._id, user._id);
        window.removeEventListener("beforeunload", listener);
      }
    };
  }, [isIframe, movie._id, user._id]);

  useEffect(() => {
    dispatch(addTimer({ movieID: movie._id, time }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return (
    <Card
      title={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {isAdmin && (
            <>
              <Button
                onClick={() => {
                  dispatch(
                    openDialog(
                      t("admin-movies edit-title"),
                      <MovieForm
                        rowData={movie}
                        tableActions={{
                          closeDialog: () => dispatch(closeDialog()),
                          setTableData: () => {},
                          addNewRow: () => {},
                          selectedLang: lang,
                          openDialog: () => {},
                          updateRow: () => {},
                        }}
                      />,
                      t("general close"),
                      true,
                    ),
                  );
                }}
              >
                עריכת מנהל
              </Button>

              <Link href={`/dashboard/movie/${movie.name2}`}>
                קליק ימני העתק קישור
              </Link>
            </>
          )}

          <Typography variant="h5" gutterBottom>
            {movie.name[lang]}
          </Typography>
          <Typography variant="h5" gutterBottom>
            {movie.lenght}
          </Typography>
        </Box>
      }
      content={
        <div className={classes.vidWidth}>
          {isIframe && (
            <IFrameWrapper link={movie.movieLink} img={movie.imgLink} />
          )}
          {/* {!isIframe && !isVimeo && (} */}
          {!isIframe && (
            // <ReactPlayer
            //   url={url}
            //   controls
            //   onPlay={start}
            //   onPause={pause}
            //   config={{
            //     file: {
            //       attributes: {
            //         controlsList: "nodownload",
            //       },
            //     },
            //   }}
            // />
            <ReactPlayer
              ref={setPlayer}
              url={url}
              controls
              onPlay={() => {
                start();
                setPlaying(true);
              }}
              onPause={pause}
              onReady={onReady}
              onProgress={onProg}
              playing={playing}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
              }}
            />
          )}
          {/* {isVimeo && (
            <VimeoPlayer
              onPlay={start}
              onPause={pause}
              link={url}
              start={[0, 15, 30]}
              end={[10, 20, 50]}
            />
          )} */}
          <Box
            display="flex"
            alignItems="center"
            m={1}
            justifyContent="space-between"
          >
            <Box alignItems="center" justifyContent="center">
              <Rate
                userID={user._id}
                movieID={movie._id}
                initialValue={movie.rating.rate}
                count={movie.rating.count}
              />
              <div>({movie.rating.count.toLocaleString()})</div>
            </Box>
            {!share && (
              <>
                <Button
                  color="primary"
                  component="a"
                  onClick={handleShareClick}
                  target="_blank"
                >
                  לחץ לשיתוף התלמידים
                </Button>

                <Like movieID={movie._id} user={user} />
              </>
            )}
          </Box>
          <Divider />
          {!share && (
            <Grid container>
              <Grid item xl={6} xs={12}>
                <Typography>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: movie.short[lang] || "",
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          )}
        </div>
      }
    />
  );
};

export default VideoContainer;
