import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Color as VariantType } from "@material-ui/lab/Alert";
import { Lang, LangObject } from "@project/types";
import initialTabs, { InitialTabs } from "../../helpers/initialTabs";

export type State = {
  tabs: InitialTabs;
  timers: { [movieID: string]: number };
  savedMovies: { id: string; name: LangObject; handle: string }[];
  lang: Lang;
  dialog: {
    title: string;
    body: string | JSX.Element;
    buttonText?: string;
    fullscreen: boolean;
    isOpen: boolean;
  };
  snackbar: {
    msg: string | JSX.Element;
    variant: VariantType;
    isOpen: boolean;
  };
};

export type SavedMovieItem = State["savedMovies"][0];
export type TabItem = State["tabs"][0];
export type Dialog = State["dialog"];
export type Snackbar = State["snackbar"];
export type TimerItem = { movieID: string; time: number };

const initialState: State = {
  tabs: initialTabs,
  timers: {},
  savedMovies: [],
  lang: "he",
  dialog: {
    title: "",
    body: "",
    buttonText: undefined,
    fullscreen: false,
    isOpen: false,
  },
  snackbar: {
    msg: "",
    variant: "warning",
    isOpen: false,
  },
};

function tabsToStorage(tabs: State["tabs"]) {
  localStorage.setItem("tabs", JSON.stringify(tabs));
}

function savedMoviesToStorage(movies: State["savedMovies"]) {
  localStorage.setItem("savedMovies", JSON.stringify(movies));
}

const slice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    setDialog: (state, { payload }: PayloadAction<Dialog>) => {
      state.dialog = payload;
    },
    setSnackbar: (state, { payload }: PayloadAction<Snackbar>) => {
      state.snackbar = payload;
    },
    setLanguage: (state, { payload }: PayloadAction<Lang>) => {
      state.lang = payload;
    },
    addTab: (state, { payload }: PayloadAction<TabItem>) => {
      const { tabs } = state;

      tabs.push(payload);
      tabsToStorage(tabs);
    },
    setTabs: (state, { payload }: PayloadAction<TabItem[]>) => {
      state.tabs = payload;
    },
    removeTabByIndex: (state, { payload }: PayloadAction<number>) => {
      const { tabs } = state;

      tabs.splice(payload, 1);
      tabsToStorage(tabs);
    },
    addSavedMovie: (state, { payload }: PayloadAction<SavedMovieItem>) => {
      const { savedMovies } = state;
      const index = savedMovies.findIndex((item) => item.id === payload.id);

      if (index === -1) {
        savedMovies.unshift(payload);
        savedMoviesToStorage(savedMovies);
      }
    },
    setMovies: (state, { payload }: PayloadAction<SavedMovieItem[]>) => {
      state.savedMovies = payload;
    },
    addTimer: (state, { payload }: PayloadAction<TimerItem>) => {
      const { movieID, time } = payload;

      state.timers[movieID] = time;
    },
  },
});

export const {
  setDialog,
  setSnackbar,
  addSavedMovie,
  addTab,
  setTabs,
  addTimer,
  removeTabByIndex,
  setLanguage,
  setMovies,
} = slice.actions;

export default slice;
