import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import StarIcon from "@material-ui/icons/Star";
import Badge from "@material-ui/core/Badge";
import TimeIcon from "@material-ui/icons/Timer";
import { MovieFields, Lang, TagFields } from "@project/types";
import { Grid } from "@material-ui/core";
import Tags from "./Tags";
import DefaultImg from "../../../img/noimage.png";
import { addTab } from "../../../redux/actions/utilsActions";

const useStyles = makeStyles({
  card: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  media: {
    height: 300,
  },
  cardContent: {
    width: "100%",
    position: "absolute",
    padding: "3px",
    minHeight: "302px",
    bottom: 0,
    right: 0,
    backgroundColor: "#fff",
    zIndex: 31,
    pointerEvents: "auto",
    transform: (props: any) =>
      props.showShort ? "translateY(0)" : "translateY(270px)",
    transition: "all ease 0.3s",
    "& p": {
      maxHeight: 160,
      opacity: (props: any) => (props.showShort ? 1 : 0),
    },
  },
  cardActions: {
    // height: "100%",
    padding: "0px",
  },
  badge: {
    position: "absolute",
    top: 15,
    left: 15,
    zIndex: 32,
  },
  btn: {
    display: "flex",
    justifyContent: "center",
    "& span": {
      fontSize: "18px",
    },
  },
  cardTitle: {
    height: (props: any) => (props.showShort ? "auto" : "30px"),
  },
});

type Props = {
  movie: MovieFields;
  selectedLang: Lang;
  movieBadge?: any;
};

const MovieCard: React.FC<Props> = ({
  movie,
  selectedLang,
  movieBadge = null,
}) => {
  const [showShort, setShowShort] = useState(false);
  const classes = useStyles({ showShort });
  const { t } = useTranslation("translations");
  const dispatch = useDispatch();

  const toggleShow = () => setShowShort(!showShort);

  return (
    <Card className={classes.card} raised>
      {movieBadge && (
        <Badge
          color="primary"
          badgeContent={movieBadge}
          className={classes.badge}
        />
      )}

      <Tags tags={movie.tags as TagFields[]} selectedLang={selectedLang} />

      <CardActionArea disabled style={{ overflow: "hidden" }}>
        <CardMedia
          className={classes.media}
          image={movie.imgLink || DefaultImg}
        />

        <CardContent
          className={classes.cardContent}
          // onMouseEnter={() => setShowShort(true)}
          onMouseLeave={() => setShowShort(false)}
          onClick={toggleShow}
        >
          <Typography
            onMouseEnter={() => setShowShort(true)}
            gutterBottom
            align="center"
            variant="h5"
            component="h2"
            className={classes.cardTitle}
          >
            {movie.name[selectedLang] ? movie.name[selectedLang] : ""}
          </Typography>

          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontSize: 18, padding: 8 }}
            component="p"
            dangerouslySetInnerHTML={{
              __html: movie.short[selectedLang] || "",
            }}
          />
        </CardContent>
      </CardActionArea>

      <CardActions className={classes.cardActions}>
        <Grid container spacing={1} justify="center">
          <Grid item xs={6}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <TimeIcon />
              {movie.lenght}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <StarIcon style={{ color: "#FFB400" }} />
              <div>
                {movie.rating.rate !== 0
                  ? (movie.rating.rate / movie.rating.count).toFixed(1)
                  : 0}
              </div>
              <div>({movie.rating.count.toLocaleString()})</div>
            </Box>
          </Grid>
        </Grid>
      </CardActions>
      <Button
        size="large"
        color="primary"
        style={{ width: "100%" }}
        onClick={() => {
          dispatch(
            addTab({
              label: movie.name[selectedLang] || t("admin-table emptyValue"),
              value: movie.name2,
              closeable: true,
              type: "movie",
            }),
          );
        }}
      >
        {t("dashboard toMovie-button")}
      </Button>
    </Card>
  );
};

export default MovieCard;
