import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    marginBottom: "15px",
  },
  card: {
    width: "100%",
  },
  cardContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  cardActions: {
    justifyContent: "center",
  },
}));

export default function ProfileSkeleton() {
  const classes = useStyles();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item md={6} xs>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Skeleton variant="circle" width={120} height={120} />

            <Skeleton height={10} width="30%" />

            <Skeleton height={6} width="50%" />
            <Skeleton height={6} width="50%" />
            <Skeleton height={6} width="50%" />
            <Skeleton height={6} width="50%" />
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Skeleton height={4} width="20%" />
            <Skeleton height={4} width="20%" />
            <Skeleton height={4} width="20%" />
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
