import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import { useTypedSelector } from "../../../redux";
import Loading from "../../common/Loading";

type Props = {
  bid: string;
};

export const FinishPage = ({ bid }: Props) => {
  const { t } = useTranslation();
  const answers = useTypedSelector((state) => state.t5.answers);
  const name = useTypedSelector((state) => state.t5.fullName);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const post = async () => {
      try {
        const tmp = { ...answers };
        const data: {
          eid: string;
          answer: string;
          name: string;
          bid: string;
        }[] = [];

        Object.keys(tmp).map(async (k) => {
          const answer = tmp[k];

          if (answer.includes("!%")) {
            tmp[k] = answer
              .split("!%")
              .filter((a) => a !== "")
              .join(", ");
          }

          if (tmp[k] !== "") {
            data.push({
              eid: k,
              answer: tmp[k],
              name,
              bid,
            });
          }
        });

        await Axios.post(`/guests/builders/answer/${bid}`, { answers: data });

        setLoading(false);
      } catch (error) {}
    };
    post();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading />;

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h4" style={{ color: "white" }} align="center">
        {t("t5 finish")}
      </Typography>
    </Box>
  );
};
