import React from "react";
import moment from "moment";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { StudioFields } from "@project/types";
import { useDispatch } from "react-redux";
import TableAbstract, {
  Columns,
  TableActions,
} from "../../../common/TableAbstract";
import StudioForm from "./StudioForm";
import { openDialog } from "../../../../redux/actions/utilsActions";

const urls = {
  mount: "/admins/studios/",
  delete: (id: string) => `/admins/studios/${id}`,
  payment: "/admins/studios/payStudio",
};

export default function Studios() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const viewHistory = (
    paymentHistory: StudioFields["paymentHistory"],
    studioPrice: number,
  ) => {
    const Component = (
      <div>
        {paymentHistory.map((p, i) => (
          <div
            style={{ border: "1px solid black", margin: 5, padding: 5 }}
            key={i}
          >
            <p>{`תאריך תשלום:${moment(p.date).format("DD/MM/YY")}`}</p>
            <p>{`כמות (שניות או צפיות):${p.amount}`}</p>
            <p>{`מחיר לצפייה:${p.price}`}</p>
            <p>{`סה"כ שולם:${p.amount * studioPrice}`}</p>
          </div>
        ))}
      </div>
    );

    dispatch(openDialog("היסטוריית תשלום", Component));
  };

  const paymentClick = async (id: string, tableActions: TableActions) => {
    try {
      const res = await Axios.post(`/admins/studios/payStudio/${id}`);

      tableActions.setTableData((old) => {
        const tmp = [...old];
        const index = tmp.findIndex((i) => i._id === id);

        if (index < 0) return old;

        const { totalViews } = tmp[index];
        tmp[index] = res.data;
        tmp[index].viewsSinceLastPayment = 0;
        tmp[index].totalViews = totalViews;

        return tmp;
      });
    } catch (error) {}
  };

  const columns: Columns = (tableActions) => [
    {
      title: t("admin-studios name"),
      field: "name",
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("admin-studios price"),
      field: "price",
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("admin-studios currency"),
      field: "currency",
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("notificationsMail"),
      field: "notificationsMail",
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("admin-studios newMovieNotification"),
      field: "newMovieNotification",
      type: "boolean",
      filtering: true,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("admin-studios viewsType"),
      field: "viewsType",
      filtering: true,
      emptyValue: t("admin-table emptyValue"),
      render: (rowData) => <>{t(rowData.viewsType)}</>,
    },
    {
      title: t("admin-studios payment history"),
      filtering: false,
      render: (rowData) => (
        <Button
          onClick={() => viewHistory(rowData.paymentHistory, rowData.price)}
        >
          הסטורית תשלומים
        </Button>
      ),
    },
    {
      title: t("admin-studios last pay date"),
      field: "lastPayment",
      filtering: false,
      type: "date",
      emptyValue: "טרם שולם",
      render: (rowData) => (
        <div>{moment(rowData.lastPayment).format("DD/MM/YY")}</div>
      ),
    },
    {
      title: t("admin-studios views since last pay"),
      field: "viewsSinceLastPayment",
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("admin-studios need to pay"),
      filtering: false,
      render: (rowData) => (
        <div>{rowData.viewsSinceLastPayment * rowData.price}</div>
      ),
    },
    {
      title: t("admin-studios pay"),
      field: "viewsSinceLastPayment",
      filtering: false,
      render: (rowData, i) => (
        <Button onClick={() => paymentClick(rowData._id, tableActions)}>
          שולם?
        </Button>
      ),
    },
    {
      title: t("admin-studios total views"),
      field: "totalViews",
      filtering: false,
      emptyValue: t("admin-table emptyValue"),
    },
  ];

  return (
    <TableAbstract
      title={t("admin-studios title")}
      columns={columns}
      urls={urls}
      AddForm={StudioForm}
      EditForm={StudioForm}
      addFormFullWidth
      useLang
    />
  );
}
