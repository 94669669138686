import { BuilderFields } from "@project/types";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useFetch } from "../../../hooks";
import Loading from "../../common/Loading";

import { T5Container } from "./T5Container";

export const T5Index: React.FC<RouteComponentProps<{ id: string }>> = ({
  match: { params },
}) => {
  const { id } = params;
  const { loading, data: t5data } = useFetch<BuilderFields>(`/guests/t5/${id}`);

  if (loading) return <Loading />;
  return <T5Container t5data={t5data!} id={id} />;
};
