import { Quote as QType } from "@project/types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField } from "@material-ui/core";
import { OnChange } from "../../types";

type Props = {
  onChange: OnChange;
  value: QType;
  errorObj: { [key: string]: string };
};

export const Quote: React.FC<Props> = ({ onChange, value, errorObj }) => {
  const { t } = useTranslation();

  return (
    <Grid container item xs={12} style={{ marginTop: 10 }}>
      <Grid container item md={6} xs={12}>
        <Grid item xs={12}>
          <TextField
            onChange={(e) => onChange("text", e.target.value)}
            placeholder={t("builder-page quote")}
            rows={2}
            rowsMax={3}
            multiline
            fullWidth
            error={"text" in errorObj}
            helperText={errorObj.url}
            value={value.text}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            onChange={(e) => onChange("credit", e.target.value)}
            placeholder={t("builder-page credit")}
            value={value.credit}
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
