import axios from "axios";
import { Color as VariantType } from "@material-ui/lab/Alert";
import { Lang } from "@project/types";
import {
  setDialog,
  setSnackbar,
  addSavedMovie,
  addTab as sliceAddTab,
  setTabs as utilsSetTabs,
  addTimer as sliceAddTimer,
  removeTabByIndex,
  setLanguage as sliceSetLanguage,
  setMovies as sliceSetMovies,
  SavedMovieItem,
  TabItem,
  TimerItem,
  Snackbar,
} from "../slices/utils";
import i18n from "../../i18n";

export const openDialog = (
  title: string,
  body: JSX.Element | string,
  buttonText = "",
  fullscreen = false,
) =>
  setDialog({
    title,
    body,
    buttonText,
    fullscreen,
    isOpen: true,
  });

export const closeDialog = () =>
  setDialog({
    title: "",
    body: "",
    buttonText: "",
    fullscreen: false,
    isOpen: false,
  });

export const openSnackbar = (msg: Snackbar["msg"], variant: VariantType) =>
  setSnackbar({
    msg,
    variant,
    isOpen: true,
  });

// passing variant on close to keep same color
export const closeSnackbar = (variant: VariantType) =>
  setSnackbar({ msg: "", variant, isOpen: false });

type AddMovie = (
  movieItem: SavedMovieItem,
) => { payload: SavedMovieItem; type: string };
export const addMovie: AddMovie = (movieItem) => addSavedMovie(movieItem);

export const setMovies = (moviesArr: SavedMovieItem[]) =>
  sliceSetMovies(moviesArr);

export const clearMovies = () => {
  localStorage.removeItem("savedMovies");
  return sliceSetMovies([]);
};

export const addTab = (tab: TabItem) => sliceAddTab(tab);

export const setTabs = (tabs: TabItem[]) => utilsSetTabs(tabs);

export const removeTab = (index: number) => removeTabByIndex(index);

export const addTimer = (timer: TimerItem) => sliceAddTimer(timer);

export const setLanguage = (lang: Lang) => {
  localStorage.setItem("language", lang);
  i18n.changeLanguage(lang);
  axios.defaults.headers.common.selectedLang = lang;

  return sliceSetLanguage(lang);
};
