/* eslint-disable react/no-danger */
import React, { useState } from "react";
import {
  Collapse,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ChoiceSelect } from "./ChoiceSelect";
import { QnAForm, QnaArray } from "./QnAForm";

type AddQnAProps = {
  setNextStep: () => void;
  setQuestions: (questions: QnaArray) => void;
  qna?: string;
};

type Stage = "add qna?" | "qna form";

export const AddQnA: React.FC<AddQnAProps> = ({
  qna,
  setNextStep,
  setQuestions,
}) => {
  const { t } = useTranslation();
  const [stage, setStage] = useState<Stage>("add qna?");

  return (
    <>
      <Grid item xs={12}>
        <Collapse in={stage === "add qna?"}>
          <ChoiceSelect
            title={t("add questions?")}
            buttons={[
              {
                text: t("yes"),
                onClick: () => {
                  setStage("qna form");
                },
              },
              {
                text: t("no"),
                onClick: () => {
                  setNextStep();
                },
              },
            ]}
          />
        </Collapse>
      </Grid>

      {stage === "qna form" && (
        <Grid item xs={12}>
          <Grid item xs={12}>
            <ExpansionPanel square expanded>
              <ExpansionPanelSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography color="primary" align="center">
                  <strong>{t("movie qna example")}</strong>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <span dangerouslySetInnerHTML={{ __html: qna || "" }} />
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <QnAForm setQuestions={setQuestions} setNextStep={setNextStep} />
          </Grid>
        </Grid>
      )}
    </>
  );
};
