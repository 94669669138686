import React from "react";
import { useDispatch } from "react-redux";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MUIDialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { closeDialog } from "../../redux/actions/utilsActions";
import { useTypedSelector } from "../../redux";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id?: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.65)",
  },
  root: {
    maxWidth: "none!important",
  },
}));

export default function Dialog() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const dialogState = useTypedSelector((state) => state.utils.dialog);
  const { t } = useTranslation();
  const { title, body, buttonText, fullscreen, isOpen } = dialogState;

  const handleClose = () => {
    dispatch(closeDialog());
  };

  return (
    <MUIDialog
      PaperProps={{ classes: { root: classes.root } }}
      onClose={handleClose}
      open={isOpen}
      fullScreen={fullscreen}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <DialogTitle onClose={handleClose}>{title}</DialogTitle>
      <DialogContent dividers>{body}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          {buttonText || t("general close")}
        </Button>
      </DialogActions>
    </MUIDialog>
  );
}
