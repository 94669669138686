import React, { useState } from "react";
import axios from "axios";
import { functions } from "@project/common";
import { UserFields, UploadFields } from "@project/types";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { useGuaranteedUserSelector } from "../../../redux";
import { downloadAxios } from "../../../helpers/functions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  primaryColor: {
    backgroundColor: theme.palette.primary.main,
  },
}));

type Props = {
  files: UploadFields[];
  movieID: string;
  noFiles?: string;
};

const FilesList: React.FC<Props> = ({
  files: initialFiles,
  movieID,
  noFiles = "",
}) => {
  const classes = useStyles();
  const user = useGuaranteedUserSelector();
  const isAdmin = functions.isAdmin(user);
  const [files, setFiles] = useState(initialFiles);

  if (files.length === 0) {
    return (
      <Typography variant="subtitle2" align="center">
        {noFiles}
      </Typography>
    );
  }

  const delFunc = async (id: string) => {
    try {
      await axios.delete(`/admins/generalActions/file/${id}`);
      const old = [...files];
      const index = old.findIndex((f) => f._id === id);

      if (index !== -1) {
        old.splice(index, 1);
        setFiles(old);
      }
    } catch (e) {}
  };

  return (
    <List className={classes.root}>
      {files.map((f) => {
        const fileUser = f.userID as UserFields;

        return (
          <React.Fragment key={f._id}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar className={classes.primaryColor}>
                  {fileUser.name[0].toUpperCase()}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={fileUser.name}
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    />
                    <Button
                      onClick={async () => {
                        await downloadAxios({
                          _id: f._id,
                          location: f.location,
                        });
                      }}
                    >
                      {f.name}
                    </Button>
                    {isAdmin && (
                      <Button onClick={() => delFunc(f._id)}>מחק</Button>
                    )}
                  </>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default FilesList;
