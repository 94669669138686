import React from "react";
import { useHistory } from "react-router-dom";
import { functions } from "@project/common";
import { useTypedSelector } from "../../redux";

function Home() {
  const isLoggedin = useTypedSelector((state) => state.auth.isLoggedin);
  const history = useHistory();

  if (isLoggedin) {
    history.push("/dashboard");
  }

  if (functions.isHersog(window.location.hostname)) {
    history.push("/login");
  }

  return (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <iframe
      src="https://elhanancohen.online/"
      style={{
        height: "5000px",
        width: "100%",
        overflow: "hidden",
      }}
      frameBorder={0}
      scrolling="no"
    />

    // <iframe src={"http://3.120.38.46/"}></iframe>
    // <Grid container justify="center" style={{ marginTop: "100px" }}>
    //   <Grid item xs={3}>
    //     <img src={Img} style={{ width: "100%", height: "auto" }} alt="logo" />
    //   </Grid>
    // </Grid>
  );
}

export default Home;
