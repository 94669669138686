import React, { useState } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Fab,
  Collapse,
  useScrollTrigger,
  Container,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/Search";
import ArrowUpwardIcon from "@material-ui/icons/Close";
import HomeBG from "../../../img/homebg.jpg";
import "./intro.css";
// eslint-disable-next-line import/no-cycle
import MainSearch from "../Search/MainSearch";

const useStyles = makeStyles((theme) => ({
  root: {
    background: `url(${HomeBG}) no-repeat center center fixed`,
    backgroundSize: "cover",
    MozBackgroundSize: "cover",
    WebkitBackgroundSize: "cover",
    OBackgroundSize: "cover",
    width: "100%",
    minHeight: "60vh",
    // height: "100vh",
    // width: "calc(100vw - 32px)"
  },
  title: {
    alignSelf: "center",
    textAlign: "center",
    "& img": {
      width: "auto",
      height: "180px",
    },
  },
  searchContainer: {
    position: "fixed",
    top: 95,
    zIndex: 900,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      top: 85,
    },
  },
  search: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  arrowFab: {
    backgroundColor: "white",
    position: "relative",
    top: (props: any) => (props.open ? -20 : 20),
  },
  triggerMargin: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: 100,
    },

    [theme.breakpoints.down("sm")]: {
      marginBottom: 160,
    },

    [theme.breakpoints.down("xs")]: {
      marginBottom: 240,
    },
  },
  marginResp: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: 40,
    },
  },
}));

export default function Intro() {
  const [open, setOpen] = useState(false);
  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  });
  const classes = useStyles({ open });

  return (
    <Grid
      container
      className={classnames({ [classes.triggerMargin]: trigger })}
    >
      <Grid
        item
        xs={12}
        className={classnames(classes.marginResp, {
          [classes.searchContainer]: trigger,
        })}
      >
        <Collapse
          in={open || !trigger}
          className={classnames({ [classes.search]: trigger })}
        >
          <Container>
            <MainSearch />
          </Container>
        </Collapse>
        {trigger && (
          <Fab
            size="small"
            onClick={() => setOpen((o) => !o)}
            className={classes.arrowFab}
          >
            {open ? (
              <ArrowUpwardIcon color="primary" />
            ) : (
              <ArrowDownwardIcon color="primary" />
            )}
          </Fab>
        )}
      </Grid>
    </Grid>
  );
}
