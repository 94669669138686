import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import axios from "axios";
// eslint-disable-next-line import/no-cycle
import LinkedMovie from "../../common/LinkedMovies";
import Loading from "../../common/Loading";

export default function LinkedSearch({ searchText = "", flag, setFlag }: any) {
  const [search, setSearch] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const SearchFunc = async () => {
    if (flag) {
      setLoading(true);
      const res = await axios.post("/users/linkedMovies/search", {
        text: searchText,
      });
      setSearch(res.data);
      setLoading(false);
      setFlag(false);
    }
  };

  useEffect(() => {
    SearchFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, flag]);

  if (loading) return <Loading />;

  return (
    <Grid container spacing={2} direction="column">
      {search.map((link: any) => (
        <Grid item xs={12} key={`${link._id}linked`}>
          <LinkedMovie linkedMovie={link} />
        </Grid>
      ))}
    </Grid>
  );
}
