import React from "react";
import { validation } from "@project/common";
import EZFormikUI, { Fields, AfterDefaultSubmit } from "ez-formikui";
import { GroupFields } from "@project/types";
import { TableActions } from "../../../common/TableAbstract";

type Props = {
  tableActions: TableActions;
  rowData?: GroupFields;
};

const GroupForm: React.FC<Props> = ({ rowData: group, tableActions }) => {
  const { selectedLang, updateRow, addNewRow, closeDialog } = tableActions;
  const url = group ? `/admins/groups/${group._id}` : "/admins/groups/";
  const method = group ? "put" : "post";

  const fields: Fields = [
    {
      fieldName: "name",
      label: "שם הקבוצה",
      type: "text",
      options: "text",
      initialValue: group?.name[selectedLang] || "",
    },
    {
      fieldName: "payingOption",
      label: "payingOption",
      type: "select",
      options: [
        { value: "monthly", label: "חודשי" },
        { value: "reva", label: "רבעוני" },
      ],
      isMulti: false,
      initialValue: group?.payingOption || "monthly",
    },
    {
      fieldName: "payingLimit",
      label: "payingLimit",
      type: "select",
      options: [
        { value: "14", label: "14 יום" },
        { value: "+1", label: "שוטף" },
        { value: "+30", label: "שוטף +30" },
        { value: "+60", label: "שוטף +60" },
        { value: "+90", label: "שוטף +90" },
      ],
      isMulti: false,
      initialValue: group?.payingLimit || "14",
    },
    {
      fieldName: "price",
      label: "מחיר לצפייה",
      type: "text",
      options: "number",
      initialValue: group?.price || 0,
    },
    {
      fieldName: "contactMail",
      label: "איש קשר",
      type: "text",
      options: "text",
      initialValue: group?.contactMail || "",
    },
    {
      fieldName: "ignoreBills",
      label: "אם מסומן המערכת תתעלם ולא תייצר הודעות חיוב",
      type: "switch",
      initialValue: group?.ignoreBills || false,
    },
    {
      fieldName: "popMessage",
      label: "אם מסומן תיפתח הודעה בסיום ה 180 יום להתחברות דרך אתר המכללה",
      type: "switch",
      initialValue: group?.popMessage || false,
    },
  ];

  const afterDefaultSubmit: AfterDefaultSubmit = (res) => {
    const { data } = res!;

    if (group) updateRow(data);
    else addNewRow(data);

    closeDialog();
  };

  return (
    <EZFormikUI
      validationSchema={validation.admins.addGroup(selectedLang)}
      fields={fields}
      onSubmit={url}
      submitMethod={method}
      afterDefaultSubmit={afterDefaultSubmit}
    />
  );
};

export default GroupForm;
