import React, { useEffect, useState } from "react";
import Axios from "axios";
import EZFormikUI, { FormikSubmit } from "ez-formikui";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { validation, functions } from "@project/common";
import { useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import { isProduction } from "../../../helpers/functions";
import { loginUser } from "../../../redux/actions/authActions";
import { useTypedSelector, useLangSelector } from "../../../redux";
import TFA from "./TFA";
import { FieldsWithTranslation } from "../../../types";
import { AlignCenter, AuthLinks } from "../AuthHelpers";

const fields: FieldsWithTranslation = (t) => [
  {
    fieldName: "email",
    label: t("login-email"),
    type: "text",
    options: "email",
    initialValue: "",
  },
  {
    fieldName: "password",
    label: t("login-password"),
    type: "text",
    options: "password",
    initialValue: "",
  },
];

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = RouteComponentProps<{}, {}, { lastPath?: string }>;

const Login: React.FC<Props> = ({ location: { state: redirect } }) => {
  const { t } = useTranslation();
  const [nextStep, setNextStep] = useState(false);
  const [creds, setCreds] = useState({ email: "", password: "" });
  const lang = useLangSelector();
  const lastPath = redirect ? redirect.lastPath : null;
  const history = useHistory();
  const isLoggedin = useTypedSelector((state) => state.auth.isLoggedin);
  const dispatch = useDispatch();
  const isHersog = functions.isHersog(window.location.hostname);

  const onSubmit: FormikSubmit = async (values) => {
    setCreds({ email: values.email, password: values.password });

    const res = await Axios.post("/guests/auth/login", values);

    if (res.data.nextStep) {
      setNextStep(true);
    } else {
      const { token } = res?.data;
      dispatch(loginUser(token));

      if (lastPath) history.push(lastPath);
      else history.push("/dashboard");
    }
  };

  useEffect(() => {
    if (isLoggedin) {
      history.push("/dashboard");
    }
  }, [isLoggedin, history]);

  return (
    <AlignCenter>
      <Grid container justify="center">
        <Grid item md={6} xs={12}>
          {nextStep ? (
            <TFA values={creds} />
          ) : (
            <>
              <EZFormikUI
                title={isHersog ? "ברוכים הבאים! " : t("login-header")}
                paragraph={
                  isHersog
                    ? "הזן את פרטי המייל והסיסמא שלך כדי להתחבר"
                    : t("login-text")
                }
                fields={fields(t)}
                onSubmit={onSubmit}
                validationSchema={validation.auth.login(lang)}
                submitProps={{ text: t("login-submit"), grid: { xs: 12 } }}
                typographyProps={{
                  paragraph: { color: "textPrimary" },
                }}
                useCaptcha={isProduction()}
              />

              <AuthLinks
                links={[
                  { text: t("dont have account?"), to: "/register" },
                  { text: t("login-phone"), to: "/login" },
                  { text: t("login-reset"), to: "/passwordReset" },
                ]}
              />
            </>
          )}
        </Grid>
      </Grid>
    </AlignCenter>
  );
};

export default Login;
