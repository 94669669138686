import React from "react";
import { Card, CardContent, Grid, makeStyles } from "@material-ui/core";
import { builderIcons } from "../../../Icons";
import { PageIcon } from "../../../PageCard/PageIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
  card: {
    backgroundColor: "#E7E7E7",
    overflow: "visible",
  },
  icon: {
    position: "relative",
    right: 20,
  },
}));

export type Props = {
  component: React.ReactNode;
  icon: keyof typeof builderIcons;
};

export const ElementCard: React.FC<Props> = ({ component, icon }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.root}>
      <Card className={classes.card}>
        <Grid container item xs={12} alignItems="center">
          <Grid item md={1} xs={3}>
            <div className={classes.icon}>
              <PageIcon
                variant="action"
                Icon={builderIcons[icon]}
                useAnimations={false}
              />
            </div>
          </Grid>
          <Grid item md={11} xs={9}>
            <CardContent>{component}</CardContent>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
