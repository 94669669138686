import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Typography, Divider, Grid, Box, Tooltip } from "@material-ui/core";
import { LinkedMoviesFields, MovieFields } from "@project/types";
import IconButton from "@material-ui/core/IconButton";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { useLangSelector, useGuaranteedUserSelector } from "../../redux";
import { downloadSaved } from "../../helpers/functions";
import MovieCard from "./HighlightMovies/MovieCard";
import { SavedMovieItem } from "../../redux/slices/utils";

const useStyles = makeStyles((theme) => ({
  filterGrid: {
    display: "flex",
    justifyContent: "space-between",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

type Props = {
  linkedMovie: LinkedMoviesFields;
};

const LinkedMovies: React.FC<Props> = ({ linkedMovie }) => {
  const selectedLang = useLangSelector();
  const { t } = useTranslation();
  const classes = useStyles();
  const userID = useGuaranteedUserSelector()._id;
  const arr: SavedMovieItem[] = [];

  if (
    !linkedMovie.name ||
    !(
      linkedMovie.movies.mid1 !== null ||
      linkedMovie.movies.mid2 !== null ||
      linkedMovie.movies.mid3 !== null ||
      linkedMovie.movies.mid4 !== null
    )
  ) {
    return <></>;
  }

  const { mid1, mid2, mid3, mid4 } = linkedMovie.movies;

  if (mid1) {
    const { _id: id, name, name2: handle } = mid1 as MovieFields;
    arr.push({ id, name, handle });
    (mid1 as MovieFields).short = linkedMovie.movies.text1!;
  }

  if (mid2) {
    const { _id: id, name, name2: handle } = mid2 as MovieFields;
    arr.push({ id, name, handle });
    (mid2 as MovieFields).short = linkedMovie.movies.text2!;
  }
  if (mid3) {
    const { _id: id, name, name2: handle } = mid3 as MovieFields;
    arr.push({ id, name, handle });
    (mid3 as MovieFields).short = linkedMovie.movies.text3!;
  }
  if (mid4) {
    const { _id: id, name, name2: handle } = mid4 as MovieFields;
    arr.push({ id, name, handle });
    (mid4 as MovieFields).short = linkedMovie.movies.text4!;
  }

  return (
    <Paper className={classes.paper} style={{ justifyContent: "center" }}>
      <Box display="flex" justifyContent="space-between">
        <Tooltip title={t("download linked") as string}>
          <IconButton
            onClick={() => {
              downloadSaved(arr, userID);
            }}
            size="medium"
          >
            <ArrowDownwardIcon fontSize="inherit" color="primary" />
          </IconButton>
        </Tooltip>
        <Typography variant="h5" color="primary" gutterBottom>
          {linkedMovie.name[selectedLang]}
        </Typography>
      </Box>
      <Divider />
      <Grid
        item
        container
        spacing={3}
        xs={12}
        style={{ justifyContent: "center", marginTop: "10px" }}
      >
        {linkedMovie.movies.mid1 && (
          <Grid item lg={3} xs={6}>
            <MovieCard
              movie={linkedMovie.movies.mid1 as MovieFields}
              selectedLang={selectedLang}
              movieBadge={1}
            />
          </Grid>
        )}
        {linkedMovie.movies.mid2 && (
          <Grid item lg={3} xs={6}>
            <MovieCard
              movie={linkedMovie.movies.mid2 as MovieFields}
              selectedLang={selectedLang}
              movieBadge={2}
            />
          </Grid>
        )}
        {linkedMovie.movies.mid3 && (
          <Grid item lg={3} xs={6}>
            <MovieCard
              movie={linkedMovie.movies.mid3 as MovieFields}
              selectedLang={selectedLang}
              movieBadge={3}
            />
          </Grid>
        )}
        {linkedMovie.movies.mid4 && (
          <Grid item lg={3} xs={6}>
            <MovieCard
              movie={linkedMovie.movies.mid4 as MovieFields}
              selectedLang={selectedLang}
              movieBadge={4}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default LinkedMovies;
