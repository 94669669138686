import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: 300,
    width: "100%",
  },
  image: {
    position: "relative",
    height: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
}));

export default function ButtonBases({
  title,
  extendData = false,
  url,
  background,
}) {
  const classes = useStyles();
  const bg =
    background.type === "color"
      ? { backgroundColor: background.data }
      : {
          backgroundImage: `url(${background.data})`,
          backgroundSize: "cover",
        };

  return (
    <ButtonBase
      focusRipple
      className={classes.image}
      focusVisibleClassName={classes.focusVisible}
      onClick={url}
      style={{
        width: "100%",
        height: extendData ? "100px" : 100,
        position: "relative",
        ...bg,
      }}
    >
      <span className={classes.imageSrc} />
      <span className={classes.imageBackdrop} />
      <span className={classes.imageButton}>
        <div className={classes.imageTitle}>
          <Typography component="span" variant="h5" color="inherit">
            {title}
            <span className={classes.imageMarked} />
          </Typography>
          {/* {extendData && (
            <div>
              <div
              dangerouslySetInnerHTML={{ __html: movieData.short["he"] }}
            ></div>
            <div>
              
            </div>
            </div>
          )} */}
        </div>
      </span>
    </ButtonBase>
  );
}
