import React from "react";
import { PageFields } from "@project/types";
import { RouteComponentProps } from "react-router-dom";
import { useFetch } from "../../hooks";
import Loading from "../common/Loading";
import { useLangSelector } from "../../redux";

type Props = RouteComponentProps<{ handle: string }>;

const DynamicPages: React.FC<Props> = ({ match: { params } }) => {
  const { handle } = params;
  const { data, loading } = useFetch<PageFields>(`/guests/pages/${handle}`);
  const lang = useLangSelector();

  if (loading) return <Loading />;

  return (
    <div
      style={{ textAlign: "center" }}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: data?.text[lang] || "Error" }}
    />
  );
};

export default DynamicPages;
