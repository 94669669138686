import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type State = {
  fullName: string;
  errors: { [key: string]: string };
  answers: { [key: string]: string };
};

const initialState: State = {
  fullName: "",
  answers: {},
  errors: {},
};

const slice = createSlice({
  name: "builder",
  initialState,
  reducers: {
    setFullName: (state, { payload }: PayloadAction<string>) => {
      state.fullName = payload;
    },
    setFieldError: (
      state,
      {
        payload: { field, error },
      }: PayloadAction<{ field: string; error: string }>,
    ) => {
      state.errors[field] = error;
    },
    setFieldAnswer: (
      state,
      {
        payload: { field, answer },
      }: PayloadAction<{ field: string; answer: string }>,
    ) => {
      state.answers[field] = answer;
    },
    clearFieldError: (state, { payload }: PayloadAction<string>) => {
      delete state.errors[payload];
    },
  },
});

export const {
  setFieldError,
  setFullName,
  clearFieldError,
  setFieldAnswer,
} = slice.actions;

export default slice;
