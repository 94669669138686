/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button, ButtonGroup, Typography, Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Skeleton from "@material-ui/lab/Skeleton";
import SearchBar from "./SearchBar";
import Search from "./index";
import { useLangSelector } from "../../../redux";
import { addTab } from "../../../redux/actions/utilsActions";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: "0 auto",
  },
  marginY: {
    margin: "15px 0",
  },
  btn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  select: {
    flexGrow: 1,
    margin: "0 7px",
    "& .MuiOutlinedInput-input": {
      padding: 6,
    },
  },
}));

export default function MainSearch({ className = "" }) {
  const selectedLang = useLangSelector();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, setFilters] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filtersOpt, setFiltersOpt] = useState([]);
  const [filterSearch, setFilterSearch] = useState<any>({ 0: [], 1: [] });
  const [uiarray, Setuiarray] = useState<any>({ labels: [], options: [] });
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleAdd = (e: any, i: string | number) => {
    const toSet: any = {};
    toSet[i] = [e.target.value];
    setFilterSearch((old: any) => ({ ...old, ...toSet }));
  };

  useEffect(() => {
    const fetch = async () => {
      const labels: any[] = [];
      const options: any[] = [];

      const res = await axios("/users/filters/search");
      const resOpt = await axios("/users/filters/filterOptions");
      setFilters(res.data.filter((f: any) => f.SearchMain));
      setFiltersOpt(resOpt.data.filter((f: any) => f.owner.SearchMain));

      res.data
        .filter((f: any) => f.SearchMain)
        .forEach((f: any, i: number) => {
          labels.push(
            <Typography variant="subtitle1" color="primary" key={f._id}>
              {f.name[selectedLang]}:
            </Typography>,
          );
          options.push([]);
          resOpt.data
            .filter((o: any) => o.owner._id === f._id)
            .forEach((z: any) => {
              options[i].push(
                <MenuItem key={z._id} value={z._id}>
                  {z.name[selectedLang]}
                </MenuItem>,
              );
            });
        });
      Setuiarray({ labels, options });
      setLoading(false);
    };
    fetch();
    // eslint-disable-next-line
  }, []);

  if (loading) return <Skeleton height={150} />;

  return (
    <div className={className}>
      <br />
      <Paper className={classes.paper}>
        <Grid container spacing={1} justify="center" alignItems="center">
          <Grid item lg={5} md={6} xs={12}>
            <SearchBar
              name={name}
              setName={setName}
              tags={tags}
              setTags={setTags}
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <Box display="flex" alignItems="center">
              {uiarray.labels.map((l: any, i: number) => (
                <Box
                  style={{ flexBasis: "50%" }}
                  key={`${i}label`}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {l}
                  <Select
                    className={classes.select}
                    fullWidth
                    value={filterSearch[i]}
                    onChange={(e) => handleAdd(e, i)}
                    variant="outlined"

                    // multiple
                  >
                    <MenuItem value={[]}>{t("emptyValue")}</MenuItem>

                    {uiarray.options[i]}
                  </Select>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item lg={3} md={12} xs={12} className={classes.btn}>
            <ButtonGroup>
              <Button
                color="primary"
                onClick={() => {
                  dispatch(
                    addTab({
                      label: "tabs-search",
                      value: <Search isOpen />,
                      closeable: true,
                      type: "search",
                    }),
                  );
                }}
              >
                {t("go to advanced search")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch(
                    addTab({
                      label: "tabs-search",
                      value: (
                        <Search
                          initialSearch={{
                            text: name,
                            arr: [...filterSearch[0], ...filterSearch[1]],
                          }}
                        />
                      ),
                      closeable: true,
                      type: "search",
                    }),
                  );
                }}
              >
                {t("search submit")}
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Paper>
      {/* <br /> */}
    </div>
  );
}
