import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Grid, Typography, Button, Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { functions } from "@project/common";
import { FolderFields } from "@project/types";
import { useLangSelector } from "../../redux";
import { useFetch } from "../../hooks";
import Loading from "../common/Loading";
import MySelect, { Option } from "../common/MySelect";

export default function CopyFolder() {
  const { t } = useTranslation();
  const history = useHistory();
  const selectedLang = useLangSelector();
  const [values, setValues] = useState<{
    folder1?: Option;
    folder2?: Option;
  }>({ folder1: undefined, folder2: undefined });
  const { data: folders, loading } = useFetch<FolderFields[]>(
    "/admins/folders",
  );

  const onSubmit = async () => {
    if (!values.folder1 || !values.folder2) {
      // eslint-disable-next-line no-alert
      alert(t("select both folders"));
    } else {
      try {
        const data = {
          folder1: values.folder1.value,
          folder2: values.folder2.value,
        };
        await axios.post("/admins/folders/copyFolder", data);
        history.push("/admin");
      } catch (error) {}
    }
  };

  if (loading) return <Loading />;

  const options = folders!.map((f) => ({
    label: functions.findFilledLang(f.name, selectedLang),
    value: f._id,
  }));

  return (
    <Container style={{ marginTop: "100px" }}>
      <Grid container justify="center" alignItems="center" spacing={3}>
        <Typography align="center" variant="h4" color="primary" gutterBottom>
          {t("copy folder")}
        </Typography>

        <Grid item xs={12}>
          <MySelect
            value={values.folder1}
            setValue={(selected) => {
              setValues((old) => ({ ...old, folder1: selected }));
            }}
            options={options}
            label={t("folder dest")}
            name="folder1"
          />
        </Grid>

        <Grid item xs={12}>
          <MySelect
            value={values.folder2}
            setValue={(selected) => {
              setValues((old) => ({ ...old, folder2: selected }));
            }}
            options={options}
            label={t("folder target")}
            name="folder2"
          />
        </Grid>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          {t("inputs submit")}
        </Button>
      </Grid>
    </Container>
  );
}
