import {
  Box,
  Container,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { BuilderFields, Element, TagFields } from "@project/types";
import React, { useState } from "react";
import { Progressbar } from "./components/Progressbar";
import { Buttons } from "./components/Buttons";
import { PageStruct } from "./components/PageStruct";
import { MainStruct } from "./components/MainStruct";
import { SizeType } from "./components/Types/Types";
import { AddName } from "./components/AddName";
import logo from "../../../img/logo3.png";
import { FinishPage } from "./FinishPage";

type Prop = {
  t5data: BuilderFields;
  id: string;
};

const useStyles = makeStyles<Theme, { imgUrl: string; isDesktop: boolean }>(
  () => ({
    root: {
      height: "100vh",
      display: "flex",
    },
    image: {
      backgroundImage: (props) => `url('${props.imgUrl}')`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      height: (props) => (props.isDesktop ? "100vh" : "25vh"),
    },
    mainContainer: {
      overflowY: "auto",
      height: (props) => (props.isDesktop ? "100%" : "100%"), // "75%"),
      padding: "0 10px",
    },
    progressbar: {
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
      pointerEvents: "none",
    },
    title: {
      textShadow: "2px 2px 2px rgba(150, 150, 150, 1)",
      color: "white",
      margin: "0 10px",
    },
  }),
);

export const T5Container: React.FC<Prop> = ({ t5data, id }) => {
  const [pageSize, setPageSize] = useState<SizeType>(6);
  const [page, setPage] = useState(0);
  const [imgBool, setImgBool] = useState(true);
  const pagesLength = t5data.pages.length + 1;
  const imagesCount = (t5data.tag as TagFields).images.length;
  const min = 0;
  const max = imagesCount - 1;
  const rand = Math.floor(min + Math.random() * (max - min));
  const isDesktop = useMediaQuery("(min-width:640px)");
  const isFinishPage = page === pagesLength + 1;
  const align = isDesktop ? "center" : "flex-start";

  let selectedImage = "https://picsum.photos/2400";
  const selectImage = () => {
    let flag = false;
    if (page > 1) {
      t5data.pages[page - 2]?.elements.forEach((elem: Element) => {
        if (
          elem.type === "image" &&
          t5data.pages[page - 2].elements.length > 1
        ) {
          selectedImage = elem.url;
          flag = true;
        }
      });
    }

    if (!flag) {
      if ((t5data?.tag as TagFields).images[rand]) {
        selectedImage = (t5data?.tag as TagFields).images[rand];
      }
    }
  };
  selectImage();
  const classes = useStyles({
    imgUrl: selectedImage,
    isDesktop,
  });

  const setFullPage = () => {
    setImgBool(false);
    setPageSize(12);
  };

  const setHalfPage = () => {
    setImgBool(true);
    setPageSize(6);
  };

  return (
    <Container maxWidth={false} disableGutters className={classes.root}>
      <Grid container justify="center">
        {imgBool && (
          <Grid item xs={12} md={6} className={classes.image}>
            <Box display="flex" alignItems={align} ml="5%" mt="5%">
              <img src={logo} alt="logo" height={50} width={50} />
              <Typography className={classes.title} variant="h5">
                {t5data.title}
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} md={pageSize} className={classes.mainContainer}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p={1}
            height="100%"
          >
            <Box height="100%" display="flex" alignItems={align}>
              {page === 0 && <AddName />}
              {page === 1 && (
                <MainStruct
                  page={page}
                  pageData={{
                    title: t5data ? t5data.title : "",
                    short: t5data ? t5data.short : "",
                  }}
                  align={align}
                />
              )}
              {page > 1 && page <= pagesLength && (
                <PageStruct
                  setFullPage={setFullPage}
                  page={page}
                  pageData={t5data!.pages[page - 2]}
                  bid={id}
                  createdBy={t5data!.createdBy}
                />
              )}
              {isFinishPage && <FinishPage bid={id} />}
            </Box>
          </Box>
        </Grid>
        {!isFinishPage && (
          <Grid item xs={12} className={classes.progressbar}>
            <Box
              p={1}
              display="flex"
              justifyContent="flex-end"
              style={{ position: "relative", top: 20 }}
            >
              <Buttons
                setPage={setPage}
                setHalfPage={setHalfPage}
                page={page}
                pagesLength={pagesLength}
              />
            </Box>
            <Box>
              <Progressbar page={page} pagesLength={pagesLength} />
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
