import { Container } from "@material-ui/core";
import React from "react";
import { BuilderForm } from "./Form";

export const Builder = () => (
  <Container maxWidth={false} disableGutters>
    <img
      src="https://images.unsplash.com/photo-1604820468298-c75d4328346f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=758&q=80"
      alt="asd"
      style={{ width: "100%", maxHeight: "20vh", zIndex: -1 }}
    />
    <BuilderForm />
  </Container>
);
