import React from "react";
import Select, { Props, OptionsType } from "react-select";
import { GridProps, Grid } from "@material-ui/core";

export type Option = { label: string; value: any };

type Value = OptionsType<any>[0] | OptionsType<any>;

export type MySelectProps = {
  value: Value;
  setValue: (selected: Value) => void;
  label: string;
  options: Option[];
  name?: string;
  grid?: GridProps;
  other?: Props & { [key: string]: any };
};

const MySelect: React.FC<MySelectProps> = ({
  value,
  setValue,
  label,
  options,
  name,
  grid = { xs: 12, item: true },
  other = {},
}) => (
  <Grid {...grid}>
    <Select
      value={value}
      onChange={(selected) => setValue(selected)}
      options={options}
      placeholder={label}
      name={name}
      {...other}
    />
  </Grid>
);

export default MySelect;
