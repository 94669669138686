import Login from "./auth/login.json";
import Navbar from "./navbar.json";
import Register from "./auth/register.json";
import ResetPassword from "./auth/reset-password.json";
import Inputs from "./inputs.json";
import AdminTables from "./admin-tables";
import General from "./general.json";
import Dashboard from "./dashboard/index.json";

export default {
  ...Login,
  ...Navbar,
  ...Register,
  ...ResetPassword,
  ...Inputs,
  ...AdminTables,
  ...General,
  ...Dashboard,
};
