import React from "react";
import Axios from "axios";
import EZFormikUI, { FormikSubmit, Fields } from "ez-formikui";
import { Grid } from "@material-ui/core";
import {
  MovieFields,
  FilterFields,
  FilterOptionFields,
  FolderFields,
  TagFields,
  StudioFields,
} from "@project/types";
import { validation, functions } from "@project/common";
import { useTranslation } from "react-i18next";
import {
  TableActions,
  MySelectWrapper,
  initialValueToOptions,
  WYSIWYGWrapper,
  initialValueToSingle,
  FiltersSelectWrapper,
} from "../../../common/TableAbstract";
import { useFetch } from "../../../../hooks";
import Loading from "../../../common/Loading";

const { convertToOptions } = functions;

type Props = {
  tableActions: TableActions;
  rowData?: MovieFields;
};

const MovieForm: React.FC<Props> = ({ tableActions, rowData: movie }) => {
  const { t } = useTranslation();
  const { selectedLang: lang } = tableActions;
  const { data: filters, loading: l1 } = useFetch<FilterFields[]>(
    "/admins/filters/",
  );
  const { data: filterOptions, loading: l2 } = useFetch<FilterOptionFields[]>(
    "/admins/filterOptions/",
  );
  const { data: folders, loading: l3 } = useFetch<FolderFields[]>(
    "/admins/folders/getForMovies/",
  );

  const { data: tags, loading: l4 } = useFetch<TagFields[]>("/admins/tags/");
  const { data: studios, loading: l5 } = useFetch<StudioFields[]>(
    "/admins/studios/",
  );

  const loading = l1 || l2 || l3 || l4 || l5;
  if (loading) return <Loading />;

  const url = movie ? `/admins/movies/${movie._id}` : "/admins/movies/";

  const onSubmit: FormikSubmit = async (values) => {
    const data = { ...values };
    data.folders = data.folders.map((folder: any) => folder.value);
    data.tags = data.tags.map((tag: any) => tag.value);
    data.studio = data.studio !== "" ? data.studio.value : undefined;

    const Method = movie ? Axios.put : Axios.post;
    const postAction = movie ? tableActions.updateRow : tableActions.addNewRow;

    const res = await Method(url, data);
    postAction(res.data);
    tableActions.closeDialog();
  };

  const fields: Fields = [
    {
      fieldName: "isActive",
      type: "switch",
      label: t("movie isActive"),
      initialValue: movie?.isActive || true,
    },
    {
      fieldName: "validatedText",
      type: "switch",
      label: t("movie validatedText"),
      initialValue: movie?.validatedText || false,
    },
    {
      fieldName: "freeMovie",
      type: "switch",
      label: t("movie freeMovie"),
      initialValue: movie?.freeMovie || false,
    },
    {
      fieldName: "name",
      type: "text",
      options: "text",
      label: t("add-movie name"),
      initialValue: movie?.name[lang] || "",
    },
    {
      fieldName: "engName",
      type: "text",
      options: "text",
      label: t("add-movie engName"),
      initialValue: movie?.name.en || "",
    },
    {
      fieldName: "name2",
      type: "text",
      options: "text",
      label: t("add-movie handle"),
      initialValue: movie?.name2 || "",
    },
    {
      fieldName: "playTimes",
      type: "array",
      of: [
        {
          fieldName: "start",
          type: "text",
          options: "number",
          label: t("add-movie startTime"),
          initialValue: 0,
        },
        {
          fieldName: "end",
          type: "text",
          options: "number",
          label: t("add-movie endTime"),
          initialValue: 0,
        },
      ],
      startEmpty: true,
      label: t("add-movie times"),
      initialValue: movie?.playTimes || [],
    },
    {
      fieldName: "lenght",
      type: "text",
      options: "text",
      label: t("add-movie lenght"),
      initialValue: movie?.lenght || "",
    },
    {
      fieldName: "movieLink",
      type: "text",
      options: "text",
      label: t("add-movie movieLink"),
      initialValue: movie?.movieLink || "",
    },
    {
      fieldName: "imgLink",
      type: "text",
      options: "text",
      label: t("add-movie imgLink"),
      initialValue: movie?.imgLink || "",
    },
    {
      fieldName: "short",
      type: "other",
      label: "",
      component: WYSIWYGWrapper({ label: t("add-movie short") }),
      initialValue: movie?.short[lang] || "",
    },
    {
      fieldName: "problemText",
      type: "other",
      label: "",
      component: WYSIWYGWrapper({ label: t("add-movie problemText") }),
      initialValue: movie?.problemText[lang] || "",
    },
    {
      fieldName: "qna",
      type: "other",
      label: "",
      component: WYSIWYGWrapper({ label: t("add-movie qna") }),
      initialValue: movie?.qna[lang] || "",
    },
    {
      fieldName: "shimush",
      type: "other",
      label: "",
      component: WYSIWYGWrapper({ label: t("add-movie shimush") }),
      initialValue: movie?.shimush[lang] || "",
    },
    {
      fieldName: "keys",
      type: "other",
      label: "",
      component: WYSIWYGWrapper({ label: t("add-movie keys") }),
      initialValue: movie?.keys || "",
    },
    {
      fieldName: "kshurim",
      type: "other",
      label: "",
      component: WYSIWYGWrapper({ label: t("add-movie kshurim") }),
      initialValue: movie?.kshurim[lang] || "",
    },
    {
      fieldName: "studio",
      type: "other",
      label: "",
      component: MySelectWrapper({
        label: t("add-movie studio-label"),
        options: convertToOptions(studios!, "_id", "name"),
        other: { isMulti: false },
      }),
      initialValue: initialValueToSingle(
        movie?.studio as StudioFields,
        "_id",
        "name",
      ),
    },
    {
      fieldName: "tags",
      type: "other",
      label: "",
      component: MySelectWrapper({
        label: t("add-movie tags-label"),
        options: convertToOptions(tags!, "_id", "name", lang),
        other: { isMulti: true },
      }),
      initialValue: initialValueToOptions(
        movie?.tags as TagFields[],
        "_id",
        "name",
        lang,
      ),
    },
    {
      fieldName: "folders",
      type: "other",
      label: "",
      component: MySelectWrapper({
        label: t("movie folders-label"),
        options: convertToOptions(folders!, "_id", "name", lang),
        other: { isMulti: true },
      }),
      initialValue: initialValueToOptions(
        movie?.folders as FolderFields[],
        "_id",
        "name",
        lang,
      ),
    },
    {
      fieldName: "filters",
      type: "other",
      label: "",
      component: FiltersSelectWrapper({
        filters: convertToOptions(filters!, "_id", "name", lang),
        options: filterOptions!.map((f) => ({
          label: functions.findFilledLang(f.name, lang),
          value: f._id,
          owner: f.owner as string,
        })),
      }),
      initialValue: movie
        ? (movie.filters as FilterOptionFields[]).map((f) => f?._id)
        : [],
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <EZFormikUI
          fields={fields}
          validationSchema={validation.admins.addMovie(lang)}
          onSubmit={onSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default MovieForm;
