import login from "./auth/login.json";
import navbar from "./navbar.json";
import register from "./auth/register.json";
import resetPassword from "./auth/reset-password.json";
import inputs from "./inputs.json";

export default {
  ...login,
  ...navbar,
  ...register,
  ...resetPassword,
  ...inputs,
};
