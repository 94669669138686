/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { functions } from "@project/common";
import { Typography, List, TextField } from "@material-ui/core";
import moment from "moment/moment";
import { Lang } from "@project/types";
import MyCard from "../../../common/Card";
import ActionUI from "./ActionUI";
import Pagination from "../../Search/Pagination";

type Props = {
  actions: any;
  selectedLang: Lang;
};

const GroupActions: React.FC<Props> = ({ actions, selectedLang }) => {
  const actionsPerPage = 10;
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [actionsNew, setActions] = useState(
    actions.filter((a: any) => a.userID !== null),
  );
  const [actionsToShow, setActionsToShow] = useState([]);
  const { t } = useTranslation();

  const sliceMe = (actions: any) =>
    actions.slice(
      page * actionsPerPage,
      page * actionsPerPage + actionsPerPage,
    );

  useEffect(() => {
    const r = new RegExp(search.toLowerCase(), "i");
    setActions(
      actions.filter(
        (t: any) =>
          t.userID !== null &&
          (r.test(t.userID.name) || r.test(t.movieID.name[selectedLang])),
      ),
    );
  }, [search]);

  useEffect(() => {
    setActionsToShow(sliceMe(actionsNew));
  }, [actionsNew, page]);

  useEffect(() => {
    setActions(actions.filter((a: any) => a.userID !== null));
  }, [actions]);

  const handlePage = (p: number) => setPage(p - 1);

  const noActionMsg =
    actions.length === 0 ? (
      <Typography variant="subtitle2" align="center">
        {t("no action yet")}
      </Typography>
    ) : null;

  return (
    <MyCard
      title={t("profile groupActions")}
      content={
        noActionMsg || (
          <>
            <List>
              <TextField
                onChange={(v) => setSearch(v.target.value)}
                fullWidth
                placeholder={t("search input")}
              />
              {actionsToShow.map((a: any) => {
                const showAction = a.actionID !== null && a.movieID !== null;
                return (
                  <div key={a._id}>
                    {showAction && (
                      <ActionUI
                        userName={a.userID.name}
                        text={
                          <>
                            <Typography>
                              {t(
                                `profile groupAction ${functions.actionIDToStr(
                                  a.actionID,
                                )}`,
                                {
                                  user: a.userID.name,
                                },
                              )}
                              <Link to={`/dashboard/movie/${a.movieID.name2}`}>
                                {functions.findFilledLang(
                                  a.movieID.name,
                                  selectedLang,
                                )}
                              </Link>
                            </Typography>{" "}
                            <Typography variant="caption">
                              {moment(a.createdAt).format("HH:mm DD/MM/YY")}
                            </Typography>
                          </>
                        }
                      />
                    )}
                  </div>
                );
              })}
            </List>
            <Pagination
              totalItems={actions.length}
              itemsPerPage={actionsPerPage}
              handleClick={handlePage}
            />
          </>
        )
      }
    />
  );
};

export default GroupActions;
