import UserHome from "../components/user";
import Profile from "../components/user/Profile/UserProfile";
import { RouteType } from "./RouteType";
import MovieFromHandle from "../components/user/Movie/MovieFromHandle";
import Search from "../components/user/Search";
import Folders from "../components/user/Folders/Folders";
import Contact from "../components/user/Contact";
import { Shares } from "../components/user/Shares";
import { ShareAnswers } from "../components/user/Shares/ShareAnswers";
import { Builder } from "../components/user/Builder";
import { EditBuilder } from "../components/user/Builder/edit";
import { MenageBuilder } from "../components/user/Builder/Menage";

const routes: RouteType[] = [
  {
    path: "/dashboard",
    component: UserHome,
    linkText: "navbar-home",
  },
  {
    path: "/dashboard/builderList",
    component: MenageBuilder,
    // linkText: "builder",
  },
  {
    path: "/dashboard/movie/:handle",
    component: MovieFromHandle,
  },
  {
    path: "/dashboard/profile/:field",
    component: Profile,
  },
  {
    path: "/dashboard/search",
    component: Search,
  },
  {
    path: "/dashboard/contact",
    component: Contact,
  },
  {
    path: "/dashboard/folders",
    component: Folders,
  },
  {
    path: "/dashboard/folders/:handle",
    component: Folders,
  },
  {
    path: "/dashboard/shares",
    component: Shares,
  },
  {
    path: "/dashboard/shares/:id",
    component: ShareAnswers,
  },
  {
    path: "/builder",
    component: Builder,
    linkText: "new-builder",
  },
  {
    path: "/editBuilder/:id",
    component: EditBuilder,
  },
];

export default routes;
