import React from "react";

import { functions } from "@project/common";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { PageFields } from "@project/types";
import { useLangSelector } from "../../../redux";
import { useFetch } from "../../../hooks";
import Loading from "../Loading";

const useStyles = makeStyles((theme) => ({
  textCenter: {
    textAlign: "center",
  },
}));

type Props = {
  closeMenu: () => void;
};

type PagesArr = (PageFields & { isDel: boolean })[];

const DynamicLinks: React.FC<Props> = ({ closeMenu }) => {
  const classes = useStyles();
  const lang = useLangSelector();
  const history = useHistory();
  const { data: pages, loading } = useFetch<PageFields[]>("/guests/pages/");

  if (loading) return <Loading />;

  return (
    <>
      {(pages as PagesArr)
        .filter(
          (page) =>
            !page.isDel &&
            functions.gotLang(page.name, lang) &&
            functions.gotLang(page.text, lang),
        )
        .map((page, i) => (
          <ListItem
            alignItems="center"
            onClick={() => {
              closeMenu();
              history.push(`/pages/${page.handle}`);
            }}
            button
            key={i + page._id}
          >
            <ListItemText
              className={classes.textCenter}
              primary={page.name[lang]}
            />
          </ListItem>
        ))}
    </>
  );
};

export default DynamicLinks;
