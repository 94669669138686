import React from "react";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { BuilderFields } from "@project/types";
import { OtherComponent } from "ez-formikui";
import { useTranslation } from "react-i18next";
import { OnChange } from "../types";
import { PageCard } from "../../PageCard";
import { elementFunctions, pageFunctions, fieldSwitch } from "./helpers";

type Page = BuilderFields["pages"][0];

export const Pages: OtherComponent = ({ value, setValue, errorMsg }) => {
  const { t } = useTranslation();

  const { addPage, movePageDown, movePageUp, removePage } = pageFunctions(
    value,
    setValue,
  );

  return (
    <Grid container justify="center" alignItems="center">
      {value.map((page: Page, i: number) => {
        const {
          AddQuote,
          addComments,
          addLink,
          addMovie,
          addImage,
          addQuestion,
          addText,
          canAddElement,
          removeElement,
          setPageValue,
        } = elementFunctions(page, i, value, setValue);

        const pageError = errorMsg && errorMsg[i] ? errorMsg[i] : {};

        return (
          <PageCard
            style={{ marginTop: 15 }}
            key={`page:${i}`}
            number={i + 2}
            header={{
              remove: () => removePage(i),
              moveDown: () => movePageDown(i),
              moveUp: () => movePageUp(i),
            }}
            actions={
              canAddElement()
                ? {
                    quote: AddQuote,
                    comments: addComments,
                    link: addLink,
                    movie: addMovie,
                    qna: addQuestion,
                    text: addText,
                    image: addImage,
                  }
                : undefined
            }
            component={
              <>
                <Grid item md={6} xs={12}>
                  <TextField
                    placeholder={t("builder-page title")}
                    onChange={(e) => setPageValue("title", e.target.value)}
                    fullWidth
                    value={value[i].title}
                    error={"title" in pageError}
                    helperText={pageError.title}
                  />
                </Grid>

                {page.elements.map((e, elIndex) => {
                  const onChange: OnChange = (field, val, index) => {
                    const old = [...value];
                    const p: Page = old[i];
                    const oldElement: any = p.elements[elIndex];
                    if (index !== undefined) {
                      oldElement[field][index] = val;
                    } else {
                      oldElement[field] = val;
                    }
                    setValue(old);
                  };
                  return (
                    <Grid
                      container
                      item
                      xs={12}
                      justify="center"
                      alignItems="center"
                      key={`element:${elIndex}`}
                    >
                      <Grid item xs={11}>
                        {fieldSwitch(
                          e.type,
                          onChange,
                          page.elements[elIndex],
                          pageError.elements ? pageError.elements[elIndex] : {},
                          t,
                        )}
                      </Grid>
                      <Grid item xs={1}>
                        <Button
                          style={{ color: "#F44336", padding: "0 5px" }}
                          onClick={() => removeElement(i, elIndex)}
                          // variant="outlined"
                        >
                          <DeleteIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  );
                })}
              </>
            }
          />
        );
      })}

      <Grid container item xs={12} justify="center" style={{ marginTop: 10 }}>
        <Tooltip title={t("add page") || ""}>
          <IconButton
            color="primary"
            onClick={addPage}
            style={{ backgroundColor: "#eee" }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
