import React from "react";
import { useTranslation } from "react-i18next";
import TableAbstract, { Columns } from "../../../common/TableAbstract";

const urls = {
  mount: "/admins/discounts/",
  delete: (id: string) => `/admins/discounts/${id}`,
  update: (id: string) => `/admins/discounts/${id}`,
  add: "/admins/discounts/",
};

export default function Discounts() {
  const { t } = useTranslation();
  const columns: Columns = (tableActions) => [
    {
      title: t("admin-discounts name"),
      field: "name",
      filtering: false,
    },
    {
      title: t("admin-discounts time"),
      field: "time",
      type: "numeric",
    },
    {
      title: t("admin-discounts amount"),
      field: "amount",
      type: "numeric",
    },
  ];

  return (
    <TableAbstract
      title={t("admin-discounts title")}
      columns={columns}
      urls={urls}
    />
  );
}
