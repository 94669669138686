import React from "react";
import { useTranslation } from "react-i18next";
import TableAbstract, { Columns, Urls } from "../../../common/TableAbstract";
import LinkedMoviesForm from "./LinkedMoviesForm";

const urls: Urls = {
  mount: "/admins/linkedMovies/",
  delete: (id: string) => `/admins/linkedMovies/${id}`,
  getByID: (id: string) => `/admins/linkedMovies/${id}`,
};

export default function LinkedMovies() {
  const { t } = useTranslation();
  const columns: Columns = ({ selectedLang: lang }) => [
    {
      title: t("admin-linkeds name"),
      field: `name.${lang}`,
      filtering: false,
      editable: "never",
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("admin-linkeds movie1"),
      field: `movies.mid1.name.${lang}`,
      filtering: false,
      editable: "never",
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("admin-linkeds movie2"),
      field: `movies.mid2.name.${lang}`,
      filtering: false,
      editable: "never",
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("admin-linkeds movie3"),
      field: `movies.mid3.name.${lang}`,
      filtering: false,
      editable: "never",
      emptyValue: t("admin-table emptyValue"),
    },
    {
      title: t("admin-linkeds movie4"),
      field: `movies.mid4.name.${lang}`,
      filtering: false,
      editable: "never",
      emptyValue: t("admin-table emptyValue"),
    },
  ];

  return (
    <TableAbstract
      title={t("admin-linkedMovies title")}
      columns={columns}
      urls={urls}
      useLang
      AddForm={LinkedMoviesForm}
      addFormFullWidth
      EditForm={LinkedMoviesForm}
      useFreshData
    />
  );
}
